// import { get } from 'lodash';
import get from "lodash/get";
import setAuthorizationToken from "./setAuthorizationToken";
const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let isRefreshing = null;
  let logoutEventName = "/api/partner/auth/logout";
  let refreshEndpoint = "/api/partner/auth/refresh_token";
  let refreshTimeOutId;

  const setLogoutEventName = (name) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const refreshToken = (delay) => {
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      delay * 1000 - 5000
    ); // Validity period of the token in seconds, minus 5 seconds
  };

  const abordRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  // The method make a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
  const getRefreshedToken = () => {
    const request = new Request(
      `${process.env.REACT_APP_RAILS_PRODUCTION}${refreshEndpoint}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "true",
          "Access-Control-Allow-Credentials": "true",
        }),
        credentials: "include",
      }
    );
    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          eraseToken();
          global.console.log("Token renewal failure");
          return { token: null };
        }
        return response.json();
      })
      .then((json_data) => {
        let token = get(json_data, "data.accessToken", ""),
          tokenExpiry = get(json_data, "data.refreshTokenExpiry", 900000);
        if (token) {
          setToken(token, tokenExpiry);
          return true;
        }
        eraseToken();
        return false;
      });
    return isRefreshing;
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token, delay) => {
    if (token) {
      inMemoryJWT = token;
      refreshToken(delay);
      setAuthorizationToken(token);
      return true;
    }
    return false;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    abordRefreshToken();
    localStorage.clear();
    window.localStorage.setItem(logoutEventName, Date.now());
    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh,
  };
};

export default inMemoryJWTManager();
