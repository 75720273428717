import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const useGetFile = (params) => {
  const { permissions } = usePermissions();
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["files", params],
    businessApis.getFile,
    {
      enabled: !!isLogin && !!params?.fileId, // && !!permissions?.business?.includes("files")
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const file = get(data, "data.data");
  return { file, isLoading, refetch, isError, error };
};

export default useGetFile;
