import React, { Fragment, useReducer, useState } from "react";
import moment from "moment-timezone";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Pagination from "antd/es/pagination";
import { Skeleton } from "antd";

import { Heading } from "../../atoms";
import styles from "./style.module.scss";
import { capitalize } from "lodash";
import DataStateHandler from "../../../utilities/DataStateHandler";
import { Link } from "react-router-dom";
import ViewAppointmentModal from "../../molecules/appointments/ViewAppointmentModal";
import { usePermissions } from "../../../PermissionsProvider/PermissionsProvider";

function ActivitiesCard({
  activities,
  isLoading,
  page,
  totalItems,
  onPageChange,
  isError,
  error,
}) {
  const { permissions } = usePermissions();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [appointment, setAppointment] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      id: null,
      businessId: null,
      locationId: null,
    }
  );

  const onClick = (item) => {
    let isStaffing = item.tablename === "shift";
    let isCorporate = item.tablename === "corporate";

    if ((isStaffing && !permissions?.shift?.includes("shift_details")) || (isCorporate && !permissions?.corporate?.includes("shift_details"))) return;
    if (!(isStaffing || isCorporate) && !permissions?.appointment?.includes("read")) return;

    setAppointment({
      id: item.appointment_number,
      businessId: item.business_id,
      locationId: item.location_id,
    });
    setIsViewModalOpen(true);
  };

  return (
    <DataStateHandler
      isLoading={isLoading}
      loadingComponent={<Skeleton loading={isLoading} active />}
      isError={isError}
      error={error}
      data={activities?.length}
    >
      <div className={styles["scrollbox"]}>
        <div className={styles["activities-list"]}>
          {!isLoading && activities?.length > 0 ? (
            activities?.map((activity, index) => (
              <div key={index} className={styles["activity-block"]}>
                <Row gutter={0}>
                  <Col xxl={3} lg={2} md={2} sm={4} xs={24}>
                    <div className={styles["activity-date"]}>
                      <Heading as="h4">
                        {moment(activity.created_date).format("DD")}
                      </Heading>
                      <span className="activity-month">
                        {moment(activity.created_date)
                          .format("MMM")
                          .toUpperCase()}
                      </span>
                    </div>
                  </Col>
                  <Col xxl={21} lg={22} md={22} sm={20} xs={24}>
                    {activity.data.map((item, index) => {
                      let {
                        business_name,
                        business_id,
                        location_name,
                        tablename,
                        provider_name,
                        description,
                        full_name,
                        createdAt,
                        team_name,
                        appointment_number,
                        file_name,
                      } = item;
                      description =
                        description === "cancel" ? "canceled" : description;
                      return (
                        <div key={index} className={styles["activity-content"]}>
                          <div className={styles["activity-title"]}>
                            {(business_name || location_name) && (
                              <Link to={`/businesses/${business_id}`}>
                                {business_name && `${business_name}`}
                                {location_name && `, ${location_name}`}
                              </Link>
                            )}{" "}
                            {tablename === "providernotes" ? (
                              `${capitalize(
                                full_name
                              )} ${description} to ${provider_name}`
                            ) : description.includes("_") ? (
                              `${capitalize(
                                provider_name
                              )} status update to ${description.replace(
                                /_/g,
                                " "
                              )} by ${full_name}`
                            ) : (
                              <div>
                                {tablename === "users" ? (
                                  capitalize(team_name || tablename)
                                ) : tablename === "file" ? (
                                  `${capitalize(file_name)} ${capitalize(
                                    tablename
                                  )}`
                                ) : provider_name ? (
                                  capitalize(provider_name)
                                ) : (
                                  <>
                                    {tablename === "tip"
                                      ? "Appointment"
                                      : capitalize(tablename)}{" "}
                                    {appointment_number && (
                                      <span
                                        onClick={() => onClick(item)}
                                        className={styles["appt-number"]}
                                      >
                                        #{appointment_number}
                                      </span>
                                    )}
                                  </>
                                )}
                                {tablename === "tip" && ` ${tablename}`}{" "}
                                {description} by {full_name}
                              </div>
                            )}
                          </div>
                          <div className={styles["activity-time"]}>
                            {moment(createdAt).format("hh:mm A").toLowerCase()}
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              No activities at this time.
            </div>
          )}
        </div>
      </div>
      <Pagination
        style={{ textAlign: "center", paddingTop: "15px" }}
        current={page + 1}
        pageSize={4}
        showSizeChanger={false}
        onChange={onPageChange}
        total={totalItems}
        hideOnSinglePage={true}
      />
      {isViewModalOpen && (
        <ViewAppointmentModal
          isOpen={isViewModalOpen}
          appointmentId={appointment.id}
          locationId={appointment.locationId}
          businessId={appointment.businessId}
          onExit={() => setIsViewModalOpen(false)}
        />
      )}
    </DataStateHandler>
  );
}

export default ActivitiesCard;
