import Modal from "antd/es/modal";
import "antd/es/modal/style";

const popup = (message, redirect) => {
  let secondsToGo = 2;

  const modal = Modal.error({
    title: "Error",
    content: message,
    onOk() {
      if (redirect) redirectToLoginPage();
    },
  });

  const redirectToLoginPage = () => {
    modal.destroy();
    window.location.href = "/login";
  };

  redirect &&
    setTimeout(() => {
      redirectToLoginPage();
    }, secondsToGo * 1000);
};

export default popup;
