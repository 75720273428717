import get from "lodash/get";
import { useQuery } from "react-query";
import user from "../api/user";
import useTryAutoLogin from "./useTryAutoLogin";
import { getbusinessId } from "../helpers";

const useUserProfileData = (CurrentbusinessId) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    "userProfileData",
    user.getProfile,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
    }
  );

  const userData = get(data, "data.data");
  const userRole = userData?.role.name;
  const businessList = get(data, "data.data.businesses", []);
  const businessId = getbusinessId(businessList, CurrentbusinessId);

  return {
    userData,
    isError,
    error,
    isLoading,
    businessList,
    businessId,
    refetch,
    userRole,
  };
};

export default useUserProfileData;
