import get from "lodash/get";
import { useQuery } from "react-query";
import business from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetBusinessPermissions = () => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading } = useQuery(
    "businessPermissions",
    business.getPermissions,
    {
      enabled: !!isLogin,
    }
  );
  const businessPermissions = get(data, "data.data");

  return {
    businessPermissions,
    isError,
    isLoading,
  };
};

export default useGetBusinessPermissions;
