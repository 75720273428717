import get from "lodash/get";
import { useQuery } from "react-query";
import location from "../api/location";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetPropertyVolumeDiscounts = (id) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading } = useQuery(
    ["propertyVolumeDiscounts", id],
    location.getPropertyVolumeDiscounts,
    {
      enabled: !!isLogin && !!id,
      cacheTime: 0,
      staleTime: 0,
      // todo check permission
    }
  );
  const discounts = get(data, "data.discounts"),
    default_discounts = get(data, "data.default_discounts"),
    any_toggled_off = get(data, "data.any_toggled_off", false);
  return {
    discounts,
    isError,
    isLoading,
    any_toggled_off,
    default_discounts,
  };
};

export default useGetPropertyVolumeDiscounts;
