import React from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import "antd/es/typography/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import ImageUpload from "../../../molecules/locations/ImageUpload";
import Heading from "../../../atoms/heading/heading";
import StyledInput from "../../../atoms/inputs/StyledInput";
import FileUpload from "../../../molecules/locations/FileUpload";
import Validator from "validator";
import styles from "./style.module.scss";

const getWrapper = (title, children) => {
  return (
    <div className="card-wrapper">
      <Card>
        <div
          className="header"
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading className="header__text" as="h4">
            {title}
          </Heading>
        </div>
        <div className="body">
          <Card>{children}</Card>
        </div>
      </Card>
    </div>
  );
};

const About = (props) => {
  const {
    businessName,
    businessPhoneNumber,
    businessEmail,
    businessLogo,
    businessContract,
    onsiteContract,
    setAboutData,
    form,
    mode,
  } = props;

  return (
    <>
      <div className={styles["wrapper"]}>
        <Row gutter={[0, 10]}>
          {getWrapper(
            "Business Details",
            <>
              <Row gutter={[32]}>
                <Col span={24}>
                  <Form.Item
                    colon={false}
                    name="businessName"
                    label={"Business Name"}
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                      {
                        max: 255,
                        message: "Name must be maxmum 255 characters.",
                      },
                    ]}
                  >
                    <StyledInput
                      value={businessName}
                      placeholder="Business name"
                      onChange={(e) =>
                        setAboutData({ businessName: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16]}>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    name="business-phone-number"
                    label="Business Phone Number"
                    required
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value || !Validator.isMobilePhone(value)) {
                            return Promise.reject(
                              new Error("Please enter a valid phone number")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <StyledInput
                      value={businessPhoneNumber}
                      onChange={(e) =>
                        setAboutData({ businessPhoneNumber: e.target.value })
                      }
                      placeholder="Phone number"
                      disabled={mode === "edit"}
                      maxLength={255}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    name="business-email"
                    label="Business Email"
                    required
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(`Business email required`);
                          }
                          if (!Validator.isEmail(value)) {
                            return Promise.reject(
                              `${value} is not a valid email address.`
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <StyledInput
                      value={businessEmail}
                      onChange={(e) =>
                        setAboutData({ businessEmail: e.target.value })
                      }
                      placeholder="Business email"
                      disabled={mode === "edit"}
                      maxLength={255}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[32]}>
                <Col span={24}>
                  <Form.Item name="bannerImage" label={"Business Logo Image"}>
                    <ImageUpload
                      id="business_bannerImage"
                      bkgroundColor={"#f8f9fb"}
                      onUploadSuccess={(url) => {
                        form.setFieldsValue({ bannerImage: url });
                        setAboutData({ businessLogo: url });
                      }}
                      value={businessLogo}
                      onRemoveSuccess={() => {
                        setAboutData({ businessLogo: "" });
                        form.setFieldsValue({ bannerImage: "" });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <div className={"divider"} />

          {getWrapper(
            "Contract",
            <Row gutter={[32]}>
              {mode === "edit" && (
                <Col span={24}>
                  <Form.Item
                    name="onsiteContract"
                    label={"Onsite Contract (Optional)"}
                  >
                    <FileUpload
                      id="business_onsiteContract"
                      logo={true}
                      bkgroundColor={"#f8f9fb"}
                      onUploadSuccess={(url) => {
                        form.setFieldsValue({ onsiteContract: url });
                        setAboutData({ onsiteContract: url });
                      }}
                      value={businessContract}
                      onRemoveSuccess={() => {
                        setAboutData({ onsiteContract: "" });
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  name="contractLogo"
                  label={"Business Contract (Optional)"}
                >
                  <FileUpload
                    id="business_contractLogo"
                    logo={true}
                    bkgroundColor={"#f8f9fb"}
                    onUploadSuccess={(url) => {
                      form.setFieldsValue({ contractLogo: url });
                      setAboutData({ businessContract: url });
                    }}
                    value={businessContract}
                    onRemoveSuccess={() => {
                      setAboutData({ businessContract: "" });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Row>
      </div>
    </>
  );
};

export default About;
