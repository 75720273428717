import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const rolesEndpoints = {
  roles: "/roles",
};

const roles = {
  getRoles() {
    return nodePortalAxiosInstance.get(`${rolesEndpoints.roles}`);
  },
  getPermissionsPerRole({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${rolesEndpoints.roles}/${id}`);
  },
  updatePermissions(id, params) {
    return nodePortalAxiosInstance.put(`${rolesEndpoints.roles}/${id}`, params);
  },
};

export default roles;
