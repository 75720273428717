import Amplitude from "amplitude-js";

if (process.env.REACT_APP_PRODUCTION === "true") {
  Amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_SDK_KEY);
}
export function loginEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_portal_login";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function logoutEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_portal_logout";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function forgotPasswordEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_forgot_password";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function resetPasswordEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_reset_password";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function dashboardDropdownEvent(location_name) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_dashboard_dropdown";
  const eventHash = { location_name };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function appointmentsLocationDropdownEvent(location_name) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_appointments_location_dropdown";
  const eventHash = { location_name };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function appointmentsDateFilterEvent(selected_timeframe) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_appointments_date_filter";
  const eventHash = { selected_timeframe };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function appointmentsExportEvent(export_format) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_appointments_export";
  const eventHash = { export_format };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsLocationDropdownEvent(location_name) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_location_dropdown";
  const eventHash = { location_name };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsSearchBarEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_search_bar";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsSearchFilterEvent(status) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_search_filter";
  const eventHash = { status };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsAddLocationEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_add_location";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsAboutPageEvent(
  save_type,
  location_name,
  address,
  website_link,
  account_id
) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_about_page";
  const eventHash = { save_type, location_name, address, website_link, account_id };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsTreatmentsEvent(save_type, service_name) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_treatments";
  const eventHash = { save_type, service_name };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsArrivalInstructionsEvent(
  save_type,
  parking_type,
  parking_fee
) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_arrival_instructions";
  const eventHash = { save_type, parking_type, parking_fee };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsSettingsEvent(save_type) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_settings";
  const eventHash = { save_type };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsLocationDropdownEvent(location_name) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_location_dropdown";
  const eventHash = { location_name };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsSearchBarEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_search_bar";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsSearchFilterEvent(status) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_search_filter";
  const eventHash = { status };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function inviteTeamMemberEvent(role, location) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_invite_team_member";
  const eventHash = { role, location };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsShortcutEditEvent(user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_shortcut_edit";
  const eventHash = { user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsShortcutResendInviteEvent(user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_shortcut_resend_invite";
  const eventHash = { user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsShortcutDeleteEvent(user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_shortcut_delete";
  const eventHash = { user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsShortcutDisableEvent(user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_shortcut_disable";
  const eventHash = { user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsShortcutEnableEvent(user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_shortcut_enable";
  const eventHash = { user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function teamsEditUserEvent(role, location, user_email) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_teams_edit_user";
  const eventHash = { role, location, user_email };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function userSettingsUpdateEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_user_settings_update";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function userSettingsPasswordChangeEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_user_settings_password change";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsInitiateCustompricingEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_initiate_custompricing";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsCustompricingMarkupApplytoallEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_custompricing_markup_applytoall";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsCustompricingSaveupdatesEvent(save_type) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_custompricing_saveupdates";
  const eventHash = { save_type };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsCancelEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_cancel";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function conciergeRequestABookingButtonOutsideEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_concierge_request_a_booking_button_outside";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function conciergeRequestABookingButtonInsideFormEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_concierge_request_a_booking_button_inside_form";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function conciergeCancelButtonInsideFormEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_concierge_cancel_button_inside_form";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsProviderSourcingEvent(save_type) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_provider_sourcing";
  const eventHash = { save_type };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsProviderPayoutEvent(save_type) {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_provider_payout";
  const eventHash = { save_type };

  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function locationsAddLocationCompleteEvent() {
  if (process.env.REACT_APP_PRODUCTION !== "true") return;

  const eventName = "sbe_locations_add_location_complete";
  const eventHash = {};

  Amplitude.getInstance().logEvent(eventName, eventHash);
}