import Spin from "antd/es/spin";
import "antd/es/spin/style";
import { Navigate, Outlet, useLocation } from "react-router";

import useTryAutoLogin from "../../../utilities/hooks/useTryAutoLogin";

const PublicRoute = () => {
  const { pathname } = useLocation();
  const { isLoading, isLogin } = useTryAutoLogin();

  if (isLoading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }
  if (pathname === "/login" && isLogin) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export default PublicRoute;
