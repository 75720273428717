import React from "react";
import Switch from "antd/es/switch";
import "antd/es/switch/style";

import styles from "./styles.module.scss";

function SwitchButton({
  checked,
  onChange,
  value,
  size = "default",
  extraButtonStyle,
  defaultChecked,
}) {
  return (
    <div className={styles["switch-btn__container"]}>
      <Switch
        className={`${styles["switch-btn"]} ${extraButtonStyle}`}
        checked={checked}
        onChange={onChange}
        defaultChecked={defaultChecked}
        value={value}
        size={size}
      />
    </div>
  );
}

export default SwitchButton;
