import get from "lodash/get";
import { Navigate, Outlet } from "react-router";
import useTryAutoLogin from "../../../utilities/hooks/useTryAutoLogin";
import inMemoryJwt from "../../../utilities/inMemoryJwt";

const PrivateRoute = () => {
  const { data, isLoading, isLogin } = useTryAutoLogin();
  const accessToken = get(data, "data.data.accessToken");
  const tokenExpiry = get(data, "data.data.refreshTokenExpiry");
  if (!isLoading && !accessToken && !isLogin) {
    return <Navigate to="/login" replace />;
  }
  inMemoryJwt.setToken(accessToken, tokenExpiry);
  return <Outlet />;
};

export default PrivateRoute;
