import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Table from "antd/es/table";
import "antd/es/table/style";
import Pagination from "antd/es/pagination";
import "antd/es/pagination/style";
import Menu from "antd/es/menu";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";

import { Cards } from "../../../../atoms/cards/Cards";
import { DropdownBase } from "../../../../atoms";
import styles from "./FilesTable.module.scss";
import Restricted from "../../../../../PermissionsProvider/Restricted";
import ConfirmModal from "../../../ConfirmModal";
import businessApis from "../../../../../utilities/api/businesses";
import openNotification from "../../../../../utilities/openNotification";

function FilesTable({
  files,
  isLoading: isFilesLoading,
  totalItems,
  page,
  perPage,
  onPageChange,
  onPageSizeChange,
  business,
  refetch,
  setAddLinkModalState,
  setUploadFileModalState,
  locations,
}) {
  const [deleteFileModalState, setDeleteFileModalState] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isOpen: false,
      fileId: null,
      isLoading: false,
    }
  );

  const handleDeleteFile = async () => {
    try {
      setDeleteFileModalState({ isLoading: true });
      let data = {
        businessId: business?.id,
        fileId: deleteFileModalState?.fileId,
      };
      let response = await businessApis.deleteFile(
        data.businessId,
        data.fileId
      );
      openNotification("Success", response.data.message, "success");
      setDeleteFileModalState({ isLoading: false, isOpen: false });
      refetch();
    } catch (error) {
      setDeleteFileModalState({ isLoading: false });
      openNotification("Error", error.response.data.message, "error");
    }
  };

  const handleDownload = (fileUrl, fileName, extension) => {
    fetch(fileUrl)
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          openNotification("Error", "Failed to download file", "error");
          throw new Error("Failed to fetch file.");
        }
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = extension ? `${fileName}.${extension}` : fileName;
        link.click();
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const generateDataSource = () =>
    files?.map((value, index) => {
      const {
        id,
        name,
        type,
        locations: fileLocations,
        added_by,
        createdAt,
        updatedAt,
        fileurl,
        extension,
      } = value;
      return {
        key: index,
        name,
        fileType: type,
        property:
          fileLocations?.length === locations?.length
            ? "All properties"
            : `${
                fileLocations?.filter((loc) => loc.id !== null)?.length
              } properties`,
        addedBy: added_by,
        dateAdded: `${moment(createdAt).format("h:mm A")} on ${moment(
          createdAt
        ).format("MM/DD/YY")}`,
        lastModified: `${moment(updatedAt).format("h:mm A")} on ${moment(
          updatedAt
        ).format("MM/DD/YY")}`,
        action: (
          <DropdownBase
            className="actions-dropdown"
            classNameC="actions-dropdown-content"
            action={["click"]}
            content={
              <Menu
                items={[
                  {
                    label: (
                      <Link
                        to="#"
                        onClick={(e) => {
                          type === "File"
                            ? handleDownload(fileurl, name, extension)
                            : window.open(fileurl, "_blank");
                        }}
                      >
                        <FeatherIcon
                          icon={type === "File" ? "download" : "arrow-up-right"}
                          size={18}
                        />{" "}
                        {type === "File" ? "Download" : "Open"}
                      </Link>
                    ),
                    key: "1",
                  },
                  {
                    label: (
                      <Restricted
                        to="edit_files"
                        resource="business"
                        fallback={<></>}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            type === "File"
                              ? setUploadFileModalState({
                                  isOpen: true,
                                  fileId: id,
                                  mode: "edit",
                                })
                              : setAddLinkModalState({
                                  isOpen: true,
                                  fileId: id,
                                  mode: "edit",
                                });
                          }}
                        >
                          <FeatherIcon icon="edit-3" size={18} />
                          Edit
                        </Link>
                      </Restricted>
                    ),
                    key: "2",
                  },
                  {
                    label: (
                      <Restricted
                        to="delete_files"
                        resource="business"
                        fallback={<></>}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            setDeleteFileModalState({
                              isOpen: true,
                              fileId: id,
                            });
                          }}
                        >
                          <FeatherIcon icon="trash-2" size={18} />
                          Delete
                        </Link>
                      </Restricted>
                    ),
                    key: "3",
                  },
                ]}
              />
            }
          >
            <Link to="#">
              <FeatherIcon icon="more-vertical" size={18} />
            </Link>
          </DropdownBase>
        ),
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "File Type",
      dataIndex: "fileType",
      key: "fileType"
    },
    {
      title: "Property",
      dataIndex: "property",
      key: "property"
    },
    {
      title: "Added by",
      dataIndex: "addedBy",
      key: "addedBy"
    },
    {
      title: "Date added",
      dataIndex: "dateAdded",
      key: "dateAdded"
    },
    {
      title: "Last modified",
      dataIndex: "lastModified",
      key: "lastModified"
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "32px",
    },
  ];

  return (
    <>
      <Row gutter={25} className="mt-20">
        <Col xs={24}>
          <div className={styles["table-container"]}>
            <Cards headless border={false}>
              <div className="table-responsive">
                <Table
                  loading={isFilesLoading}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  dataSource={generateDataSource()}
                  columns={columns}
                  size="middle"
                />
              </div>
            </Cards>
          </div>
        </Col>
        <Col xs={24} className="pb-30">
          <Pagination
            style={{ textAlign: "end" }}
            current={page + 1}
            pageSize={perPage}
            showSizeChanger={true}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={totalItems}
            hideOnSinglePage={totalItems < 10 ? true : false}
          />
        </Col>
      </Row>
      {deleteFileModalState.isOpen && (
        <ConfirmModal
          isOpen={deleteFileModalState.isOpen}
          title={`Are you sure you want to delete this file?`}
          text="This action cannot be undone."
          buttons={[
            {
              className: "my-10",
              size: "small",
              type: "danger",
              outlined: true,
              text: "No, exit now",
              onClick: () => setDeleteFileModalState({ isOpen: false }),
            },
            {
              size: "small",
              type: "primary",
              loading: deleteFileModalState.isLoading,
              text: "Yes, delete this file",
              onClick: () => handleDeleteFile(),
            },
          ]}
        />
      )}
    </>
  );
}

export default FilesTable;
