import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";

import { Button } from "../../../atoms/buttons/buttons";
import { useQueryClient } from "react-query";

function List({ listState, setListState, onListChange, dateFormat }) {
  const queryClient = useQueryClient();

  let { currentMonth, defaultValue } = listState;

  useEffect(() => {
    onListChange(getMonth(currentMonth), getMonth(currentMonth + 1));
  }, []);

  const getMonth = (month) => moment().add(month, "month").format(dateFormat);

  const onIncrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    onListChange(getMonth(currentMonth + 1), getMonth(currentMonth + 2));
    return setListState({
      ...listState,
      currentMonth: currentMonth + 1,
    });
  };

  const onDecrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    onListChange(getMonth(currentMonth - 1), getMonth(currentMonth));
    setListState({
      ...listState,
      currentMonth: currentMonth - 1,
    });
  };

  return (
    <div className="calender-head__navigation">
      <Button
        id="calendar_left-arrow-button"
        onClick={onDecrement}
        className="btn-navigate"
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-left" />
      </Button>
      <span id="calendar_date" className="date-label">
        {`${moment()
          .add(currentMonth, "month")
          .format("MMM DD, YYYY")} - ${moment()
          .add(currentMonth + 1, "month")
          .format("MMM DD, YYYY")}`}
      </span>
      <Button
        id="calendar_right-arrow-button"
        onClick={onIncrement}
        className="btn-navigate"
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-right" />
      </Button>
    </div>
  );
}

export default List;
