import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const useGetBusinessFiles = (params) => {
  const { permissions } = usePermissions();
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["files", params],
    businessApis.getBusinessFiles,
    {
      enabled: !!isLogin, // && !!permissions?.business?.includes("files")
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const files = get(data, "data.data.rows");
  const totalItems = get(data, "data.data.totalItems");
  return { files, totalItems, isLoading, refetch, isError, error };
};

export default useGetBusinessFiles;
