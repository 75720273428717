import Spin from "antd/es/spin";
import "antd/es/spin/style";
import { Navigate, Outlet } from "react-router";

import useGetMaintenance from "./hooks/useGetMaintenance";
import inMemoryJwt from "./inMemoryJwt";

const AppMaintenanceHandler = () => {
  const { maintenanceData, isLoading } = useGetMaintenance();

  if (isLoading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  if (maintenanceData?.is_offline) {
    inMemoryJwt.eraseToken();
    return <Navigate to="/under-maintenance" replace />;
  }

  return <Outlet />;
};

export default AppMaintenanceHandler;
