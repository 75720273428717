import React, { useEffect, useState } from "react";
import "antd/es/form/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import styles from "./style.module.scss";
import Select from "antd/es/select";
import "antd/es/select/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow, StyledInput } from "../../atoms";
import { get, isEmpty, isNaN } from "lodash";
import WarningModal from "../../organisms/CreateShift/WarningModal";

const { Option } = Select;

function EditTipModal({
  isOpen,
  onExit,
  selectedAppointment,
  appointmentDetails,
  setUpdateTipDetails,
  updateTipDetails,
  setTip,
  tip,
  setConfirmModal,
  isDollarClicked,
  isPercentClicked,
  setIsDollarClicked,
  setIsPercentClicked,
  isSootheMember,
  shoud_show_warning
}) {
  const [warningModalState, setWarningModalState] = useState(null);
  const tipOptions = [
    { label: "$", value: "dollar" },
    { label: "%", value: "percentage" },
  ];

  const [form] = Form.useForm();

  const clearFields = () => {
    form.resetFields(["tipField"]);
    setUpdateTipDetails({
      amount: null,
      percentage_of_original: null,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      clearFields();
    }
  }, [isOpen]);

  form.setFieldsValue({
    "tip-label": isDollarClicked ? "dollar" : "percentage",
  });
  let tipVal = get(selectedAppointment, `cart_products.${tip.tipIndex}.tip_amount`, 0) ?? 0;
  return (<>
    <Modal
      wrapClassName={styles["tip-modal-container"]}
      title={
        <div>
          <h3 style={{ color: "#545454" }}>
            {tipVal ? "Adjust" : "Add"} tip for {appointmentDetails.providerName}
          </h3>
        </div>
      }
      visible={isOpen}
      footer={null}
      centered
      onCancel={onExit}
    >
      <div className="view-modal-body">
        <div className="view-modal-body-card" style={{ marginRight: 0 }}>
          <Card className={styles["modal-card"]} headless="true">
            <div className={styles["flex-row"]}>
              <div className={styles["card-content"]}>
                <span style={{
                  backgroundColor: "#F6F6F6",
                  marginBottom: "5px",
                  border: "none",
                  padding: "16px",
                  borderRadius: "3px",
                  width: "fit-content",
                  color: "#000000",
                  fontWeight: 500
                }}>
                  <div>Current Tip</div>
                  <div style={{ color: "#545454" }}>${tipVal}</div>
                </span>
                <Form
                  form={form}
                  name="tip-form"
                  onFinish={() => {
                    setUpdateTipDetails({
                      ...updateTipDetails,
                      amount: form.getFieldValue("tipField") || 0,
                    });
                    if (shoud_show_warning) {
                      setWarningModalState({
                        functn: () => {
                          setConfirmModal({ isOpen: true });
                          setTip({ isModalOpen: false });
                          setWarningModalState(null)
                        }
                      })
                    } else {
                      setConfirmModal({ isOpen: true });
                      setTip({ isModalOpen: false });
                    }
                  }}
                >
                  <>
                    <div className={styles["tip-amount-title"]}>
                      New Tip
                    </div>
                    <FlexRow>
                      <Form.Item
                        colon={false}
                        name="tip-label"
                        className={styles["tip-selector"]}
                      >
                        <Select
                          className={styles["tip-selector"]}
                          value={isDollarClicked ? "dollar" : "percentage"}
                          onChange={(value, option) => {
                            if (value === "dollar") {
                              setIsDollarClicked(true);
                              setIsPercentClicked(false);
                            } else {
                              setIsDollarClicked(false);
                              setIsPercentClicked(true);
                            }
                            clearFields();
                          }}
                        >
                          {tipOptions.map((tip, index) => (
                            <Option key={index} value={tip.value}>
                              {tip.label}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div className={styles["tip-amount"]}>
                        <Form.Item
                          name="tipField"
                          rules={[
                            () => ({
                              validator(_, val) {
                                if (isNaN(val)) {
                                  return Promise.reject(
                                    "Tip has to be a number"
                                  );
                                }
                                let amount = Number(selectedAppointment.cart_products[tip.tipIndex].tip_amount),
                                  value = isPercentClicked && !isDollarClicked ? (Number(val || 0) / 100) * parseFloat(
                                    selectedAppointment.cart_products[
                                      tip.tipIndex
                                    ].charge_amount
                                  ) : Number(val || 0);
                                if (!isSootheMember && !value) {
                                  return Promise.reject("Tip cannot be empty");
                                }
                                if (+value < 0) {
                                  return Promise.reject(
                                    "Tip can't be negative"
                                  );
                                }

                                if (+value > 1000) {
                                  return Promise.reject(
                                    "Tip cannot exceed $1000."
                                  );
                                }
                                if (!isSootheMember && amount && value < amount) {
                                  return Promise.reject(
                                    "Tip cannot be less than previous tip."
                                  );
                                }

                                if (
                                  isPercentClicked &&
                                  (value / 100) *
                                  parseFloat(
                                    selectedAppointment.cart_products[
                                      tip.tipIndex
                                    ].charge_amount
                                  ) >
                                  1000
                                ) {
                                  return Promise.reject(
                                    "Tip cannot exceed $1000."
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <StyledInput
                            type="number"
                            extraclassname={`hide-number-arrows w-100 h-auto`}
                            defaultValue={0}
                            min={0} max={1000}
                            pattern="[0-9\.]+" step={0.01}
                            onChange={(value) => {
                              if (isNaN(value)) {
                                return;
                              }
                              if (isPercentClicked && !isDollarClicked) {
                                setUpdateTipDetails({
                                  percentage_of_original:
                                    +value / (100)?.toFixed(2),
                                  amount:
                                    (selectedAppointment.cart_products[
                                      tip.tipIndex
                                    ].charge_amount *
                                      +value) /
                                    (100)?.toFixed(2),
                                });
                              } else if (isDollarClicked && !isPercentClicked) {
                                setUpdateTipDetails({
                                  amount: +value,
                                  percentage_of_original: null,
                                });
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </FlexRow>
                  </>
                  <footer>
                    <Button
                      className="close-button"
                      key="cancel"
                      size="small"
                      onClick={onExit}
                      type="danger"
                    >
                      Close without saving
                    </Button>
                    <Button
                      key="submit"
                      size="small"
                      type={"sootheButton"}
                      htmlType="submit"
                    >
                      Save tip
                    </Button>
                  </footer>
                </Form>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Modal>

    <WarningModal isOpen={!isEmpty(warningModalState)} onExit={() => { setWarningModalState(null) }} onSubmit={() => {
      if (warningModalState.functn) {
        warningModalState.functn()
      }
    }}
    />
  </>
  );
}

export default EditTipModal;
