export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const decimalRegex = /^\d{1,4}(?:\.\d{1,2})?$/;

export const ABOUT = "About";
export const TREATMENTS = "Treatments";
export const ARRIVAL_INSTRUCTIONS = "Arrival Instructions";
export const CUSTOM_PRICING = "Custom Pricing";
export const PROVIDER_PAYOUT = "Provider Payout";
export const PAYMENTS_AND_BILLING = "Payments & Billing";
export const OPERATING_HOURS = "Operating Hours";
export const DISCOUNTS = "Discounts";
export const BILLING = "Billing";
export const PERMISSIONS = "Permissions";
export const PROVIDER_SOURCING = "Provider Preferences";