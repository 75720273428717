import { Result } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { MainActionButton } from "../components/atoms";

function NoPermissionComponent({ extra = true, height = "100vh" }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height,
      }}
    >
      <Result
        style={{ width: "100%" }}
        status="403"
        title="403"
        subTitle={
          <div>
            Sorry, you do not currently have the authorization <br /> to access/view
            this page.
          </div>
        }
        extra={
          extra && (
            <MainActionButton
              copy="Go Back"
              htmlType="button"
              onClick={() => {
                navigate(-1);
              }}
            />
          )
        }
      />
    </div>
  );
}

export default NoPermissionComponent;
