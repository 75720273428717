import get from "lodash/get";
import { useQuery } from "react-query";
import business from "../api/businesses";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetBusinessVolumeDiscounts = ({ id, type }) => {
  const { isLogin } = useTryAutoLogin();
  const { permissions } = usePermissions();

  const { data, isError, isLoading } = useQuery(
    ["businessVolumeDiscounts", id],
    business.getBusinessVolumeDiscounts,
    {
      enabled:
        !!isLogin &&
        !!id &&
        !!(type === "byBusiness") &&
        !!permissions?.business?.includes("billing"),
      // todo permission and role check soothe internal
      cacheTime: 0,
      staleTime: 0,
    }
  );
  const discounts = get(data, "data.discounts"),
    default_discounts = get(data, "data.default_discounts"),
    // should_create = get(data, "data.should_create", false),
    any_toggled_off = get(data, "data.any_toggled_off", false);
  return {
    discounts,
    // should_create,
    isError,
    isLoading,
    any_toggled_off,
    default_discounts,
  };
};

export default useGetBusinessVolumeDiscounts;
