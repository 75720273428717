import React, { useEffect, useState } from "react";
import CalenDar from "react-calendar";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import { useQueryClient } from "react-query";

function Day({ setDayState, dayState, dateFormat, onDayChange }) {
  const queryClient = useQueryClient();

  const { date, container, currentLabel, defaultValue } = dayState;

  const [year, setYear] = useState();

  // useEffect(() => {
  //   onDayChange(date);
  // }, []);

  const onChange = (dt) => {
    queryClient.refetchQueries("getAppointmentDetails");
    setDayState({
      ...dayState,
      date: dt,
      defaultValue: moment(dt).format(dateFormat),
    });
  };

  return (
    <CalenDar
      id="calendar_date-picker"
      className="react-calendar"
      onClickDay={(dt) => {
        if (year) {
          onDayChange(
            `${moment(new Date()).format("YYYY")}-${moment(moment(dt)).format(
              "MM-DD"
            )} `
          );
          setDayState({
            ...dayState,
            date: dt,
            defaultValue: `${moment(new Date()).format("YYYY")}-${moment(
              moment(dt)
            ).format("MM-DD")}`,
          });
        } else {
          onDayChange(dt);
        }
        container.classList.remove("show");
      }}
      onActiveStartDateChange={({ action, activeStartDate, view }) => {
        setYear();
        // todo drillup is not really working
        // todo default events in day and month view when switching view mode are not loading
        // some preloaded data are still there though
        let day = action === "next" ? 1 : action === "prev" ? -1 : 0;
        if (view === "year" && action === "drillUp") {
          setYear(moment(new Date()).format("YYYY"));
        } else {
          if (action === "next" || action === "prev") {
            setDayState({
              ...dayState,
              currentLabel: moment(
                moment(defaultValue).add(day, "days")
              ).format("MMMM YYYY"),
              defaultValue: moment(
                moment(defaultValue).add(day, "days")
              ).format(dateFormat),
            });
            onDayChange(
              moment(moment(defaultValue).add(day, "days")).format(dateFormat)
            );
          } else {
            if (action !== "drillUp" && action !== "drillDown") {
              setDayState({
                ...dayState,
                currentLabel: moment(moment(activeStartDate)).format(
                  "MMMM YYYY"
                ),
                defaultValue: moment(moment(activeStartDate)).format(
                  dateFormat
                ),
              });
              onDayChange(activeStartDate);
            }
          }
        }
      }}
      next2Label={null}
      prev2Label={null}
      nextLabel={<FeatherIcon icon="chevron-right" />}
      prevLabel={<FeatherIcon icon="chevron-left" />}
      onChange={onChange}
      navigationLabel={() =>
        year
          ? `${moment(moment(defaultValue)).format("MMMM DD,")} ${moment(
            new Date()
          ).format("YYYY")}`
          : `${moment(defaultValue).format("MMMM DD, YYYY")}`
      }
      value={date}
    />
  );
}

export default Day;
