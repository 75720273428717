import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetBusinessById = (params) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading } = useQuery(
    ["business", params],
    businessApis.getBusinessById,
    {
      enabled: !!isLogin && !!params.id,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const businessData = get(data, "data.data");
  return { businessData, isError, error, isLoading };
};

export default useGetBusinessById;
