/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Breadcrumb from "antd/es/breadcrumb";
import "antd/es/breadcrumb/style";
import Spin from "antd/es/spin";
import "antd/es/spin/style";

import { PageHeader } from "../../components/molecules/page-header/PageHeader";
import { Link, useLocation, useParams } from "react-router-dom";
import Heading from "../../components/atoms/heading/heading";
import TemplatesWrapper from "../../components/organisms/spaStaffing/templates/TemplatesWrapper";
import useGetLocation from "../../utilities/hooks/useGetLocation";
import styles from "./createShift/style.module.scss";
import useHotjar from "../../utilities/hooks/useHotjar";
import useUserProfileData from "../../utilities/hooks/useUserProfileData";
import { useBusinessIdGlobal } from "../../utilities/hooks/useBusinessId";
import DataStateHandler from "../../utilities/DataStateHandler";
import { includes } from "lodash";

function Templates({ location, businessId, isCorporateWellness }) {
  useHotjar();
  return (
    <>
      <PageHeader
        className={styles["header-boxed"]}
        title={<Heading as="h1">Templates</Heading>}
        routes={
          <Breadcrumb>
            <Breadcrumb.Item>
              {isCorporateWellness ?
                <Link to="/corporate-wellness">Corporate Wellness</Link>
                : <Link to="/staffing">Spa Staffing</Link>}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {location?.name.charAt(0).toUpperCase() + location?.name.slice(1)}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Templates</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="main">
        <TemplatesWrapper location={location} businessId={businessId} />
      </div>
    </>
  );
}

export default () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();

  const {
    isLoading: isUserLodaing,
    businessId,
    isError: isUserError,
    error: userError,
  } = useUserProfileData(CurrentbusinessId);

  const {
    location,
    isLoading: isLocationLoading,
    isError: isLocationError,
    error: locationError,
  } = useGetLocation(parseInt(id), businessId);

  const data = {
    location,
    businessId,
  };
  return (
    <DataStateHandler
      style={{ height: "83vh" }}
      isLoading={isUserLodaing || isLocationLoading}
      loadingComponent={
        <div className="spin" style={{ height: "83vh" }}>
          <Spin />
        </div>
      }
      isError={isLocationError || isUserError}
      error={userError || locationError}
      data={location}
    >
      <Templates isCorporateWellness={includes(pathname, "corporate-wellness")} {...data} />
    </DataStateHandler>
  );
};
