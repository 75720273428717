/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Typography from "antd/es/typography";
import "antd/es/typography/style";
import Space from "antd/es/space";
import "antd/es/space/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Radio from "antd/es/radio";
import "antd/es/radio/style";

import Heading from "../../../atoms/heading/heading";
import StyledInput from "../../../atoms/inputs/StyledInput";
import styles from "./Billing.module.scss";
import useGetBusinessBilling from "../../../../utilities/hooks/useGetBusinessBilling";
import useGetPropertyBilling from "../../../../utilities/hooks/useGetPropertyBilling";
import Validator from "validator";
import openNotification from "../../../../utilities/openNotification";

const { Text } = Typography;

const Billing = (props) => {
  const {
    billingData: {
      billingType,
      financeContactFirstName,
      financeContactLastName,
      financeContactPhoneNumber,
      financeContactEmail,
    },
    setBillingData,
    type = "business",
    mode,
    billing,
    form,
  } = props;

  useEffect(() => {
    if (billing) {
      const { phone_number, fname, lname, email } = billing;
      setBillingData({
        billingType: "byBusiness",
        financeContactFirstName: fname,
        financeContactLastName: lname,
        financeContactPhoneNumber: phone_number,
        financeContactEmail: email,
      });
      form.setFieldsValue({
        "billing-type": "byBusiness",
        "finance-contact-phone-number": phone_number,
        "finance-contact-first-name": fname,
        "finance-contact-last-name": lname,
        "finance-contact-email": email,
      });
    }
  }, [billing]);

  const discountLevels = [
    { label: "By Business", value: "byBusiness" },
    { label: "By Property", value: "byProperty" },
  ];

  useEffect(() => {
    props.form.setFieldsValue({
      [`billing-type`]: billingType,
    });
  }, []);

  const getWrapper = (title, children) => {
    return (
      <div className="card-wrapper">
        <Card>
          <div
            className="header"
            style={{
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading className="header__text" as="h4">
              {title}
            </Heading>
          </div>
          <div className="body">
            <Card>{children}</Card>
          </div>
        </Card>
      </div>
    );
  };
  const showNotfifcation = (type) => {
    if (type === "byBusiness") {
      openNotification(
        `Changing from Property-level settings to Business-level settings will
       delete all individual selections previously made in each property. 
       This action cannot be undone.`
      );
    }
    if (type === "byProperty") {
      openNotification(
        `Changing from Business-level settings to Property-level settings will allow you to make selection
       for each property, overriding the Business level settings previously set. To change this, 
       you will need to go back to settings.`
      );
    }
  };
  const onBillingTypeChange = (e) => {
    const value = e.target.value;
    setBillingData({ billingType: value });
    if (mode === "edit") {
      showNotfifcation(value);
    }
  };

  return (
    <>
      <div className={styles["wrapper"]}>
        <Row gutter={[0, 16]}>
          {getWrapper(
            "Billing",
            <>
              {type === "business" && (
                <Form.Item
                  colon={false}
                  name="billing-type"
                  label="Billing Type"
                  rules={[
                    {
                      required: true,
                      message: "Billing type is required",
                    },
                  ]}
                >
                  <Radio.Group
                    className={styles["taps-container"]}
                    value={billingType}
                    onChange={(e) => onBillingTypeChange(e, billingType)}
                  >
                    {discountLevels.map((type, index) => (
                      <Radio.Button
                        id={`billing_${type.value}`}
                        key={index}
                        value={type.value}
                        sessionType={type.title}
                        className={styles["discount-tap"]}
                      >
                        {type.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}

              {billingType === "byProperty" ? (
                <Space direction="horizontal" className="mb-20">
                  <Text type="secondary">
                    When you set up a Property, you will be able to input your
                    billing information.
                  </Text>
                </Space>
              ) : (
                <>
                  {mode !== "edit" && (
                    <div className={styles["title"]}>Finance Contact</div>
                  )}
                  <Row gutter={[16, 0]} justify="space-between">
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name="finance-contact-first-name"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "Finance first name required",
                          },
                        ]}
                      >
                        <StyledInput
                          id="billing_finance-contact-first-name"
                          value={financeContactFirstName}
                          onChange={(e) => {
                            setBillingData({
                              financeContactFirstName: e.target.value,
                            });
                          }}
                          placeholder="Enter First Name"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name="finance-contact-last-name"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Finance last name required",
                          },
                        ]}
                      >
                        <StyledInput
                          id="billing_finance-contact-last-name"
                          value={financeContactLastName}
                          onChange={(e) => {
                            setBillingData({
                              financeContactLastName: e.target.value,
                            });
                          }}
                          placeholder="Enter Last Name"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name="finance-contact-phone-number"
                        label="Phone Number"
                        required
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value || !Validator.isMobilePhone(value)) {
                                return Promise.reject(
                                  new Error("Please enter a valid phone number")
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <StyledInput
                          id="billing_finance-contact-phone-number"
                          value={financeContactPhoneNumber}
                          onChange={(e) => {
                            setBillingData({
                              financeContactPhoneNumber: e.target.value,
                            });
                          }}
                          placeholder="Enter Phone Number"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name="finance-contact-email"
                        label="Email"
                        required
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  `Finance contact email required`
                                );
                              }
                              if (!Validator.isEmail(value)) {
                                return Promise.reject(
                                  `${value} is not a valid email address.`
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <StyledInput
                          id="finance-contact-email"
                          value={financeContactEmail}
                          onChange={(e) => {
                            setBillingData({
                              financeContactEmail: e.target.value,
                            });
                          }}
                          placeholder="Enter Email"
                          maxLength={255}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default (props) => {
  const {
    businessId,
    status,
    billingData: { billingType },
  } = props;

  const { billing: businessBilling, isLoading: isBusinessBillingLoading } =
    useGetBusinessBilling({
      id: businessId,
      status,
      type: billingType,
    });
  const { billing: propertyBilling, isLoading: isPropertyBillingLoading } =
    useGetPropertyBilling(props.locationId);

  if (isBusinessBillingLoading || isPropertyBillingLoading) {
    return <Skeleton active />;
  }

  return <Billing {...props} billing={businessBilling || propertyBilling} />;
};
