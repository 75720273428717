import get from "lodash/get";
import { useQuery } from "react-query";
import templateApis from "../api/templates";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetTemplateUsers = (params) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading } = useQuery(
    ["templatesUsers", params],
    templateApis.getTemplateUsers,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const users = get(data, "data.data");
  return { users, isError, error, isLoading };
};

export default useGetTemplateUsers;
