import React from "react";
import Modal from "antd/es/modal";
import "antd/es/modal/style";

import { Button } from "../../atoms/buttons/buttons";
import styles from "./style.module.scss";

import deleteLocationModalIcon from "../../../static/img/icon/delete-location-modal-icon.svg";

function DeleteModal(props) {
  const { locationName, isVisible, onCancel, onSubmit, isLoading } = props;

  return (
    <Modal
      className={styles["modal"]}
      open={isVisible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      centered
    >
      <img src={deleteLocationModalIcon} alt="trash" />
      <p>
        Are you sure you want to delete {locationName} location? <br />
        This action cannot be undone.
      </p>
      <footer>
        <Button
          size="small"
          onClick={onSubmit}
          type="primary"
          disabled={isLoading}
        >
          Yes, delete
        </Button>
        <Button size="small" onClick={onCancel} type="secondary">
          No, cancel
        </Button>
      </footer>
    </Modal>
  );
}

export default DeleteModal;
