import React from "react";
import img404 from "../../../static/img/404.svg";
import { Heading, MainActionButton } from "../../atoms";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import { useNavigate } from "react-router-dom";
import "../index.css";
import styles from "./emptyContentView.module.scss";

const EmptyContentView = (props) => {
  const navigate = useNavigate();
  const { title, text, btnCopy, btnLink, headingFont = "h0" } = props;
  return (
    <Row className={styles["full-height"]}>
      <Col
        xl={{ span: 8, offset: 8 }}
        lg={{ span: 8, offset: 8 }}
        md={{ span: 22, offset: 1 }}
        xs={{ span: 24, offset: 0 }}
        className={styles["text-align-center"]}
      >
        <img
          src={img404}
          alt="404"
          className={`${styles["margin-bottom-40"]} ${styles["error-img"]}`}
        />
        <Heading as={headingFont} className={styles["title"]}>
          {title}
        </Heading>
        <p>{text}</p>
        <MainActionButton
          copy={btnCopy}
          htmlType="button"
          onClick={() => {
            navigate(btnLink);
          }}
        />
      </Col>
    </Row>
  );
};

export default EmptyContentView;
