import React from "react";
import styles from "./styles.module.scss";

export const FlexRow = (props) => {
  return (
    <div
      style={props.style}
      className={`${styles["flex-row"]} ${props.className}`}
    >
      {props.children}
    </div>
  );
};
