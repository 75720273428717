import axios from "axios";

const URL = `${process.env.REACT_APP_RAILS_PRODUCTION}/api/partner/s3`;

export const generateUrl = () => {
  return axios.get(`${URL}/get`);
};

export const uploadToS3 = (url, file) => {
  const body = file;
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.put(url, body, headers);
};
