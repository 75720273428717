import React, { Suspense } from "react";
import Tabs from "antd/es/tabs";
import "antd/es/tabs/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import moment from "moment-timezone";
import FeatherIcon from "feather-icons-react";

import { Cards } from "../../../atoms/cards/Cards";
import styles from "./EditBusiness.module.scss";
import { Button } from "../../../atoms/buttons/buttons";
import Overview from "./Overview";
import Properties from "./Properties";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import Restricted from "../../../../PermissionsProvider/Restricted";
import Files from "./Files";

function EditBusiness({ business }) {
  const items = [
    {
      label: "Overview",
      key: 1,
      children: (
        <Restricted
          to="overview"
          resource="business"
          fallback={
            <div>
              You do not currently have the authorization to access/view the
              overview of this business.
            </div>
          }
        >
          <Overview business={business} />
        </Restricted>
      ),
    },
    {
      label: "Files",
      key: 2,
      children: (
        <Restricted
          to="view_files"
          resource="business"
          fallback={
            <div>
              You do not currently have the authorization to access/view the
              Files of this business.
            </div>
          }
        >
          <Files business={business} />
        </Restricted>
      ),
    },
    {
      label: "Properties",
      key: 3,
      children: (
        <Restricted
          to="properties"
          resource="business"
          fallback={
            <div>
              You do not currently have the authorization to access/view the
              properties of this business.
            </div>
          }
        >
          <Properties business={business} />
        </Restricted>
      ),
    },
    {
      label: "Settings",
      key: 4,
      children: <Settings business={business} />,
    },
  ];

  let { name, status, disabled } = business;
  return (
    <>
      <Suspense
        fallback={
          <Cards headless>
            <Skeleton active />
          </Cards>
        }
      >
        <Cards headless>
          <div className={styles["card-content"]}>
            <div>
              <div className={styles["business__name"]}>
                <div>{name}</div>
                <span
                  className={`status ${
                    disabled
                      ? "disabled"
                      : status === "active"
                      ? "Success"
                      : status === "pending"
                      ? "warning"
                      : status === "draft"
                      ? "in-progress"
                      : "error"
                  }`}
                >
                  {disabled ? "Disabled" : capitalize(status)}
                </span>
              </div>
              <span className={styles["business__created-at"]}>
                Added {moment(business.createdAt).format("MMMM DD, YYYY")}
              </span>
            </div>
            <Restricted to="create" resource="location" fallback={<></>}>
              <Button
                className="btn-add_new"
                size="default"
                type="sootheButton"
                key="1"
              >
                <Link
                  to={`/businesses/${business.id}/properties`}
                  state={{ mode: "business" }}
                  className={styles["link-container"]}
                >
                  <FeatherIcon icon="plus" size={20} /> Add New Property
                </Link>
              </Button>
            </Restricted>
          </div>
          <div></div>
        </Cards>
      </Suspense>

      <Suspense
        fallback={
          <Cards headless>
            <Skeleton active />
          </Cards>
        }
      >
        <div className={styles["card-container"]}>
          <Cards headless={true}>
            <Tabs
              className={styles["tabs"]}
              size="small"
              tabBarGutter={15}
              items={items}
            />
          </Cards>
        </div>
      </Suspense>
    </>
  );
}

export default EditBusiness;
