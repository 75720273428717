import React from "react";

import StaffingCalendar from "../../components/organisms/spaStaffing/Calendar";
import CalendarWrapper from "../../components/organisms/spaStaffing/CalendarWrapper";
import useHotjar from "../../utilities/hooks/useHotjar";

function Calendar() {
  useHotjar();
  return (
    <CalendarWrapper>
      <StaffingCalendar />
    </CalendarWrapper>
  );
}

export default Calendar;
