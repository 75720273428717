/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Select from "antd/es/select";
import "antd/es/select/style";
import FeatherIcon from "feather-icons-react";
import "antd/es/space/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Checkbox from "antd/es/checkbox";
import "antd/es/checkbox/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import Heading from "../../../atoms/heading/heading";
import styles from "./Permissions.module.scss";
import map from "lodash/map";
import useGetBusinessPermissions from "../../../../utilities/hooks/useGetBusinessPermissions";
import StyledInput from "../../../atoms/inputs/StyledInput";
import { filter } from "lodash";
import useUserProfileData from "../../../../utilities/hooks/useUserProfileData";
import { useBusinessIdGlobal } from "../../../../utilities/hooks/useBusinessId";

const getWrapper = (title, children) => {
  return (
    <div className="card-wrapper">
      <Card>
        <div
          className="header"
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading className="header__text" as="h4">
            {title}
          </Heading>
        </div>
        <div className="body">
          <Card>{children}</Card>
        </div>
      </Card>
    </div>
  );
};
const getbusinessPermissionList = (selectedDealtypes, businessPermissions) => {
  const permissionList = selectedDealtypes?.map((type) => {
    const dealType = businessPermissions?.find((perm) => perm.id === type);
    return dealType?.permissions;
  });
  return permissionList?.flat();
};

const getbusinessPermissionWithIdsList = (
  selectedDealtypes,
  businessPermissions
) => {
  const permissionList = selectedDealtypes?.map((type) => {
    const dealType = businessPermissions?.find((perm) => perm.id === type);
    return dealType?.permissions.map((item) => ({
      categoryId: dealType.id,
      id: item,
    }));
  });
  return permissionList?.flat();
};

const Permissions = (props) => {
  const {
    form,
    permissionsData: { selectedDealTypes, selectedPermission, businessDomain },
    setPermissionsData,
    businessPermissions,
  } = props;
  const selectedPersmissionsWithCategoryIds = getbusinessPermissionWithIdsList(
    selectedDealTypes,
    businessPermissions
  );
  let dealTypes = businessPermissions.map((businPerm) => ({
    label: businPerm.name,
    value: businPerm.id,
  }));

  const guestBookingValue = dealTypes?.find(
    (dt) => dt.label.toLowerCase() === "guest booking"
  )?.value;

  const isGuestBookingSelected = selectedDealTypes?.includes(guestBookingValue);

  const { businessId } = useBusinessIdGlobal();
  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;

  dealTypes = isSootheMember ? dealTypes : filter(dealTypes, (elmt) => (elmt.label !== "Coporate Wellness" && elmt.label !== "Corporate"));

  return (
    <>
      <div className={styles["wrapper"]}>
        <Row gutter={[0, 16]}>
          {getWrapper(
            "Permission",
            <>
              <Form.Item
                colon={false}
                name="deal-type"
                label="Type of Deal* (Select all that apply)"
                rules={[{ required: true, message: "Deal Type is required" }]}
              >
                <div className={styles["checkbox-group"]}>
                  <Checkbox.Group
                    options={dealTypes}
                    value={selectedDealTypes}
                    onChange={(value, option) => {
                      const selectedOptions =
                        selectedPersmissionsWithCategoryIds.filter((el) =>
                          selectedPermission.includes(el.id.id)
                        );
                      const newSelectedPermissions = selectedOptions.filter(
                        (perm) => value.includes(perm.categoryId)
                      );

                      if (value.length > selectedDealTypes.length) {
                        const dealType = value.filter(
                          (x) => !selectedDealTypes.includes(x)
                        );
                        const options = [...selectedOptions.map((el) => el.id)];
                        const permOptions = businessPermissions.find(
                          (item) => dealType[0] === item.id
                        );
                        if (permOptions) {
                          options.push(...permOptions.permissions);
                        }
                        setPermissionsData({
                          selectedDealTypes: value,
                          selectedPermission: Array.from(
                            new Set([...options.map((opt) => opt.id)])
                          ),
                        });
                        form.setFieldsValue({
                          providers: Array.from(
                            new Set([...options.map((opt) => opt.id)])
                          ),
                        });
                      } else {
                        setPermissionsData({
                          selectedDealTypes: value,
                          selectedPermission: newSelectedPermissions.map(
                            (el) => el.id.id
                          ),
                        });
                        form.setFieldsValue({
                          providers: newSelectedPermissions.map(
                            (el) => el.id.id
                          ),
                        });
                      }
                    }}
                  ></Checkbox.Group>
                </div>
              </Form.Item>
              {isGuestBookingSelected && (
                <div style={{ display: "flex" }}>
                  <Form.Item
                    name="business-domain"
                    label="Business Domain"
                    rules={[
                      { required: true, message: "Business domain required" },
                    ]}
                    style={{ maxWidth: "18.75rem", width: "100%" }}
                  >
                    <StyledInput
                      value={businessDomain}
                      onChange={(e) =>
                        setPermissionsData({ businessDomain: e.target.value })
                      }
                      placeholder="Business domain"
                      maxLength={255}
                    />
                  </Form.Item>
                  <span style={{ margin: "45px 0px 0px 10px" }}>
                    .business.soothe.com
                  </span>
                </div>
              )}
              <Form.Item
                colon={false}
                name="providers"
                label="This business may view the following:*"
                rules={[
                  { required: true, message: "Permissions are required" },
                ]}
              >
                <Select
                  className={styles["appointment-select"]}
                  placeholder="Enter permissions here"
                  suffixicon={<FeatherIcon icon="chevron-down" size="15px" />}
                  mode="multiple"
                  allowClear
                  showArrow
                  value={selectedPermission}
                  onChange={(value) => {
                    const selectedOptions =
                      selectedPersmissionsWithCategoryIds.filter((el) =>
                        value.includes(el.id.id)
                      );
                    const selectedDeals = selectedDealTypes.filter((id) => {
                      return selectedOptions.some((element) => {
                        return element.categoryId === id;
                      });
                    });
                    setPermissionsData({
                      selectedPermission: value,
                      selectedDealTypes: selectedDeals,
                    });
                    form.setFieldsValue({
                      "deal-type": selectedDeals,
                    });
                  }}
                >
                  {map(
                    selectedPersmissionsWithCategoryIds,
                    (permission, it) => {
                      return (
                        <Select.Option
                          key={`list-${it}`}
                          value={permission?.id?.id}
                          className={styles["providers-select"]}
                          style={{ padding: "2px" }}
                        >
                          <span> {permission?.id?.perm_description}</span>
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default (props) => {
  const { businessPermissions, isLoading } = useGetBusinessPermissions();
  if (!businessPermissions || isLoading) {
    return <Skeleton active />;
  }
  return <Permissions {...props} businessPermissions={businessPermissions} />;
};
