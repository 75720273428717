import React from "react";
import styled from "styled-components";
import "antd/es/form/style";
import { Modal } from "../../../atoms/modals/antd-modals";
import { Button } from "../../../atoms/buttons/buttons";
import styles from "./staffing.module.scss";

function DeleteTemplateModal({ isOpen, onExit, onSubmit, loading }) {
  return (
    <Modal
      title={`Are you sure you want to delete this template?`}
      closable={false}
      visible={isOpen}
      centered
      className={styles["modal-wrapper"]}
      footer={[
        <Button
          className="my-10"
          key="cancel"
          size="small"
          onClick={() => {
            onExit();
          }}
          type="light"
        >
          No, exit now
        </Button>,
        <Button
          key="delete"
          size="small"
          type="redButton"
          htmlType="submit"
          loading={loading}
          onClick={() => {
            onSubmit();
          }}
        >
          Yes, delete this template
        </Button>,
      ]}
    >
      <div>This action cannot be undone.</div>
    </Modal>
  );
}

export default DeleteTemplateModal;
