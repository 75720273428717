import get from "lodash/get";

import { useQuery } from "react-query";
import user from "../api/user";

const useGetMaintenance = () => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    ["maintenance"],
    () => user.checkAppMaintenance(),
    {
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );

  const maintenanceData = get(data, "data.data");
  return { maintenanceData, isError, error, isLoading, refetch };
};

export default useGetMaintenance;
