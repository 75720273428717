/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Breadcrumb from "antd/es/breadcrumb";
import "antd/es/breadcrumb/style";
import Spin from "antd/es/spin";
import "antd/es/spin/style";

import { PageHeader } from "../../../components/molecules/page-header/PageHeader";
import { Link, useParams } from "react-router-dom";
import EditBusiness from "../../../components/organisms/businesses/EditBusiness";
import useGetBusinessById from "../../../utilities/hooks/useGetBusinessById";
import { useBusinessIdGlobal } from "../../../utilities/hooks/useBusinessId";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import useHotjar from "../../../utilities/hooks/useHotjar";
import Restricted from "../../../PermissionsProvider/Restricted";
import DataStateHandler from "../../../utilities/DataStateHandler";

function BusinessDetails({ business }) {
  return (
    <div>
      <PageHeader
        className="header-boxed"
        routes={
          <Restricted
            to="property_toggle"
            resource="admin"
            fallback={<>{business.name}</>}
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/businesses">Businesses</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{business.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Restricted>
        }
      />
      <div className="main">
        <EditBusiness business={business} />
      </div>
    </div>
  );
}

export default () => {
  useHotjar();
  const { businessId } = useParams();
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();

  const {
    isLoading: isUserLodaing,
    businessId: businessID,
    isError: isUserError,
    error: userError,
  } = useUserProfileData(CurrentbusinessId);

  const {
    businessData: business,
    isLoading: isBusinessLoading,
    isError: isBusinessError,
    error: businessError,
  } = useGetBusinessById({
    id: businessId,
  });

  const data = {
    business,
  };
  return (
    <DataStateHandler
      style={{ height: "83vh" }}
      isLoading={isBusinessLoading || isUserLodaing}
      loadingComponent={
        <div className="spin">
          <Spin />
        </div>
      }
      isError={isBusinessError || isUserError}
      error={businessError || userError}
      data={business}
    >
      <BusinessDetails {...data} />
    </DataStateHandler>
  );
};
