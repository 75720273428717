import React from "react";
import Input from "antd/es/input";
import "antd/es/input/style";

import "./StyledInput.scss";

const StyledInput = (props) => {
  return <Input {...props} className="styled-input" />;
};

export default StyledInput;
