import React from "react";
import capitalize from "lodash/capitalize";
import FeatherIcon from "feather-icons-react";

export const ViewAppointmentModalHeader = ({
  appointmentNumber,
  status,
  needsAttention,
  partiallyFilled,
  refetch
}) => {
  return (
    <div>
      <span
        style={{ marginRight: "20px" }}
      >{`Appointment #${appointmentNumber}`}</span>
      <span
        className={`status ${status === "completed" || status === "complete"
          ? "Success"
          : partiallyFilled
            ? "partially-filled"
            : status === "pending" && needsAttention
              ? "needs-attention"
              : status === "pending"
                ? "warning"
                : status === "filled" || status === "assigned"
                  ? "in-progress"
                  : status === "disabled"
                    ? "disabled"
                    : "error"
          }`}
          style={{ marginRight: "10px" }}
      >
        {partiallyFilled
          ? "Partially filled"
          : status === "pending" && needsAttention
            ? "Could not fill"
            : capitalize(status)}
      </span>
      <span style={{ cursor: "pointer", color: "#9299B8", padding:"10px" }} onClick={()=> refetch()}>
        <FeatherIcon size={16} icon="rotate-cw" className="anticon" />
      </span>
    </div>
  );
};
