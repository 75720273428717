import React from "react";
import Form from "antd/es/form";
import "antd/es/form/style";

import { Modal } from "../../../components/atoms/modals/antd-modals";
import { StyledInput } from "../../../components/atoms";
import { Button } from "../../../components/atoms/buttons/buttons";
import styles from "./style.module.scss";

function TemplateModal({
  onExit,
  onSave,
  title,
  setShiftTitle,
  loading,
  isOpen,
}) {
  return (
    <Modal
      wrapClassName={styles["modal-container"]}
      title={`What would you like to name this template?`}
      visible={isOpen}
      footer={null}
      onCancel={onExit}
      centered
    >
      <div className="view-modal-body">
        <div className="view-modal-body-card" style={{ marginRight: 0 }}>
          <Form.Item
            key="header-1"
            colon={false}
            name="shift-name"
            style={{ margin: "10px 0" }}
            rules={[
              {
                message: "Template name is required",
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("Template name is required");
                  }
                  return Promise.resolve();
                },
              },
              {
                message: "Name must be maximum 50 characters",
                required: true,
                validator: (_, value) => {
                  if (value && value.length > 50) {
                    return Promise.reject("Name must be maximum 50 characters");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <StyledInput
              id="create-shift_title"
              placeholder="Enter template name here"
              value={title}
              onChange={(e) => setShiftTitle({ shiftTitle: e.target.value })}
            />
          </Form.Item>
          <footer className="mt-20">
            <Button
              className="mr-10"
              key={1}
              size="small"
              onClick={onExit}
              type="light"
            >
              Go back without saving
            </Button>

            <Button
              style={{ margin: 0 }}
              key={2}
              size="small"
              loading={loading}
              onClick={onSave}
              type="sootheButton"
            >
              Save template
            </Button>
          </footer>
        </div>
      </div>
    </Modal>
  );
}

export default TemplateModal;
