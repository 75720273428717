import { useQuery } from "react-query";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";
import shift from "../api/shift";

const useGetShifts = (info) => {
  const { isLogin } = useTryAutoLogin();
  const { data, isError, isLoading, refetch } = useQuery(
    ["shifts", info],
    shift.getAllShifts,
    {
      enabled: !!isLogin && !!info.locationId,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const shiftsData = get(data, "data.data.events");
  return { data: shiftsData, isError, isLoading, refetch };
};

export default useGetShifts;
