import React, { createContext, useContext, useState } from "react";

const PermissionContext = createContext();

export default function PermissionProvider({ children }) {
  const [permissions, setPermissions] = useState();
  const [businessPermissions, setBusinessPermissions] = useState();

  const resetPermissions = () => {
    setPermissions();
    setBusinessPermissions();
  };

  let value = {
    permissions,
    setPermissions,
    businessPermissions,
    setBusinessPermissions,
    resetPermissions,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}

export const usePermissions = () => useContext(PermissionContext);
