import React, { useReducer } from "react";
import Radio from "antd/es/radio";
import Form from "antd/es/form";
import "antd/es/form/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";
import appointmentsAPI from "../../../utilities/api/appointment";
import shiftsAPI from "../../../utilities/api/shift";
import DataStateHandler from "../../../utilities/DataStateHandler";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetCancellationReasons from "../../../utilities/hooks/useGetCancellationReasons";
import FeeModal from "../viewShift/FeeModal";
import styles from "./style.module.scss";

function ReasonsToCancelAppointmentModal({
  isOpen,
  onExit,
  selectedReasonForCancel,
  setSelectedReasonForCancel,
  loading,
  setLoading,
  appointmentId,
  businessId,
}) {
  const {
    data: selectedAppointment,
    isLoading,
    refetch,
    should_show_warning,
    able_to_adjust
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const {
    data: cancellationReasons,
    isLoading: isCancellationReasonsLoading,
    isError: isCancellationError,
    error: cancellationError,
  } = useGetCancellationReasons(businessId);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [cancelAppointment, setCancelAppointment] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isModalOpen: false,
      isModalloading: false,
      isFeeModalOpen: false,
      isFeeModalLoading: false,
      isReasonsModalOpen: false,
      isReasonsModalLoading: false,
    }
  );

  const handleCancelAppointment = () => {
    let data = {
      id: selectedAppointment.appointment_number,
      businessId: selectedAppointment.business_id,
      locationId: selectedAppointment.location_id,
      client_cancellation_reason_id: selectedReasonForCancel.id,
      quoted_fee: selectedAppointment.cancellation_fee,
      cancellation_reason_index: selectedReasonForCancel.index,
    };

    if (
      (selectedAppointment.is_staffing &&
      selectedAppointment.cancellation_fee)
      || (selectedAppointment.is_corporate &&
        selectedAppointment.cancellation_fee)
    ) {
      setCancelAppointment({
        isReasonsModalLoading: false,
        isReasonsModalOpen: false,
        isFeeModalOpen: true,
        data,
      });
    } else {
      setCancelAppointment({ isReasonsModalLoading: true });
      onCancelAppointment(data);
    }
  };

  const onCancelAppointment = async (data) => {
    setLoading(true);

    try {
      let response;
      if (selectedAppointment.is_staffing || selectedAppointment.is_corporate) {
        response = await shiftsAPI.deleteShift(data);
      } else response = await appointmentsAPI.deleteAppointment(data);
      setLoading(false);
      setCancelAppointment({
        isModalLoading: false,
        isModalOpen: false,
        isFeeModalLoading: false,
        isFeeModalOpen: false,
        isReasonsModalOpen: false,
      });
      openNotification("Cancel Appointment", response.data.message, "success");
      refetch();
      queryClient.refetchQueries("appointments");
      queryClient.refetchQueries("shifts");
      queryClient.refetchQueries("businessAppointments");
      queryClient.refetchQueries("allBusinessesAppointments");
      onExit();
    } catch (err) {
      setLoading(false);
      openNotification("Error", err.response.data.message, "error");
    }
  };

  return (
    <>
      <Modal
        wrapClassName={styles["modal-container"]}
        title={
          !isCancellationReasonsLoading &&
          `Please share your reason for cancelling Appointment #${selectedAppointment.appointment_number}.`
        }
        visible={isOpen}
        footer={null}
        onCancel={onExit}
        centered
      >
        <DataStateHandler
          style={{ height: "40vh" }}
          isLoading={isLoading || isCancellationReasonsLoading}
          loadingComponent={<Skeleton active paragraph={{ rows: 8 }} />}
          isError={isCancellationError}
          error={cancellationError}
          data={cancellationReasons?.length}
        >
          {cancellationReasons && (
            <div className="view-modal-body">
              <Form
                form={form}
                name="reasons"
                onFinish={handleCancelAppointment}
              >
                <Form.Item
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "Please select a reason",
                    },
                  ]}
                >
                  <Radio.Group>
                    <div className="ant-radio-btn-vertical">
                      {cancellationReasons.map((cancellationReason, index) => (
                        <Radio
                          key={cancellationReason.id}
                          disabled={false}
                          value={cancellationReason.id}
                          onChange={(e) =>
                            setSelectedReasonForCancel({
                              id: e.target.value,
                              index,
                            })
                          }
                        >
                          {cancellationReason.reason}
                        </Radio>
                      ))}
                    </div>
                    {/* <div className="ant-radio-btn_input">
                <Radio
                  key={"other"}
                  disabled={false}
                  value={"other"}
                  onChange={(e) => setSelectedReasonForCancel(e.target.value)}
                >
                  Other:
                </Radio>

                <StyledInput
                  placeholder="Enter other reason"
                  onChange={(e) => setOtherReasonForCancel(e.target.value)}
                />
              </div> */}
                  </Radio.Group>
                </Form.Item>
                <footer>
                  <Button
                    key="cancel"
                    size="small"
                    onClick={onExit}
                    type="light"
                  >
                    Exit now
                  </Button>

                  <Button
                    style={{ margin: 0 }}
                    key="submit"
                    size="small"
                    type="danger"
                    htmlType="submit"
                    loading={loading}
                  >
                    Cancel this appointment
                  </Button>
                </footer>
              </Form>
            </div>
          )}
        </DataStateHandler>
      </Modal>
      {cancelAppointment.isFeeModalOpen && (
        <FeeModal
          isOpen={cancelAppointment.isFeeModalOpen}
          loading={cancelAppointment.isFeeModalLoading}
          title={"Are you sure you want to cancel this appointment?"}
          body={
            <div>
              <p>
                Cancelling this appointment will incur the fee below as stated
                in our cancellation policy
              </p>
              <p>
                Your total charge will be $
                {parseInt(selectedAppointment.cancellation_fee).toFixed(2)}.
              </p>
            </div>
          }
          action="cancel"
          onExit={() =>
            setCancelAppointment({
              isFeeModalOpen: false,
            })
          }
          onSubmit={() => {
            setCancelAppointment({
              isFeeModalLoading: true,
            });
            onCancelAppointment(cancelAppointment.data);
          }}
        />
      )}
    </>
  );
}

export default ReasonsToCancelAppointmentModal;
