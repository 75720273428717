/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import Spin from "antd/es/spin";
import "antd/dist/antd.min.css";
import mainRoutes from "./routes";
import { useViewGlobal } from "./utilities/hooks/useView";

function App() {
  const { view } = useViewGlobal();
  return (
    <Router>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Routes>{mainRoutes(view)}</Routes>
      </Suspense>
    </Router>
  );
}
export default () => {
  return <App />;
};
