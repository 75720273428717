import {axiosInstance as axios, nodePortalAxiosInstance} from "./axiosInstance";

const notificationsEndpoints = {
  notifications: "/notifications",
  new_notifications: "/notifications/find_all_with_notifications",
  notificationsTableStructure: "/notifications/get",
  notificationsToggle: "/notifications/toggle_all",
};

const notifications = {
  getNotifications({ queryKey: [, params] }) {
    const { location, page, perPage: size, businessId } = params;

    return nodePortalAxiosInstance.get(`${notificationsEndpoints.new_notifications}`, {
      params: {
        page,
        size,
        businessId,
        ...(location === "all" || !location ? {} : { location }),
      },
    });
  },
  getNotificationsTableStructure() {
    return nodePortalAxiosInstance.get(`${notificationsEndpoints.notifications}`);
  },
  toggleAllNotifications({ location, businessId, notificationId }) {
    return nodePortalAxiosInstance.post(`${notificationsEndpoints.notificationsToggle}`, {
      notification_id: notificationId,
      ...(location === "all" ? {} : { location }),

      businessId,
    });
  },
  updateNotification({ location, sms, email, userID, businessId, user_id }) {
    return nodePortalAxiosInstance.post(`${notificationsEndpoints.notifications}`, {
      user_notification_id: userID,
      sms,
      email,
      ...(location ? { location } : {}),
      businessId,
      user_id,
    });
  },
};

export default notifications;
