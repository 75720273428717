import { useQuery } from "react-query";
import templateApis from "../api/templates";
import useTryAutoLogin from "./useTryAutoLogin";
import { useLocation } from "react-router";
import { filter, includes, get } from "lodash";

const useGetTemplates = (params) => {
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["templates", params],
    templateApis.getAllTemplates,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const templates = isCorporateWellness ? filter(get(data, "data.data.rows"), (el) => (!get(el, "data.is_training", false) && get(el, "shiftType", "") === "corporate")) : get(data, "data.data.rows");
  const totalItems = get(data, "data.data.totalItems");
  return { templates, isError, error, isLoading, totalItems, refetch };
};

export default useGetTemplates;
