import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetBusinessProperties = (params) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, refetch } = useQuery(
    ["businessProperties", params],
    businessApis.getBusinessProperties,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const properties = get(data, "data.data.rows");
  const totalItems = get(data, "data.data.totalItems");
  return { properties, isError, isLoading, totalItems, refetch };
};

export default useGetBusinessProperties;
