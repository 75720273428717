import { hotjar } from "react-hotjar";

function useHotjar() {
  if (process.env.NODE_ENV !== "production") return;
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_TRACKING_ID,
    process.env.REACT_APP_SNIPPET_VERSION_ID
  );
}

export default useHotjar;
