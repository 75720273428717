import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const useGetBusinessOverview = (params) => {
  const { permissions } = usePermissions();
  const { isLogin } = useTryAutoLogin();

  const { data, refetch, isLoading } = useQuery(
    ["businessAppointments", params],
    businessApis.getBusinessOverview,
    {
      enabled:
        !!isLogin &&
        !!params.id &&
        !!permissions?.business?.includes("overview"),
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const location = get(data, "data.data.location");
  const appointments = get(data, "data.data.appointments");
  return { appointments, location, isLoading, refetch };
};

export default useGetBusinessOverview;
