import React from "react";
import FeatherIcon from "feather-icons-react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Skeleton } from "antd";

import { Cards } from "../../atoms/cards/Cards";
import AppointmentCard from "../../molecules/overview/AppointmentCard";
import styles from "./style.module.scss";
import { CalendarButtonPageHeader } from "../../atoms/buttons/calendar-button/calendar-button";
import DataStateHandler from "../../../utilities/DataStateHandler";

const responsiveSettings = [
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 300,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 0,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const properties = {
  slidesToScroll: 4,
  slidesToShow: 4,
  infinite: false,
  autoplay: false,
  prevArrow: (
    <span className={styles["slider-buttons"]}>
      <FeatherIcon size={30} icon="chevron-left" />
    </span>
  ),
  nextArrow: (
    <span className={styles["slider-buttons"]}>
      <FeatherIcon size={30} icon="chevron-right" />
    </span>
  ),
  responsive: responsiveSettings,
};

function AppointmentsAndShiftsCard({
  isLoading,
  title,
  business,
  location,
  appointments,
  state,
  onClick,
  onCancel,
  visible,
  setVisible,
  handleRangeChange,
  permissions,
  isbutton,
  isError,
  error,
}) {
  return (
    <Cards
      title={title}
      isbutton={
        isbutton ? (
          isbutton
        ) : (
          <CalendarButtonPageHeader
            handleRangeChange={handleRangeChange}
            state={state}
            onClick={onClick}
            onCancel={onCancel}
            visible={visible}
            setVisible={setVisible}
            className={styles["calendar-button"]}
            calendarClassName={styles["calendar"]}
          />
        )
      }
    >
      <DataStateHandler
        isLoading={isLoading}
        loadingComponent={<Skeleton loading={isLoading} active />}
        isError={isError}
        error={error}
        data={appointments?.length}
      >
        {!isLoading && appointments?.length > 0 ? (
          appointments?.length <= 4 ? (
            <div className={styles["responsive-flex"]}>
              {appointments?.map((appointment, index) => (
                <AppointmentCard
                  key={index}
                  appointmentData={appointment}
                  business={business}
                  location={location}
                />
              ))}
            </div>
          ) : (
            <Slide {...properties}>
              {appointments?.map((appointment, index) => (
                <AppointmentCard
                  key={index}
                  appointmentData={appointment}
                  business={business}
                  location={location}
                />
              ))}
            </Slide>
          )
        ) : (
          <div style={{ textAlign: "center" }}>
            No appointments or shifts need attention at this time.
          </div>
        )}
      </DataStateHandler>
    </Cards>
  );
}

export default AppointmentsAndShiftsCard;
