import React, { useEffect } from "react";
import CalenDar from "react-calendar";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import { useQueryClient } from "react-query";

function Month({ setMonthState, monthState, onMonthChange, dateFormat }) {
  const queryClient = useQueryClient();

  const { date, container } = monthState;

  // useEffect(() => {
  //   onMonthChange(date);
  // }, []);

  // const onChange = (dt) => {
  //   queryClient.refetchQueries("getAppointmentDetails");
  //   setMonthState({
  //     ...monthState,
  //     date: dt,
  //     defautlValue: moment(dt).format(dateFormat),
  //   });
  // };

  return (
    <CalenDar
      id="calendar_date-picker"
      // onClickMonth={(date) => {
      //   onMonthChange(date);
      //   container.classList.remove("show");
      // }}
      onActiveStartDateChange={({ action, activeStartDate, view }) => {
        let year = activeStartDate.getFullYear();
        let month = String(activeStartDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        let day = String(activeStartDate.getDate()).padStart(2, '0');

        let formattedDateObj = `${year}-${month}-${day}`;
        let relMoment = moment(formattedDateObj, dateFormat);
        if (action === "prev" || action === "next") {
          setMonthState({
            ...monthState,
            currentLabel: relMoment.format("MMMM YYYY"),
            defaultValue: relMoment.format(dateFormat),
            date: activeStartDate
          });
          onMonthChange(activeStartDate);
          queryClient.refetchQueries("getAppointmentDetails");
        }
      }}
      next2Label={null}
      prev2Label={null}
      nextLabel={<FeatherIcon icon="chevron-right" />}
      prevLabel={<FeatherIcon icon="chevron-left" />}
      // onChange={onChange}
      value={date}
      view="month"
      views={['month']}
      minDetail="month"
      maxDetail="month"
    />
  );
}

export default Month;
