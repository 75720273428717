import React, { createContext, useContext, useState } from "react";

const context = createContext();

export default function LocationContext({ children }) {
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    id: "",
  });

  const value = {
    selectedLocation,
    setSelectedLocation,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
}

export const useLocationIdGlobal = () => useContext(context);
