import { useQuery } from "react-query";
import rolesApi from "../api/roles";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetPermissionsPerRole = (id) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, status } = useQuery(
    ["permissionsPerRole", id],
    rolesApi.getPermissionsPerRole,
    {
      enabled: !!isLogin && !!id,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const permissions = get(data, "data.data");
  return { permissions, isError, isLoading, status };
};

export default useGetPermissionsPerRole;
