import get from "lodash/get";
import { useQuery } from "react-query";
import business from "../api/businesses";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetDiscounts = (id) => {
  const { isLogin } = useTryAutoLogin();
  const { permissions } = usePermissions();

  const { data, isError, isLoading } = useQuery(
    ["discounts", id],
    business.getDisconts,
    {
      enabled:
        !!isLogin && !!id && !!permissions?.business?.includes("billing"),
      cacheTime: 0,
      staleTime: 0,
    }
  );
  const discounts = get(data, "data.data.result");
  return {
    discounts,
    isError,
    isLoading,
  };
};

export default useGetDiscounts;
