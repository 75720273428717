import React, { Fragment } from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Input from "antd/es/input";
import "antd/es/input/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import styles from "./DiscountTable.module.scss";
import Radio from "antd/es/radio";
import "antd/es/radio/style";
import Restricted from "../../../../../PermissionsProvider/Restricted";

function DiscountTable({ discountServices = [], setDiscount, form }) {
  const discountTypes = [
    { label: "$", value: "amount" },
    { label: "%", value: "percentage" },
  ];

  const onApplyToAllDiscount = () => {
    const discountServicesValues = structuredClone(discountServices);
    const applayedValue = discountServicesValues[0][1].value;
    discountServicesValues.forEach((item, index) => {
      item[1].value = applayedValue;
      form.setFieldsValue({
        [`discount-${index}`]: applayedValue,
      });
    });
    setDiscount({ services: discountServicesValues });
  };
  const onApplyToAllType = () => {
    const discountServicesValues = structuredClone(discountServices);
    const applayedValue = discountServicesValues[0][1].type;
    discountServicesValues.forEach((item, index) => {
      item[1].type = applayedValue;
      form.setFieldsValue({
        [`type-${index}`]: applayedValue,
      });
    });
    setDiscount({ services: discountServicesValues });
  };
  return (
    <nav>
      <ul style={{ margin: 0, padding: 0 }}>
        <li className="column-title">
          <Row>
            <Col
              style={{ margin: 0, padding: 0, marginBottom: "0px" }}
              className="col"
              span={8}
            >
              Service Type
            </Col>
            <Col
              style={{ margin: 0, padding: 0, marginBottom: "0px" }}
              className="col"
              span={8}
            >
              Type{" "}
              <Restricted to="update" resource="location" fallback={<></>}>
                <span
                  onClick={onApplyToAllType}
                  style={{
                    color: "#fa8c16",
                    marginLeft: "10px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Apply to all
                </span>
              </Restricted>
            </Col>
            <Col
              style={{ margin: 0, padding: 0, marginBottom: "0px" }}
              className="col"
              span={8}
            >
              Discount{" "}
              <Restricted to="update" resource="location" fallback={<></>}>
                <span
                  onClick={onApplyToAllDiscount}
                  style={{
                    color: "#fa8c16",
                    marginLeft: "10px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Apply to all
                </span>
              </Restricted>
            </Col>
          </Row>
        </li>
        <li key={"main"}>
          <Row align="top">
            {discountServices.map((item, index) => {
              form.setFieldsValue({
                [`type-${index}`]: item[1].type ? item[1].type : "amount",
                [`discount-${index}`]: item[1].value,
              });
              return (
                <Fragment key={index}>
                  <Col
                    className="col"
                    span={8}
                    style={{
                      alignItems: "initial !important",
                      margin: 0,
                      padding: "12px 0",
                    }}
                  >
                    {item[0].charAt(0).toUpperCase() + item[0].slice(1)}
                  </Col>
                  <Col
                    className="col"
                    span={8}
                    style={{
                      alignItems: "initial !important",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Form.Item
                      className={styles["form-item-container"]}
                      colon={false}
                      name={`type-${index}`}
                      rules={[
                        {
                          required: true,
                          message: "Type is required",
                        },
                      ]}
                    >
                      <Radio.Group
                        className={styles["taps-container"]}
                        onChange={({ target }) => {
                          const { value } = target;
                          let newDiscountServices =
                            structuredClone(discountServices);
                          newDiscountServices[index][1].type = value;
                          setDiscount({ services: newDiscountServices });
                          form.validateFields([`discount-${index}`]);
                        }}
                        value={item[1].type ? item[1].type : "amount"}
                      >
                        {discountTypes.map((type, index) => (
                          <Radio.Button
                            key={type.label + item[0]}
                            value={type.value}
                            className={styles["session-tap"]}
                          >
                            {type.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{ padding: 0, margin: 0 }}
                    className="col"
                    span={8}
                  >
                    <Form.Item
                      colon={false}
                      className={styles["form-item-container"]}
                      name={`discount-${index}`}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Discount can't be less than zero"
                              );
                            }
                            if (value > 100 && item[1].type === "percentage") {
                              return Promise.reject(
                                "Discount can't be more than 100%"
                              );
                            }
                            if (value && !/^\d+(\.\d{0,2})?$/.test(value)) {
                              return Promise.reject(
                                "Please enter up to two digits"
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        min={0}
                        step={"any"}
                        type="number"
                        style={{
                          height: "2.0625rem",
                          width: "4.3rem",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          let val;
                          if (
                            /^0/.test(e.target.value) &&
                            e.target.value !== "0" &&
                            e.target.value !== "0."
                          ) {
                            val = parseFloat(e.target.value);
                          } else if (e.target.value === "0.") {
                            val = 0;
                          } else {
                            val = e.target.value;
                          }
                          if (val >= 0) {
                            let newDiscountServices =
                              structuredClone(discountServices);
                            newDiscountServices[index][1].value = val;
                            setDiscount({ services: newDiscountServices });
                          } else {
                            item[1].value = 0;
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              );
            })}
          </Row>
        </li>
      </ul>
    </nav>
  );
}

export default DiscountTable;
