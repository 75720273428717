import React from "react";
import { FlexRow } from "../atoms";

import SwitchButton from "../atoms/switchButton/SwitchButton";

export const SwitchToggle = ({
  isToggled,
  copy = "",
  onChange,
  value,
  size,
  buttonStyle,
  defaultChecked,
}) => {
  return (
    <FlexRow>
      {copy}
      <SwitchButton
        checked={isToggled}
        onChange={onChange}
        value={value}
        extraButtonStyle={buttonStyle}
        size={size}
        defaultChecked={defaultChecked}
      />
    </FlexRow>
  );
};
