import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const templateEndpoints = {
  templatesMain: "/templates",
  getAllTemplates: "/readall/template",
  getTemplate: "/read/template",
  updateTemplate: "/update/template",
  templateUsers: "/templates/filter_names"
};

const template = {
  getAllTemplates({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${templateEndpoints.templatesMain}`, { params });
  },
  getTemplateById({ queryKey: [, { id, location_id, businessId }] }) {
    return nodePortalAxiosInstance.get(`${templateEndpoints.templatesMain}/${id}`, {
      params: { location_id, businessId },
    });
  },
  updateTemplate({ id, details }) {
    return nodePortalAxiosInstance.put(`${templateEndpoints.templatesMain}/${id}`, details);
  },
  getTemplateUsers({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${templateEndpoints.templateUsers}`, { params });
  },
  createTemplate({ details }) {
    return nodePortalAxiosInstance.post(`${templateEndpoints.templatesMain}`, details);
  },
  renameTemplate({ id, details }) {
    return nodePortalAxiosInstance.post(`${templateEndpoints.templatesMain}/${id}/rename`, details);
  },
  deleteTemplate(id, location_id, businessId) {
    return nodePortalAxiosInstance.delete(`${templateEndpoints.templatesMain}/${id}`, {
      data: { location_id, businessId },
    });
  }
};

export default template;
