import { useQuery } from "react-query";
import rolesApi from "../api/roles";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetRoleTypes = () => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, status } = useQuery(
    "roleTypes",
    rolesApi.getRoles,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const roles = get(data, "data.data");
  return { roles, isError, isLoading, status };
};

export default useGetRoleTypes;
