import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import ConfigProvider from "antd/es/config-provider";
import { ThemeProvider } from "styled-components";
import { theme } from "./config/theme/themeVariables";
import { QueryClientProvider } from "react-query";
import appQueryClient from "./utilities/appQueryClient";
import BusinessIdContext from "./utilities/hooks/useBusinessId";
import LocationIdContext from "./utilities/hooks/useLocationId";
import ViewContext from "./utilities/hooks/useView";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import moment from "moment-timezone";
import PermissionProvider from "./PermissionsProvider/PermissionsProvider";

moment.updateLocale("en-gb", {
  week: {
    dow: 1, /// Date offset
  },
});

ReactDOM.render(
  <ConfigProvider direction="ltr" locale={locale}>
    <ThemeProvider theme={{ ...theme }}>
      <QueryClientProvider client={appQueryClient}>
        <ViewContext>
          <BusinessIdContext>
            <LocationIdContext>
              <PermissionProvider>
                <App />
              </PermissionProvider>
            </LocationIdContext>
          </BusinessIdContext>
        </ViewContext>
      </QueryClientProvider>
    </ThemeProvider>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
