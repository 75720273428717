import axios from "axios";
import inMemoryJwt from "../inMemoryJwt";
import popup from "../popup";
import openNotification from "../openNotification";

const abortController = new AbortController();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api`,
  withCredentials: true,
  signal: abortController.signal,
});

const nodePortalAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_RAILS_PRODUCTION}/api/partner`,
  withCredentials: true,
  signal: abortController.signal
});

nodePortalAxiosInstance.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";
  config.headers["Access-Control-Allow-Origin"] = "true";
  config.headers["Access-Control-Allow-Credentials"] = "true";

  let token = inMemoryJwt.getToken();
  if (token) {
    config.headers["x-access-token"] = token;
  }
  return config;
});

nodePortalAxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.config?.url === "/auth/signin") return Promise.reject(error);
      if (error.response) {
        const { status } = error.response;
        if (status === 422 && error?.config?.url === "/auth/refresh") {
          handleSessionExpiration();
        } else if (status === 401 || status === 403) {
          if (error.response.data.permission) {
            openNotification("Error", error.response.data.message, "error");
            return Promise.reject(error);
          }
          handleSessionExpiration();
        } else if (status === 500) {
          handleServerError();
        } else if (status === 503) {
          handleMaintenanceError();
        } else {
          handleOtherErrors();
        }
      }
      return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";
  config.headers["Access-Control-Allow-Origin"] = "true";
  config.headers["Access-Control-Allow-Credentials"] = "true";

  let token = inMemoryJwt.getToken();
  if (token) {
    config.headers["x-access-token"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.config?.url === "/auth/signin") return Promise.reject(error);
    if (error.response) {
      const { status } = error.response;
      if (status === 422 && error?.config?.url === "/auth/refresh") {
        handleSessionExpiration();
      } else if (status === 401 || status === 403) {
        if (error.response.data.permission) {
          openNotification("Error", error.response.data.message, "error");
          return Promise.reject(error);
        }
        handleSessionExpiration();
      } else if (status === 500) {
        handleServerError();
      } else if (status === 503) {
        handleMaintenanceError();
      } else {
        handleOtherErrors();
      }
    }
    return Promise.reject(error);
  }
);

const handleSessionExpiration = () => {
  inMemoryJwt.eraseToken();
  popup("Session ended, please login again ...", true);
  abortController.abort();
};

const handleServerError = () => {
  popup("Ohh no, something went wrong...", false);
};

const handleMaintenanceError = () => {
  inMemoryJwt.eraseToken();
  window.location.href = "/under-maintenance";
};

const handleOtherErrors = () => {
  // Handle other specific error cases if needed
};

export { axiosInstance, nodePortalAxiosInstance };
