import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Button from "antd/es/button";
import "antd/es/button/style";

import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

function Footer(props) {
  const { handleUpdate, checked, setChecked, loading } = props;

  const navigate = useNavigate();

  const onUpdateClick = () => {
    handleUpdate();
  };

  const onSwitchChange = (checked) => {
    setChecked(checked);
  };

  return (
    <div className={styles["action-wrapper"]}>
      <div className="step-action-inner">
        <Row>
          <Col xs={24}>
            <div className="steps-action">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={onUpdateClick}
                    type="primary"
                    className="submit-button button"
                    loading={loading}
                  >
                    Save updates
                  </Button>
                  <Button
                    type="secondary"
                    className="cancel-button button"
                    onClick={() => {
                      //   locationsCancelEvent();
                      navigate("/businesses");
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
