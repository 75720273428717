import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Table from "antd/es/table";
import "antd/es/table/style";
import Pagination from "antd/es/pagination";

import "antd/es/pagination/style";
import Tag from "antd/es/tag";
import "antd/es/tag/style";
import Tooltip from "antd/es/tooltip";
import "antd/es/tooltip/style";
import Menu from "antd/es/menu";
import Avatar from "antd/es/avatar";
import "antd/es/avatar/style";
import FeatherIcon from "feather-icons-react";

import { Cards } from "../../../atoms/cards/Cards";
import { ProjectLastModified, ProjectList, ProjectPagination } from "./style";
import { DropdownBase } from "../../../atoms";
import styles from "./staffing.module.scss";
import moment from "moment-timezone";
import { includes } from "lodash";

function List({
  templates,
  location,
  totalItems,
  getColumnTitle,
  page,
  size,
  onPageChange,
  onPageSizeChange,
  setIsDeleteTemplateModalOpen,
  setTemplateId,
  providers: providersInLocation,
  setShiftTemplateData,
}) {
  const dataSource = [];
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");

  if (templates.length)
    templates.map((value, index) => {
      const {
        id,
        name,
        updatedAt,
        createdAt,
        modified_by,
        shiftType,
        created_by,
        data: { starttime, endtime, providers, number_providers },
      } = value;

      let existingProviders = providersInLocation.filter((pro) =>
        providers.includes(pro.provider_id)
      ).length;

      return dataSource.push({
        key: id,
        name: `${name.charAt(0).toUpperCase() + name.slice(1)}`,
        type: <span>{shiftType}</span>,
        providers: `${existingProviders} / ${number_providers} 
        ${existingProviders < 2 ? "Provider" : "Providers"} selected`,
        lastModified: (
          <ProjectLastModified>
            <Avatar
              src={
                modified_by.profileImageURL
                  ? modified_by.profileImageURL
                  : "../../../../static/img/users/7.png"
              }
            />
            <p>
              {moment(updatedAt).format("YY/MM/DD") ===
              moment(new Date()).format("YY/MM/DD")
                ? `Today,
              ${moment(updatedAt).format("h:mm A")}`
                : moment(updatedAt).isSame(new Date(), "week")
                ? moment(updatedAt).format("dddd,  h:mm A")
                : moment(updatedAt).format("YY/MM/DD, h:mm A")}
            </p>
          </ProjectLastModified>
        ),
        createdOn: (
          <ProjectLastModified>
            <Avatar
              src={
                created_by.profileImageURL
                  ? created_by.profileImageURL
                  : "../../../../static/img/users/7.png"
              }
            />
            <p>
              {moment(createdAt).format("YY/MM/DD") ===
              moment(new Date()).format("YY/MM/DD")
                ? `Today,
              ${moment(createdAt).format("h:mm A")}`
                : moment(createdAt).isSame(new Date(), "week")
                ? moment(createdAt).format("dddd,  h:mm A")
                : moment(createdAt).format("YY/MM/DD, h:mm A")}
            </p>
          </ProjectLastModified>
        ),
        action: (
          <DropdownBase
            className="actions-dropdown"
            classNameC="actions-dropdown-content"
            action={["click"]}
            content={
              <Menu
                items={[
                  {
                    label: (
                      <Link
                        to={{
                          pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${location.id}`,
                        }}
                        state={{
                          templateId: id,
                        }}
                      >
                        <FeatherIcon icon="arrow-up-right" size={18} /> Use
                        Template
                      </Link>
                    ),
                    key: "1",
                  },
                  {
                    label: (
                      <Link
                        to={"#"}
                        onClick={() => {
                          setTemplateId(id);
                          setShiftTemplateData({
                            isModalOpen: true,
                            isRequired: true,
                          });
                        }}
                      >
                        <FeatherIcon icon="edit-3" size={18} /> Rename
                      </Link>
                    ),
                    key: "2",
                  },
                  {
                    label: (
                      <Link
                        to={{
                          pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${location.id}`,
                        }}
                        state={{
                          templateId: id,
                          mode: "edit",
                        }}
                      >
                        <FeatherIcon icon="edit-2" size={18} /> Edit
                      </Link>
                    ),
                    key: "3",
                  },
                  {
                    label: (
                      <Link
                        to="#"
                        onClick={() => {
                          setTemplateId(id);
                          setIsDeleteTemplateModalOpen(true);
                        }}
                      >
                        <FeatherIcon icon="trash-2" size={18} /> Delete
                      </Link>
                    ),
                    key: "4",
                  },
                ]}
              />
            }
          >
            <Link to="#">
              <FeatherIcon icon="more-vertical" size={18} />
            </Link>
          </DropdownBase>
        ),
      });
    });

  const columns = [
    {
      title: getColumnTitle("Name", "name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: getColumnTitle("Type", "type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Providers",
      dataIndex: "providers",
      key: "providers",
    },
    {
      title: getColumnTitle("Last Modified", "last_modified"),
      dataIndex: "lastModified",
      key: "lastModified",
    },
    {
      title: getColumnTitle("Created on", "created_on"),
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Row gutter={25}>
      <Col xs={24}>
        <div className={styles["table-container"]}>
          <Cards headless>
            <ProjectList>
              <div className="table-responsive">
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  dataSource={dataSource}
                  columns={columns}
                  size="middle"
                />
              </div>
            </ProjectList>
          </Cards>
        </div>
      </Col>
      <Col xs={24} className="pb-30">
        <ProjectPagination>
          <Pagination
            style={{ textAlign: "end" }}
            current={page + 1}
            pageSize={size}
            showSizeChanger={true}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={totalItems}
            hideOnSinglePage={totalItems < 10 ? true : false}
          />
        </ProjectPagination>
      </Col>
    </Row>
  );
}

export default List;
