import Button from "antd/es/button";
import "antd/es/button/style";

import styles from "./styles.module.scss";

export const MainActionButton = ({
  copy = "",
  type = "primary",
  htmlType = "submit",
  additionalClassName = "",
  ...props
}) => (
  <Button
    className={`${styles["generic-cta-style"]} ${additionalClassName}`}
    htmlType={htmlType}
    {...props}
  >
    {copy}
  </Button>
);
