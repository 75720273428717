import React from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Empty from "antd/es/empty";
import "antd/es/empty/style";
import Pagination from "antd/es/pagination";
import "antd/es/pagination/style";

import { ProjectPagination } from "./style";
import GridCard from "./GridCard";

function Grid({
  templates,
  location,
  totalItems,
  page,
  size,
  onPageChange,
  onPageSizeChange,
  setIsDeleteTemplateModalOpen,
  setTemplateId,
  providers,
  setShiftTemplateData,
}) {
  return (
    <Row gutter={25}>
      {templates.length > 0 ? (
        <>
          {templates.map((value) => {
            return (
              <Col key={value.id} xl={8} md={12} xs={24}>
                <GridCard
                  value={value}
                  location={location}
                  setIsDeleteTemplateModalOpen={setIsDeleteTemplateModalOpen}
                  setTemplateId={setTemplateId}
                  providersInLocation={providers}
                  setShiftTemplateData={setShiftTemplateData}
                />
              </Col>
            );
          })}
          <Col xs={24} className="pb-30">
            <ProjectPagination>
              <Pagination
                style={{ textAlign: "end" }}
                current={page + 1}
                pageSize={size}
                showSizeChanger={true}
                onChange={onPageChange}
                onShowSizeChange={onPageSizeChange}
                total={totalItems}
                hideOnSinglePage={totalItems < 10 ? true : false}
              />
            </ProjectPagination>
          </Col>
        </>
      ) : (
        <Empty
          style={{ margin: "50px auto" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </Row>
  );
}

export default Grid;
