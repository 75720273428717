import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { Select } from "antd";

import { Button } from "../../../atoms/buttons/buttons";
import { useQueryClient } from "react-query";

function Year({ setYearState, yearState, onYearChange }) {
  const queryClient = useQueryClient();

  const { currentYear, maxYear, minYear, defaultValue } = yearState;

  useEffect(() => {
    onYearChange(new Date().getFullYear());
  }, []);

  const option = [];
  for (let i = minYear; i <= maxYear; i += 1) {
    option.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>
    );
  }

  const onIncrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    return (
      currentYear < maxYear &&
      (onYearChange(currentYear + 1),
      setYearState({
        ...yearState,
        currentYear: currentYear + 1,
      }))
    );
  };

  const onDecrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    return (
      currentYear > minYear &&
      (onYearChange(currentYear - 1),
      setYearState({
        ...yearState,
        currentYear: currentYear - 1,
      }))
    );
  };

  return (
    <div className="calender-head__navigation">
      <Button
        id="calendar_left-arrow-button"
        onClick={onDecrement}
        className="btn-navigate"
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-left" />
      </Button>
      <Select
        id="calendar_date"
        className="year-select"
        onChange={(value) => {
          setYearState({ ...yearState, currentYear: value });
          onYearChange(value);
        }}
        defaultValue={currentYear}
        value={currentYear}
        style={{ width: "100px" }}
      >
        {option}
      </Select>
      <Button
        id="calendar_right-arrow-button"
        className="btn-navigate"
        onClick={onIncrement}
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-right" />
      </Button>
    </div>
  );
}

export default Year;
