import React, { useState } from "react";
import { Link } from "react-router-dom";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Table from "antd/es/table";
import "antd/es/table/style";
import Pagination from "antd/es/pagination";
import "antd/es/pagination/style";
import Menu from "antd/es/menu";
import Tooltip from "antd/es/tooltip";
import "antd/es/tooltip/style";
import FeatherIcon from "feather-icons-react";

import { Cards } from "../../../../atoms/cards/Cards";
import { DropdownBase } from "../../../../atoms";
import styles from "./PropertiesTable.module.scss";
import { capitalize } from "lodash";
import openNotification from "../../../../../utilities/openNotification";
import {
  deleteLocation,
  disableLocation,
  enableLocation,
} from "../../../../../services/locations";
import { useQueryClient } from "react-query";
import DeleteModal from "../../../../molecules/locations/DeleteModal";
import EllipsisLabel from "../../../../atoms/ellipsisLabel/EllipsisLabel";
import Restricted from "../../../../../PermissionsProvider/Restricted";

function PropertiesTable({
  properties,
  isLoading,
  totalItems,
  page,
  perPage,
  onPageChange,
  onPageSizeChange,
  business,
  refetch,
}) {
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [targetLocation, setTargetLocation] = useState();

  const handleDisable = (locationId) => {
    disableLocation(locationId)
      .then(() => {
        openNotification(
          "Disable Location",
          "Location successfully disabled",
          "success"
        );
        refetch();
        queryClient.removeQueries("paginated");
        queryClient.removeQueries("locations");
      })
      .catch((err) => {
        openNotification(
          "Disable Location",
          err.response.data.message,
          "error"
        );
      });
  };
  const handleEnable = (id) => {
    enableLocation(id)
      .then(() => {
        openNotification(
          "Enable Location",
          "Location successfully enabled",
          "success"
        );
        refetch();
        queryClient.removeQueries("paginated");
        queryClient.removeQueries("locations");
      })
      .catch((err) => {
        openNotification("Enable Location", err.response.data.message, "error");
      });
  };

  const onDeleteClick = (location) => {
    setIsDeleteModalOpen(true);
    setTargetLocation(location);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTargetLocation(null);
  };

  const handleDeleteLocation = () => {
    deleteLocation(targetLocation?.id)
      .then(() => {
        openNotification(
          "Delete Location",
          `Location "${targetLocation?.name}" successfully delete`,
          "success"
        );
        refetch();
        queryClient.removeQueries("paginated");
        queryClient.removeQueries("locations");
      })
      .catch((err) => {
        openNotification("Delete Location", err.response.data.message, "error");
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const generateDataSource = () =>
    properties?.map((value, index) => {
      const { id, name, dealType, disabled, providerCount, status } = value;

      return {
        key: index,
        name:
          name.length > 29 ? (
            <Tooltip title={name} placement="topLeft" color={"#fff"} key={id}>
              <EllipsisLabel>{name}</EllipsisLabel>
            </Tooltip>
          ) : (
            name
          ),
        dealType: dealType,
        providers: `${providerCount} Providers`,
        status: (
          <span
            className={`status ${
              status === "live"
                ? "Success"
                : status === "pending"
                ? "warning"
                : status === "disabled"
                ? "disabled"
                : status === "draft"
                ? "in-progress"
                : "error"
            }`}
          >
            {disabled ? "Disabled" : capitalize(status)}
          </span>
        ),
        action: (
          <DropdownBase
            className="actions-dropdown"
            classNameC="actions-dropdown-content"
            action={["click"]}
            content={
              <Menu
                items={[
                  {
                    label: (
                      <Restricted
                        to="update"
                        resource="location"
                        fallback={<></>}
                      >
                        <Link
                          to={
                            status === "draft"
                              ? `/businesses/${business.id}/properties`
                              : `/businesses/${business.id}/properties/${id}`
                          }
                          state={{
                            mode: "business",
                            locationId: id,
                            isDraft: status === "draft",
                          }}
                        >
                          <FeatherIcon icon="edit-2" size={18} /> Edit
                        </Link>
                      </Restricted>
                    ),
                    key: "1",
                  },
                  {
                    label:
                      status === "disabled" || status === "pending" ? (
                        <Restricted
                          to="enable"
                          resource="location"
                          fallback={<></>}
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleEnable(id);
                            }}
                          >
                            <FeatherIcon icon="check-square" size={18} /> Enable
                          </Link>
                        </Restricted>
                      ) : (
                        <Restricted
                          to="disable"
                          resource="location"
                          fallback={<></>}
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleDisable(id);
                            }}
                          >
                            <FeatherIcon icon="x-square" size={18} /> Disable
                          </Link>
                        </Restricted>
                      ),
                    key: "2",
                  },
                  {
                    label: (
                      <Restricted
                        to="delete"
                        resource="location"
                        fallback={<></>}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            onDeleteClick(value);
                          }}
                        >
                          <FeatherIcon icon="trash-2" size={18} />
                          Delete
                        </Link>
                      </Restricted>
                    ),
                    key: "3",
                  },
                ]}
              />
            }
          >
            <Link to="#">
              <FeatherIcon icon="more-vertical" size={18} />
            </Link>
          </DropdownBase>
        ),
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deal Type",
      dataIndex: "dealType",
      key: "dealType",
    },
    {
      title: "Providers",
      dataIndex: "providers",
      key: "providers",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <Row gutter={25} className="mt-20">
        <Col xs={24}>
          <div className={styles["table-container"]}>
            <Cards headless border={false}>
              <div className="table-responsive">
                <Table
                  loading={isLoading}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  dataSource={generateDataSource()}
                  columns={columns}
                  size="middle"
                />
              </div>
            </Cards>
          </div>
        </Col>
        <Col xs={24} className="pb-30">
          <Pagination
            style={{ textAlign: "end" }}
            current={page + 1}
            pageSize={perPage}
            showSizeChanger={true}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={totalItems}
            hideOnSinglePage={totalItems < 10 ? true : false}
          />
        </Col>
      </Row>
      <DeleteModal
        isVisible={isDeleteModalOpen}
        locationName={targetLocation?.name}
        onCancel={closeDeleteModal}
        onSubmit={handleDeleteLocation}
      />
    </>
  );
}

export default PropertiesTable;
