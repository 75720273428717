import Checkbox from "antd/es/checkbox";
import "antd/es/checkbox/style";
import Input from "antd/es/input";
import "antd/es/input/style";

import styles from "./styles.module.scss";
import { InputNumber } from "antd";

export const StyledInput = ({
  value,
  onChange,
  defaultValue,
  placeholder = "",
  type = "text",
  disabled = false,
  rows = 4,
  maxLength,
  ...props
}) => {
  if (type === "password") {
    return (
      <Input.Password
        className={styles["generic-input-style"]}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete="new-password"
        {...props}
      />
    );
  } else if (type === "email") {
    return (
      <Input
        className={styles["generic-input-style"]}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete="off"
        {...props}
      />
    );
  } else if (type === "textarea") {
    return (
      <Input.TextArea
        className={styles["generic-input-style"]}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
        {...props}
      />
    );
  } else if (type === "number") {
    return (
      <InputNumber
        className={`${styles["generic-input-style"]} ${props?.extraclassname || ""}`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        autoComplete="off"
        {...props}
      />
    );
  } else {
    return (
      <Input
        className={styles["generic-input-style"]}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        autoComplete="off"
        maxLength={maxLength}
        type={type}
        {...props}
      />
    );
  }
};

export const StyledCheckbox = (props) => {
  return (
    <Checkbox className={styles["generic-checkbox-style"]} {...props}>
      {props.children}
    </Checkbox>
  );
};
