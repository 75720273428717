/* eslint-disable import/no-anonymous-default-export */
import React, { lazy, useState } from "react";
import { PageHeader } from "../../components/molecules/page-header/PageHeader";
import Breadcrumb from "antd/es/breadcrumb";
import "antd/es/breadcrumb/style";
import { Link } from "react-router-dom";
import Card from "antd/es/card";
import "antd/es/card/style";
import styles from "./notifications.module.scss";
import useGetNotifications from "../../utilities/hooks/useGetNotifications";
import { useBusinessIdGlobal } from "../../utilities/hooks/useBusinessId";
import useGetLocations from "../../utilities/hooks/useGetLocations";
import useUserProfileData from "../../utilities/hooks/useUserProfileData";
import { useLocationIdGlobal } from "../../utilities/hooks/useLocationId";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import { LocationsDropdown } from "../../components/atoms";
import useGetNotificationsTableStruct from "../../utilities/hooks/useGetNotificationsTableStruct";

const NotificationsTable = lazy(() =>
  import("../../components/organisms/notifications/table")
);

const Notifications = ({ locations, businessId }) => {
  // const defaultLocationId = locations[0].id;
  const [page, setPage] = useState(0);
  const [perPage, setSize] = useState(10);
  const { selectedLocation, setSelectedLocation } = useLocationIdGlobal();
  const {
    notifications,
    isError: isNotificationsError,
    isLoading: isNotificationsLoading,
  } = useGetNotifications({
    location: selectedLocation.id,
    businessId,
    page,
    perPage,
  });
  const {
    tableStructure,
    isError: isTableStructureError,
    isLoading: isTableStructureLoading,
  } = useGetNotificationsTableStruct(selectedLocation.id);
  // useEffect(() => {
  //   if (!selectedLocation.id) setSelectedLocation(defaultLocationId);
  // }, []);

  return (
    <>
      <PageHeader
        className={styles["header-boxed"]}
        title={
          <>
            <div style={{ marginBottom: "15px" }}>Notifications Settings</div>
            <LocationsDropdown
              locations={locations}
              value={selectedLocation.id || "all"}
              businessId={businessId}
              onChange={(value, option) => {
                setSelectedLocation({
                  name: option.title,
                  id: option.value === "all" ? null : option.value,
                });
              }}
              type="main-dropdown"
            />
          </>
        }
        routes={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {/* {location?.name.charAt(0).toUpperCase() + location?.name.slice(1)} */}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Notification</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className={`main ${styles["main-container"]}`}>
        <Card className={styles["card"]}>
          <NotificationsTable
            setPage={setPage}
            setPerPage={setSize}
            page={page}
            perPage={perPage}
            tableStructure={tableStructure}
            selectedLocation={selectedLocation.id}
            notificationsInfo={notifications}
            businessId={businessId}
            isLoading={isNotificationsLoading || isTableStructureLoading}
          />
        </Card>
      </div>
    </>
  );
};

export default () => {
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();
  const { isLoading: isUserLodaing, businessId } =
    useUserProfileData(CurrentbusinessId);
  const { locations, isLoading: isLocationsLoading } =
    useGetLocations(businessId);
  if (!businessId || isLocationsLoading) {
    return (
      <div className="spin" style={{ height: "83vh" }}>
        <Spin />
      </div>
    );
  }

  return <Notifications locations={locations} businessId={businessId} />;
};
