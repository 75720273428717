import { Button } from "../../atoms/buttons/buttons";
import { Modal } from "../../atoms/modals/antd-modals";

function WarningModal({
    isOpen,
    onExit,
    onSubmit,
    isCreate
}) {
    return (
        <Modal
            className="no-lines-modal"
            closable={true}
            title={<div className="color-dark">{isCreate ? "" : "Shift Change "}Warning</div>}
            visible={isOpen}
            onCancel={onExit}
            footer={[
                {
                    size: "large",
                    className: "my-10",
                    type: "default",
                    outlined: true,
                    style: {
                        fontSize: "14px",
                        lineHeight: "16px",
                        backgroundColor: "#F1F6FF",
                    },
                    text: "Cancel",
                    onClick: () => onExit(),
                },
                {
                    size: "large",
                    type: "primary",
                    text: "Continue",
                    style: {
                        fontSize: "14px",
                        lineHeight: "16px"
                    },
                    onClick: () => onSubmit(),
                },
            ].map((button, index) => (
                <Button
                    className={button.className}
                    key={`past-shift-warning-modal-btn-${index}`}
                    size={button.size}
                    type={button.type}
                    outlined={button.outlined}
                    onClick={button.onClick}
                    style={button.style}
                >
                    {button.text}
                </Button>
            ))}
        >
            <div className="color-dark">
                {isCreate ?
                    "You're creating a shift for a completed appointment. We won't send out any offers and the Providers selected will be paid out immediately."
                    : "After 9pm on the day following shift completion, confirming a shift change will lead to withholding payment from the Provider, as they have already been compensated for that shift."}
            </div>
        </Modal>
    )
}
export default WarningModal;