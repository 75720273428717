import { Result, Spin } from "antd";
import React from "react";
import { Outlet } from "react-router";
import get from "lodash/get";

import useUserProfileData from "../utilities/hooks/useUserProfileData";
import inMemoryJwt from "../utilities/inMemoryJwt";
import { MainActionButton } from "../components/atoms";
import useTryAutoLogin from "../utilities/hooks/useTryAutoLogin";
import { useBusinessIdGlobal } from "../utilities/hooks/useBusinessId";

function NoBusinessFound() {
  const { isLogin } = useTryAutoLogin();
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();
  const { isLoading, userData, businessId } =
    useUserProfileData(CurrentbusinessId);

  let businesses = get(userData, "userData.businesses", []);

  if (isLogin && !isLoading && !businessId && !businesses.length) {
    inMemoryJwt.eraseToken();
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Result
          style={{ width: "100%" }}
          status="404"
          title="404"
          subTitle={
            <div>
              You are not currently enrolled in any businesses, contact your
              system administrator?
            </div>
          }
          extra={
            <MainActionButton
              copy="Back to login"
              htmlType="button"
              onClick={() => {
                window.location.href = "/login";
              }}
            />
          }
        />
      </div>
    );
  } else {
    return <Outlet />;
  }
}

export default NoBusinessFound;
