import notification from "antd/es/notification";
import "antd/es/notification/style";

const openNotification = (
  message,
  description,
  type = "info",
  duration = 3
) => {
  const CustomMessage = () => <div id="notification-message">{message}</div>;

  const CustomDescription = () => (
    <div id="notification-description">{description}</div>
  );

  notification[type]({
    message: <CustomMessage />,
    description: <CustomDescription />,
    duration,
  });
};

export default openNotification;
