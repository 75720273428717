import React from 'react'
import { Link } from 'react-router-dom'

function LinkTo({ onClick, to, text, className, ...props }) {
  return (
    <Link onClick={onClick} to={to} className={className} {...props}>
      {text}
    </Link>
  )
}

export default LinkTo
