// eslint-disable-next-line max-classes-per-file
import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, Calendar } from "react-date-range";
import { DatePicker } from "antd";
import { ItemWraper, ButtonGroup } from "./style";
import { Button } from "../buttons/buttons";
import styles from "./datePicker.module.scss";

const DateRangePickerOne = ({
  state,
  handleRangeChange,
  onClick,
  onCancel,
  datePicker,
  className,
}) => {
  const { dateRangePicker } = state;
  const start = dateRangePicker.selection.startDate.toString().split(" ");
  const end = dateRangePicker.selection.endDate.toString().split(" ");

  return (
    <div className={styles["wrapper"]}>
      {datePicker ? (
        <Calendar
          date={dateRangePicker.selection.startDate}
          onChange={handleRangeChange}
          className="calendar-wrapper"
          // months={1}
          direction="horizontal"
        />
      ) : (
        <DateRangePicker
          onChange={handleRangeChange}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          className={`PreviewArea ${className}`}
          months={2}
          ranges={[dateRangePicker.selection]}
          direction="horizontal"
        />
      )}
      <div className={styles["buttonGroup"]}>
        {datePicker ? (
          <p>{`${start[1]} ${start[2]} ${start[3]}`}</p>
        ) : (
          <p>{`${start[1]} ${start[2]} ${start[3]} - ${end[1]} ${end[2]} ${end[3]}`}</p>
        )}
        <Button size="small" type="sootheButton" onClick={onClick}>
          Apply
        </Button>
        <Button size="small" onClick={onCancel} type="light">
          Clear
        </Button>
      </div>
    </div>
  );
};

export { DateRangePickerOne };
