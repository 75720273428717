import React, { createContext, useContext, useState } from "react";

const context = createContext();

export default function BusinessIdContext({ children }) {
  const [businessId, setBusinessId] = useState();
  
  const value = {
    businessId,
    setBusinessId,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
}

export const useBusinessIdGlobal = () => useContext(context);
