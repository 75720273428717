/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import Form from "antd/es/form";
import "antd/es/form/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import get from "lodash/get";
import map from "lodash/map";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { LocationsDropdown, StyledInput } from "../../atoms";
import FileUpload from "../../molecules/locations/FileUpload";
import styles from "./UploadFile.module.scss";
import useGetLocations from "../../../utilities/hooks/useGetLocations";
import DataStateHandler from "../../../utilities/DataStateHandler";
import businessApis from "../../../utilities/api/businesses";
import openNotification from "../../../utilities/openNotification";
import useGetFile from "../../../utilities/hooks/useGetFile";
import { useQueryClient } from "react-query";
import FileVersions from "./FileVersions";

function UploadFileModal({
  isOpen,
  onExit,
  title,
  businessId,
  mode = "add",
  fileId,
}) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileExtension, setFileExtension] = useState(null);
  const [isViewFileVersionsOpen, setIsViewFileVersionsOpen] = useState(false);

  const {
    locations,
    isLoading: isLocationsLoading,
    isError,
    error,
  } = useGetLocations(businessId);

  const {
    file,
    isLoading: isFileLoading,
    isError: isFileError,
    error: fileError,
  } = useGetFile({ businessId, fileId });

  useEffect(() => {
    if (file) {
      setFileExtension(file?.extension);
      form.setFieldsValue({
        properties:
          file.properties?.length === locations?.length
            ? ["all"]
            : file.properties.map((property) => property.id),
        "file-name": file.name,
        "file-url": file.fileurl,
      });
    }
  }, [file, form, locations]);

  const handleApiCall = async (apiFunction, data) => {
    setIsLoading(true);
    try {
      const response = await apiFunction(data);
      setIsLoading(false);
      openNotification("Success", response.data.message, "success");
      queryClient.invalidateQueries("files");
      mode === "edit" && queryClient.removeQueries(["fileVersions"]);
      onExit();
      form.resetFields();
    } catch (error) {
      setIsLoading(false);
      openNotification("Error", error.response.data.message, "error");
    }
  };

  const uploadFile = async (data) => {
    await handleApiCall(businessApis.uploadFile, data);
  };

  const updateFile = async (data) => {
    await handleApiCall(businessApis.updateFile, data);
  };

  const allLocations = locations && map(locations, (loc) => loc.id);

  const onFinish = async () => {
    form.validateFields().then((values) => {
      const data = {
        locations: get(values, "properties", []).includes("all")
          ? allLocations
          : values.properties,
        name: values["file-name"],
        url: values["file-url"],
        extension: fileExtension,
        ...(mode === "edit" ? { file_id: file?.id } : {}),
        businessId,
      };

      mode === "edit" ? updateFile(data) : uploadFile(data);
    });
  };

  return (
    <Modal
      title={
        <Row className={styles["title"]}>
          {mode === "edit" ? (
            <>
              <Col
                xxl={18}
                lg={isViewFileVersionsOpen ? 15 : 18}
                xs={18}
                className={styles["anitmation"]}
              >
                {title}
              </Col>
              <Col
                xxl={6}
                lg={isViewFileVersionsOpen ? 9 : 6}
                xs={6}
                className={styles["anitmation"]}
              >
                <Button
                  size="small"
                  type="light"
                  outlined={true}
                  onClick={() =>
                    setIsViewFileVersionsOpen(!isViewFileVersionsOpen)
                  }
                >
                  {isViewFileVersionsOpen ? "Close" : "View"} version history
                </Button>
              </Col>
            </>
          ) : (
            <Col xxl={24} lg={24} xs={24}>
              {title}
            </Col>
          )}
        </Row>
      }
      visible={isOpen}
      onCancel={onExit}
      width={850}
      centered
      wrapClassName={styles["modal-wrapper"]}
      footer={[
        <Button
          className="my-10"
          key={1}
          size="small"
          type="danger"
          outlined={true}
          onClick={onExit}
        >
          Close without saving
        </Button>,
        <Button
          key={2}
          size="small"
          htmlType="submit"
          loading={isLoading}
          onClick={() => form.submit()}
          type={"primary"}
        >
          Save file
        </Button>,
      ]}
    >
      <DataStateHandler
        isLoading={isLocationsLoading || isFileLoading}
        loadingComponent={<Skeleton active paragraph={{ rows: 15 }} />}
        isError={isError || isFileError}
        error={error || fileError}
        data={locations || file}
      >
        <Row gutter={50}>
          <Col
            xxl={isViewFileVersionsOpen ? 15 : 24}
            lg={isViewFileVersionsOpen ? 15 : 24}
            xs={isViewFileVersionsOpen ? 15 : 24}
            sm={isViewFileVersionsOpen ? 15 : 24}
            className={styles["anitmation"]}
            style={{
              borderRight: isViewFileVersionsOpen
                ? "1px solid #F1F2F6"
                : "none",
            }}
          >
            <div className={styles["form-container"]}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                }}
                requiredMark={false}
              >
                <Form.Item
                  colon={false}
                  name="properties"
                  label="Properties*"
                  rules={[{ required: true, message: "Property is required" }]}
                >
                  <LocationsDropdown
                    locations={locations}
                    businessId={businessId}
                    type="modal-dropdown"
                    placeholder="Select one or more properties..."
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  name="file-name"
                  label="File name*"
                  rules={[{ required: true, message: "File name is required" }]}
                >
                  <StyledInput placeholder="Enter file name" />
                </Form.Item>
                <Form.Item
                  colon={false}
                  name="file-url"
                  label="Upload file* (.doc, .pdf)"
                  rules={[{ required: true, message: "File is required" }]}
                >
                  <FileUpload
                    id="files_file-url"
                    logo={true}
                    uploadPDFFileOnly={false}
                    bkgroundColor={"#f8f9fb"}
                    onUploadSuccess={(url, fileExtension) => {
                      form.setFieldsValue({ "file-url": url });
                      setFileExtension(fileExtension);
                    }}
                    onRemoveSuccess={() => {
                      form.setFieldsValue({ "file-url": "" });
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col
            xxl={isViewFileVersionsOpen ? 9 : 0}
            lg={isViewFileVersionsOpen ? 9 : 0}
            xs={isViewFileVersionsOpen ? 9 : 0}
            sm={isViewFileVersionsOpen ? 9 : 0}
          >
            {isViewFileVersionsOpen && (
              <FileVersions businessId={businessId} fileId={fileId} />
            )}
          </Col>
        </Row>
      </DataStateHandler>
    </Modal>
  );
}

export default UploadFileModal;
