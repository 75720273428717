import { useQuery } from "react-query";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";
import appointment from "../api/appointment";

const useGetCancellationReasons = (businessId) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, status } = useQuery(
    ["cancellationReasons", businessId],
    () => appointment.getCancellationReasons(businessId),
    {
      enabled: !!isLogin && !!businessId,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );

  const cancellationReasons = get(
    data,
    "data.data.client_cancellation_reasons"
  );
  return { data: cancellationReasons, isError, error, isLoading, status };
};

export default useGetCancellationReasons;
