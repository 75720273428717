import React, { useState, useEffect } from "react";

import Upload from "antd/es/upload";
import "antd/es/upload/style";
import message from "antd/es/message";
import "antd/es/message/style";
import FeatherIcon from "feather-icons-react";
import { LoadingOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";

import UploadInputStyle from "../../atoms/inputs/UploadInputStyle";
import Heading from "../../atoms/heading/heading";
import openNotification from "../../../utilities/openNotification";

import { generateUrl, uploadToS3 } from "../../../services/aws";

const { Dragger } = Upload;

const ImageUpload = ({
  logo = false,
  onUploadSuccess,
  value,
  onRemoveSuccess,
  disabled = false,
  bkgroundColor,
  id,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: "1",
          name: value,
          status: "done",
          url: value,
        },
      ]);
    }
  }, [value]);

  const customRequest = async ({ file, onSuccess }) => {
    setIsLoading(true);
    const response = await generateUrl().then((res) => res.data);
    if (response.data && response.data.url) {
      const url = response.data.url;
      uploadToS3(url, file)
        .then(() => {
          const imageUrl = url.split("?")[0];
          onSuccess(imageUrl, file);
          onUploadSuccess(imageUrl);
          setIsLoading(false);
          setFileList([
            {
              uid: "1",
              name: imageUrl,
              status: "done",
              url: imageUrl,
            },
          ]);
        })
        .catch((error) => {
          setIsLoading(false);
          openNotification("Error", error.message, "error");
        });
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onRemove = () => {
    setFileList([]);
    onRemoveSuccess();
  };

  const fileUploadProps = {
    beforeUpload: async (file) => {
      const isJpgOrPng =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";

      if (!isJpgOrPng) {
        message.error(`You can only upload JPG/PNG file`);
      }

      return isJpgOrPng;
    },
    maxCount: 1,
    customRequest,
    onRemove,
    onPreview,
  };

  return (
    <UploadInputStyle bkgroundColor={bkgroundColor}>
      <ImgCrop
        rotate
        zoom
        shape={logo ? "round" : "rect"}
        aspect={logo ? 1 / 1 : 16 / 8}
        fillColor="white"
        minZoom={0.1}
        cropperProps={{ restrictPosition: false }}
      >
        <Dragger
          id={id}
          {...fileUploadProps}
          fileList={fileList}
          listType="picture"
          disabled={disabled}
          accept="image/jpeg,image/png,image/jpg"
        >
          <p className="ant-upload-drag-icon">
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              <FeatherIcon icon="upload" size={50} />
            )}
          </p>
          <Heading as="h4" className="ant-upload-text">
            Drag and drop an image
          </Heading>
          <p className="ant-upload-hint">
            or <span>Browse</span> to choose a file
          </p>
        </Dragger>
      </ImgCrop>
    </UploadInputStyle>
  );
};

export default ImageUpload;
