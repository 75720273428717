import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const railsLocationEndpoints = {
  locations: "/dashboard_locations",
  locationsPaginated: "/locations",
  propertyDetails: "/property_details",
  saveLocationAsDraft: "/locations/draft",
  customPricingGet: "/custom_pricing",
  updateMarkups: "/update_markups",
  workingHours: "/working_hours",
  services: "/get_location_services",
  //createAppointment: "/appointments",
  createAppointment: "/create_appointment",
  providerPayout: "/get_hourly_payout_amounts",
  updateProviderPayout: "/update_hourly_payouts",
  //tax: "/tax/appointments/read",
  tax: "/tax",
  breaks: "/get_shift_breaks",
  updateBreaks: "/update_shift_breaks",
  training: "/get_training_payouts",
  updateTraining: "update_training_payouts",
  discounts: "/discounts",
  billing: "/billings",
  providerSourcing: "/get_all_questions",
  providerSourcingByLocation: "/get_location_answers",
  volumeDiscounts: "/invoice_discounts",
};

const locationEndpoints = {
  locations: "/locations/get/all",
  locationsPaginated: "/locations",
  saveLocationAsDraft: "/locations/draft",
  customPricing: "/locations/custom/pricing",
  workingHours: "/working/hours",
  services: "/locations/services",
  createAppointment: "/appointments",
  providerPayout: "/read/hourly",
  updateProviderPayout: "/update/hourly",
  tax: "/tax/appointments/read",
  breaks: "/locations/shift/breaks",
  training: "/read/training",
  updateTraining: "/update/training",
  discounts: "/location/discounts",
  billing: "/location/billing",
  providerSourcing: "/questions/all",
  providerSourcingByLocation: "/locations/questions",
};

const location = {
  getTaxPerLocation({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.post(railsLocationEndpoints.tax, params);
  },
  getLocationById(id, businessId) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.propertyDetails}?id=${id}`, {
      params: { businessId },
    });
  },
  // getLocationById(id, businessId) {
  //   return axios.get(`${locationEndpoints.locationsPaginated}/${id}`, {
  //     params: { businessId },
  //   });
  // },
  createAppointment(details) {
    return nodePortalAxiosInstance.post(railsLocationEndpoints.createAppointment, details);
  },
  getAllLocations(businessId, status, include_staffing, include_corporate, providers) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.locations}`, {
      params: { businessId, status, ...include_staffing && { include_staffing }, ...include_corporate && { include_corporate }, providers },
    });
  },
  getAllLocationsPaginated({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(locationEndpoints.locationsPaginated, { params });
  },
  saveLocationAsDraft(location) {
    const body = location;
    return axios.post(`${locationEndpoints.saveLocationAsDraft}`, body);
  },
  getCustomPricing(id) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.customPricingGet}?id=${id}`);
  },
  updateCustomPricing(id, customPricing) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.updateMarkups}?id=${id}`, customPricing);
  },
  getWorkingHoursOfLocation(id) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.workingHours}?id=${id}`);
  },
  getServicesOfLocation(id) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.services}?id=${id}`);
  },
  getProviderPayout({ id, businessId }) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.providerPayout}?id=${id}`, {
      params: {
        businessId,
      },
    });
  },
  getProviderBreaks(id) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.breaks}?id=${id}`);
  },
  updateProviderBreaks(id, data) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.updateBreaks}?id=${id}`, data);
  },
  updateProviderPayout(providerPayout) {
    return nodePortalAxiosInstance.post(
      `${railsLocationEndpoints.updateProviderPayout}`,
      providerPayout
    );
  },
  getProviderTraingings({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.training}`, { params });
  },
  updateProviderTraingings({ locationId, businessId, trainings }) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.updateTraining}`, {
      location_id: locationId,
      business_id: businessId,
      hourly_payouts: trainings?.training || trainings,
    });
  },

  getDisconts({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.discounts}?id=${id}`);
  },
  updateDiscounts(id, discounts) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.discounts}?id=${id}`, discounts);
  },
  getBilling({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.billing}?id=${id}`);
  },
  updateBilling(id, billings) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.billing}?id=${id}`, billings);
  },
  getQuestions() {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.providerSourcing}`);
  },
  getAnswersBylocation({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.providerSourcingByLocation}?id=${id}`);
  },
  getPropertyVolumeDiscounts({ queryKey: [, id] }) {
    const params = id ? `?discountable_type=location&discountable_id=${id}` : '';
    return nodePortalAxiosInstance.get(`${railsLocationEndpoints.volumeDiscounts}${params}`);
  },
  postPropertyVolumeDiscounts(data) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.volumeDiscounts}`,
      data
    );
  },
  patchPropertyVolumeDiscounts(data) {
    return nodePortalAxiosInstance.post(`${railsLocationEndpoints.volumeDiscounts}/update`,
      data
    );
  },
};

export default location;
