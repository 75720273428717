import React, { createContext, useContext, useState } from "react";

const context = createContext();

export default function ViewContext({ children }) {
  let selectedView = localStorage.getItem("view");
  const [view, setView] = useState(selectedView || "property");

  const value = { view, setView };
  return <context.Provider value={value}>{children}</context.Provider>;
}

export const useViewGlobal = () => useContext(context);
