import { axiosInstance as axios, nodePortalAxiosInstance } from "../utilities/api/axiosInstance";
const locationEndpoints = {
  locations: "/dashboard_locations",
  enable_location:"/enable_location",
  toggle_location: "/locations/toggle_status",
  disable_location:"/disable_location",
  propertyDetails: "/property_details",
  update_services: "/update_services",
  updateLocation: "/update_location",
  //propertyDetails: "/locations",
  locationsPaginated: "/locations",
  create_location: "/create_location",
  update_arrival: "/create_arrival_instructions",
  workingHours: "/update_working_hours",
  businessNotifications: "/business-notifications",
  location: "/location",
  updateAnswers: "/update_location_answers",
  updateWhitelisted: "/update_white_listed",
};

export const saveLocationAsDraft = (location) => {
  const body = location;
  return nodePortalAxiosInstance.post(`${locationEndpoints.create_location}`, body);
};

export const getAllLocationsPaginated = (
  page = 0,
  size = 10,
  status = "all",
  search,
  businessId
) => {
  return nodePortalAxiosInstance.get(
    `${locationEndpoints.locationsPaginated
    }?page=${page}&size=${size}&status=${status}&businessId=${businessId}${search ? `&search=${search}` : ""
    }`
  );
};

export const getAllLocations = (businessId) => {
  return nodePortalAxiosInstance.get(
    `${locationEndpoints.locations}?&businessId=${businessId}`
  );
};

export const disableLocation = (id) => {
  return nodePortalAxiosInstance.post(`${locationEndpoints.disable_location}?location_id=${id}`);
};

export const deleteLocation = (id) => {
  return nodePortalAxiosInstance.delete(`${locationEndpoints.locationsPaginated}/${id}`);
};
export const enableLocation = (id) => {
  return nodePortalAxiosInstance.post(`${locationEndpoints.enable_location}?id=${id}`);
};
export const toggleLocation = (id, status) => {
  return nodePortalAxiosInstance.post(
    `${locationEndpoints.toggle_location}?location_id=${id}`,
    status
  );
};
export const updateLocationServices = (id, services) => {
  const body = services;
  return nodePortalAxiosInstance.post(`${locationEndpoints.update_services}?id=${id}`, body);
};

export const saveLocation = (location) => {
  const body = location;
  return nodePortalAxiosInstance.post(`${locationEndpoints.create_location}`, body);
};

export const saveArrivalInstructions = (id, arrivalInstructions) => {
  const body = arrivalInstructions;
  return nodePortalAxiosInstance.post(
    `${locationEndpoints.update_arrival}?id=${id}`,
    body
  );
};
export const saveProviderSourcing = (id, answers) => {
  return nodePortalAxiosInstance.post(`${locationEndpoints.updateAnswers}?id=${id}`, { answers });
};

export const saveWorkingHours = (id, workingHours) => {
  const body = workingHours;
  return nodePortalAxiosInstance.post(`${locationEndpoints.workingHours}?id=${id}`, body);
};

export const saveBusinessNotifications = (id, businessNotifications) => {
  const body = businessNotifications;
  return axios.put(`${locationEndpoints.businessNotifications}/${id}`, body);
};

export const getLocationById = (id, businessId) => {
  return nodePortalAxiosInstance.get(`${locationEndpoints.propertyDetails}?id=${id}`, {
    params: { businessId },
  });
};

// export const getLocationById = (id, businessId) => {
//   return axios.get(`${locationEndpoints.locationsPaginated}/${id}`, {
//     params: { businessId },
//   });
// };

export const updateLocation = (location, id) => {
  const body = location;
  return nodePortalAxiosInstance.post(`${locationEndpoints.updateLocation}?id=${id}`, body);
};

export const updateDraftLocation = (location, id) => {
  const body = location;
  return nodePortalAxiosInstance.post(`${locationEndpoints.updateLocation}?id=${id}`, body);
};

export const updateLocationWhitelisted = (update_white_listed, id) => {
  return nodePortalAxiosInstance.post(`${locationEndpoints.updateWhitelisted}?id=${id}`, update_white_listed);
}
