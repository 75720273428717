import { useQuery } from "react-query";
import userApis from "../api/userAuth";
import inMemoryJWTManager from "../inMemoryJwt";

const useTryAutoLogin = () => {
  const isLogin =
    !!inMemoryJWTManager.getToken() || !!localStorage.getItem("isLoggedIn");

  const { data, isLoading, isError } = useQuery(
    "currentUser",
    userApis.refreshToken,
    {
      enabled: !!isLogin,
    }
  );

  if (data) {
    inMemoryJWTManager.setToken(
      data?.data?.data?.accessToken,
      data?.data?.data?.refreshTokenExpiry
    );
  }

  return {
    data,
    isLoading,
    isError,
    isLogin: !!data,
  };
};

export default useTryAutoLogin;
