import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const useGetBusinessActivities = (params) => {
  const { permissions } = usePermissions();
  const { isLogin } = useTryAutoLogin();

  const { data, isLoading, refetch } = useQuery(
    ["businessActivities", params],
    businessApis.getBusinessActivities,
    {
      enabled:
        !!isLogin &&
        !!params.businessId &&
        !!permissions?.business?.includes("overview"),
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const activities = get(data, "data.data.rows");
  const totalItems = get(data, "data.data.totalItems");
  return { activities, totalItems, isLoading, refetch };
};

export default useGetBusinessActivities;
