import get from "lodash/get";
import { useQuery } from "react-query";
import location from "../api/location";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetPropertyBilling = (id) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading } = useQuery(
    ["propertyBilling", id],
    location.getBilling,
    {
      enabled: !!isLogin && !!id,
      cacheTime: 0,
      staleTime: 0,
    }
  );
  const billing = get(data, "data.data.result");
  return { billing, isError, isLoading };
};

export default useGetPropertyBilling;
