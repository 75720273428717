/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import Menu from "antd/es/menu";
import FeatherIcon from "feather-icons-react";

import { useBusinessIdGlobal } from "../../../utilities/hooks/useBusinessId";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import useGetLocations from "../../../utilities/hooks/useGetLocations";
import { Link, useLocation } from "react-router-dom";
import { DropdownBase, LocationsDropdown } from "../../atoms";
import { Button } from "../../atoms/buttons/buttons";
import { PageHeader } from "../../atoms/page-headers/PageHeadrs";
import { useLocationIdGlobal } from "../../../utilities/hooks/useLocationId";
import Restricted from "../../../PermissionsProvider/Restricted";
import { usePermissions } from "../../../PermissionsProvider/PermissionsProvider";
import { useQueryClient } from "react-query";
import { includes } from "lodash";

const CalendarWrapper = ({ locations, businessId, children, userData, isCorporateWellness }) => {
  const queryClient = useQueryClient();
  const { permissions, businessPermissions } = usePermissions();
  const { selectedLocation, setSelectedLocation } = useLocationIdGlobal();

  useEffect(() => {
    if (locations.length)
      setSelectedLocation((prevState) => ({
        ...prevState,
        name:  locations[0]?.name,
        id:  locations[0]?.id,
      }));
  }, [locations, setSelectedLocation, isCorporateWellness]);

  const handleSelectedLocation = (id, option) => {
    setSelectedLocation({ name: option.title, id: option.value });
    queryClient.invalidateQueries("shiftDetails");
  };

  const items = [
    {
      key: "1",
      label: (
        <Restricted
          both={true}
          key="1"
          to="create"
          resource={isCorporateWellness ? "corporate" : "shift"}
          fallback={<></>}
        >
          <Link
            id="calendar_create-new-shift"
            to={{
              pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${selectedLocation.id}`,
            }}
          >
            <FeatherIcon icon="plus-circle" size={18} />
            New
          </Link>
        </Restricted>
      ),
    },
    {
      key: "2",
      label: (
        <Restricted
          both={true}
          key="1"
          to="template"
          resource={isCorporateWellness ? "corporate" : "shift"}
          fallback={<></>}
        >
          <Link
            id="calendar_use-template"
            to={{
              pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/templates/${selectedLocation.id}`,
            }}
          >
            <FeatherIcon icon="list" size={18} />
            Use Template
          </Link>
        </Restricted>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        ghost
        title={
          <LocationsDropdown
            showAllLocationsOption={false}
            value={selectedLocation.id}
            locations={locations}
            businessId={businessId}
            onChange={handleSelectedLocation}
            type="main-dropdown"
          />
        }
        buttons={[
          (isCorporateWellness ? ((businessPermissions?.corporate?.includes("create") &&
            permissions?.corporate?.includes("create")) ||
            (businessPermissions?.corporate?.includes("template") &&
              permissions?.corporate?.includes("template")))
            :
            ((businessPermissions?.shift?.includes("create") &&
              permissions?.shift?.includes("create")) ||
              (businessPermissions?.shift?.includes("template") &&
                permissions?.shift?.includes("template")))) && (
            <DropdownBase
              key="button"
              className="actions-dropdown"
              classNameC="actions-dropdown-content"
              action={["click"]}
              content={<Menu items={items}></Menu>}
            >
              <div>
                <Button
                  id="calendar_create-shift"
                  className="btn-add_new"
                  size="default"
                  type="sootheButton"
                  key="link"
                  style={{ padding: "0 40px" }}
                  disabled={!selectedLocation.id}
                >
                  + Create Appointment
                </Button>
              </div>
            </DropdownBase>
          ),
        ]}
      />

      <div className="main">
        {React.cloneElement(children, {
          selectedLocation,
          businessId,
          userData,
        })}
      </div>
    </div>
  );
};

export default ({ children }) => {
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");

  const {
    userData,
    isLoading: isUserLodaing,
    businessId,
  } = useUserProfileData(CurrentbusinessId);

  const { locations, isLoading: isLocationsLoading } = useGetLocations(
    businessId,
    "live",
    !isCorporateWellness,
    isCorporateWellness
  );

  if (isUserLodaing || isLocationsLoading || !locations) {
    return (
      <div className="spin" style={{ height: "83vh" }}>
        <Spin />
      </div>
    );
  }
  const staffingData = {
    locations,
    businessId,
    children,
    userData,
    isCorporateWellness
  };
  return <CalendarWrapper {...staffingData} />;
};
