import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "react-query";
import user from "../api/user";
import useTryAutoLogin from "./useTryAutoLogin";
import { useViewGlobal } from "./useView";

const useUserPermissions = (id) => {
  const { view } = useViewGlobal();
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading } = useQuery(
    ["permissions", id],
    user.getPermissions,
    {
      enabled: !!isLogin && !!id,
      cacheTime: view === "admin" ? 0 : 2 * 60 * 1000,
    }
  );
  const userPermissions = get(data, "data.userPermissions");
  const businessPerm = get(data, "data.businessPermissions");
  let permissions = {};
  let businessPermissions = {};
  userPermissions?.map((d) => {
    permissions[d.resource] = d.permissions;
  });
  businessPerm?.map((d) => {
    businessPermissions[d.resource] = d.permissions;
  });

  return {
    data: isEmpty(permissions) ? undefined : permissions,
    businessPermissions: isEmpty(businessPermissions)
      ? undefined
      : businessPermissions,
    isError,
    isLoading,
  };
};

export default useUserPermissions;
