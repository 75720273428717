import React from "react";
import styled from "styled-components";
import "antd/es/select/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import styles from "./ConfirmModal.module.scss";

// const ModalStyle = styled(Modal)`
//   &.ant-modal {
//     .ant-modal-content {
//       border-radius: 16px;
//       padding-top: 8px;
//       .ant-modal-header {
//         border: none;
//         > div {
//           font-weight: 500;
//           font-size: 18px;
//         }
//       }
//       .ant-modal-body {
//         padding-top: 0;
//         font-weight: 400;
//         font-size: 16px;
//       }
//     }
//   }
// `;

export default function ConfirmModal({
  isOpen,
  isClosabel = false,
  onCancel,
  title = "Are you sure you want to delete this business?",
  text = `This will delete all information including settings and appointments
  and may incur cancellation fees on upcoming appointments. This
  action cannot be undone.`,
  buttons = [
    {
      className: "my-10",
      size: "small",
      type: "danger",
      outlined: "true",
      text: "No, exit now",
      onClick: () => {},
    },
    {
      size: "small",
      type: "primary",
      text: "Yes, delete this buiness",
      onClick: () => {},
    },
  ],
}) {
  return (
    <Modal
      title={title}
      closable={isClosabel}
      wrapClassName={styles["modal-style"]}
      centered
      visible={isOpen}
      onCancel={onCancel}
      footer={buttons.map((button, index) => (
        <Button
          className={button.className}
          key={index}
          size={button.size}
          type={button.type}
          outlined={button.outlined}
          onClick={button.onClick}
          loading={button.loading}
        >
          {button.text}
        </Button>
      ))}
    >
      {text}
    </Modal>
  );
}
