import React from "react";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";

import DataStateHandler from "../../../utilities/DataStateHandler";
import useGetFileVersions from "../../../utilities/hooks/useGetFileVersions";
import styles from "./UploadFile.module.scss";
import openNotification from "../../../utilities/openNotification";

function FileVersionItem({ versionData, index, fileVersions, type = "file" }) {
  const { id, name, updatedAt, edited_by, fileurl, propertyCount, extension } =
    versionData;

  const handleDownload = (fileUrl, fileName, extension) => {
    fetch(fileUrl)
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          openNotification("Error", "Failed to download file", "error");
          throw new Error("Failed to fetch file.");
        }
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = extension ? `${fileName}.${extension}` : fileName;
        link.click();
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div key={id}>
      <div className={styles["version-title"]}>
        <div>Version {fileVersions.length - index}</div>
        <div>{moment(updatedAt).format("MM/DD/YY")}</div>
      </div>
      <div>
        {propertyCount}
        {propertyCount > 1 ? " Properties" : " Property"}
      </div>
      <div>{name}</div>
      <div>Edited by {edited_by}</div>
      {type === "file" ? (
        <div
          onClick={() => handleDownload(fileurl, name, extension)}
          className={styles["file-download-link"]}
        >
          <div>{fileurl}</div>
          <span>
            <FeatherIcon size={16} icon="download" color={"#ADB4D2"} />
          </span>
        </div>
      ) : (
        <div
          onClick={() => window.open(fileurl, "_blank")}
          className={styles["open-link"]}
        >
          <div>{fileurl}</div>
          <span>
            <FeatherIcon size={20} icon="arrow-up-right" color={"#ADB4D2"} />
          </span>
        </div>
      )}
    </div>
  );
}

function FileVersions({ businessId, fileId, type }) {
  const { fileVersions, isLoading, isError, error } = useGetFileVersions({
    businessId,
    fileId,
  });

  return (
    <DataStateHandler
      isLoading={isLoading}
      loadingComponent={<Skeleton active paragraph={{ rows: 6 }} />}
      isError={isError}
      error={error}
      data={fileVersions}
    >
      <div className={styles["file-versions-container"]}>
        {fileVersions?.map((versionData, index) => (
          <FileVersionItem
            key={index}
            versionData={versionData}
            index={index}
            fileVersions={fileVersions}
            type={type}
          />
        ))}
      </div>
    </DataStateHandler>
  );
}

export default FileVersions;
