import get from "lodash/get";
import { useQuery } from "react-query";
import notification from "../api/notifications";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetNotifications = (notificationsInfo) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, error } = useQuery(
    ["notifications", notificationsInfo],
    notification.getNotifications,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const notifications = get(data, "data.data");

  return { notifications, isError, isLoading, error };
};

export default useGetNotifications;
