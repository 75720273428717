import React from "react";
import PropTypes from "prop-types";
import PageHeader from "antd/es/page-header";
import "antd/es/page-header/style";
import styles from "./style.module.scss"

function StyledPageHeader(props) {
  const { title, subTitle, routes, extra, className } = props;

  return (
    <PageHeader
      style={{ backgroundColor: "rgb(244, 245, 247)" }}
      className={`${styles["page-header"]} ${styles[className]}`}
      title={title}
      subTitle={subTitle}
      breadcrumb={routes}
      extra={extra}
      ghost={true}
    />
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
  breadcrumb: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  extra: PropTypes.array,
  ghost: PropTypes.bool,
};

export { StyledPageHeader as PageHeader };
