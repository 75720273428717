import React from "react";
import styles from "./EllipsisLabel.module.scss";

const EllipsisLabel = ({ children, style }) => {
  return (
    <div className={styles["label"]} style={style}>
      {children}
    </div>
  );
};

export default EllipsisLabel;
