import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { DropdownStyle } from "./dropdown-style";
import DropdownStyle from "antd/es/dropdown";
import "antd/es/dropdown/style";

import styles from "./dropdown.module.scss";
const Dropdown = (props) => {
  const {
    content,
    placement,
    title,
    action,
    children,
    style,
    className,
    onClick,
  } = props;

  return (
    <DropdownStyle
      overlayClassName={className}
      style={style}
      placement={placement}
      title={title}
      overlay={<div className={styles["content-wrapper"]}>{content}</div>}
      trigger={action}
      onClick={onClick}
    >
      {children}
    </DropdownStyle>
  );
};

const content = (
  <>
    <Link to="#">
      <span>Export to CSV</span>
    </Link>
    <Link to="#">
      <span>Export to XML</span>
    </Link>
    <Link to="#">
      <span>Export to Drive</span>
    </Link>
  </>
);

Dropdown.defaultProps = {
  placement: "bottomRight",
  content,
  style: {},
  className: "strikingDash-dropdown",
};

Dropdown.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};

export { Dropdown };
