import { useQuery } from "react-query";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";
import appointment from "../api/appointment";

const useGetAppointmentsDetails = (id) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["getAppointmentDetails", id],
    appointment.getAppointmentDetails,
    {
      enabled: !!isLogin && !!id.appointmentId,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const appointmentData = get(data, "data.data.cart[0]");
  const should_show_warning = get(data, "data.data.should_show_warning", false);
  const able_to_adjust = get(data, "data.data.able_to_adjust", false);
  return { data: appointmentData, isError, error, isLoading, refetch, should_show_warning, able_to_adjust };
};

export default useGetAppointmentsDetails;
