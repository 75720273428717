/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import useTryAutoLogin from "./useTryAutoLogin";
import { useBusinessIdGlobal } from "./useBusinessId";
import useUserProfileData from "./useUserProfileData";
import useUserPermissions from "./useUserPermissions";
import { Spin } from "antd";
import { Outlet } from "react-router";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

function PermissionHandler({ businessId, permissions, businessPermissions }) {
  const { setBusinessId } = useBusinessIdGlobal();
  const { setPermissions, setBusinessPermissions } = usePermissions();

  useEffect(() => {
    setBusinessId(businessId);
    setPermissions(permissions);
    setBusinessPermissions(businessPermissions);
  }, [permissions, businessPermissions]);

  return <Outlet />;
}

export default (props) => {
  const { isLogin, isLoading } = useTryAutoLogin();
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();

  const { isLoading: isUserLodaing, businessId } =
    useUserProfileData(CurrentbusinessId);

  const {
    data: permissions,
    businessPermissions,
    isLoading: isPermissionsLoading,
  } = useUserPermissions(businessId);

  if (isLoading || isPermissionsLoading || isUserLodaing) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  const data = { businessId, permissions, businessPermissions, ...props };
  return <PermissionHandler {...data} />;
};
