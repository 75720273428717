import {axiosInstance as axios, nodePortalAxiosInstance} from "./axiosInstance";

const userEndpoints = {
  signin: "/auth/sign_in",
  logout: "/auth/logout",
  acceptInvitaion: "/auth/accept_invite",
  forgetPasswrod: "/auth/forgot_password",
  resetPassword: "/auth/reset_password",
  checkToken: "/auth/check_token",
  refreshToken: "/auth/refresh_token",
  checkResetToken: "/auth/check_reset_token",
};

const userApis = {
  login(credentials) {
    return nodePortalAxiosInstance.post(`${userEndpoints.signin}`, JSON.stringify(credentials));
  },
  logout() {
    return nodePortalAxiosInstance.post(`${userEndpoints.logout}`, {});
  },
  acceptInvitation(invitaionInfo) {
    return nodePortalAxiosInstance.post(`${userEndpoints.acceptInvitaion}`, invitaionInfo);
  },
  forgetPassword(email) {
    return nodePortalAxiosInstance.post(`${userEndpoints.forgetPasswrod}`, { email });
  },
  resetPassword(resetPasswordInfo) {
    return nodePortalAxiosInstance.post(`${userEndpoints.resetPassword}`, resetPasswordInfo);
  },
  checkToken(info) {
    return nodePortalAxiosInstance.post(`${userEndpoints.checkToken}`, info);
  },
  checkResetToken(info) {
    return nodePortalAxiosInstance.post(`${userEndpoints.checkResetToken}`, info);
  },
  refreshToken() {
    return nodePortalAxiosInstance.post(`${userEndpoints.refreshToken}`, {});
  },
};
export default userApis;
