import Spin from "antd/es/spin";
import "antd/es/spin/style";
export const Spinner = ({ loading, children }) =>
  loading ? (
    <Spin spinning={loading} tip="Loading...">
      {children}
    </Spin>
  ) : (
    <>{children}</>
  );
