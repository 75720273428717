import {axiosInstance as axios, nodePortalAxiosInstance} from "./axiosInstance";

const providerEndpoints = {
  providers: "/providers",
  block: "/block",
  locations: "locations",
  block_provider: "/block_provider",
  unblock: "/unblock",
  unblock_provider: "/unblock_provider",
  blockReasons: "/providers/provider_block_reasons",
  favorite: "/favorite",
  favorite_provider: "/favorite_provider",
  status: "/providers/change_status",
  assignProvider: "/assign",
  assign_provider: "/assign_providers",
  services: "/system/services",
  modalities: "/system/modalities",
  markets: "/system/markets",
  ProvidersByLocationId: "/get_location_services",
  onboardingProviders: "/providers",
  assignedProviders: "/providers/assigned_providers",
  unassignedProviders: "/providers/unassigned_providers",
  exportProviders: "/providers/download_onboarding",
  trainingNeeded: "/training",
  trainingDone: "/training/done",
  training_completed: "/training_completed",
  training_needed: "/training_needed"

};
const provider = {
  getProviders({ queryKey: [, params] }) {
    return axios.get(providerEndpoints.providers, { params });
  },
  exportProviders(params) {
    return nodePortalAxiosInstance.get(providerEndpoints.exportProviders, {
      params,
      responseType: "blob",
    });
  },
  onboardingProviders({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(providerEndpoints.onboardingProviders, { params });
  },
  assignedProviders({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(providerEndpoints.assignedProviders, { params });
  },
  unassignedProviders({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(providerEndpoints.unassignedProviders, { params });
  },
  blockProvider(id, locationId, blockReason) {
    return nodePortalAxiosInstance.post(`/providers/${id}${providerEndpoints.block_provider}?id=${id}`, {
      locationId,
      blockReason,
    });
  },
  getBlockReasons() {
    return nodePortalAxiosInstance.get(`${providerEndpoints.blockReasons}`);
  },
  unblockProvider(id, locationId) {
    return nodePortalAxiosInstance.post(`/providers/${id}${providerEndpoints.unblock_provider}?id=${id}`, {
      locationId,
    });
  },
  favoriteProvider(id, locationId) {
    return nodePortalAxiosInstance.post(`/providers/${id}${providerEndpoints.favorite_provider}`, {
      id,
      locationId,
    });
  },
  updateProviderStatus(id, status) {
    return nodePortalAxiosInstance.post(`${providerEndpoints.status}?id=${id}`, { status });
  },
  setTrainingNeeded(id, locationId) {
    return nodePortalAxiosInstance.post(
      `${providerEndpoints.providers}/${id}${providerEndpoints.training_needed}`,
      { locationId }
    );
  },
  setTrainingDone(id, locationId) {
    return nodePortalAxiosInstance.post(
      `${providerEndpoints.providers}/${id}${providerEndpoints.training_completed}`,
      { locationId }
    );
  },
  getProvider({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${providerEndpoints.providers}/${id}`);
  },
  updateOneProvider({ id, status, note }) {
    return nodePortalAxiosInstance.put(`${providerEndpoints.providers}/${id}`, {
      status,
      note,
    });
  },
  assignProvider(locationId, providersIds) {
    return nodePortalAxiosInstance.post(
      `${providerEndpoints.locations}/${locationId}${providerEndpoints.assign_provider}`,
      {
        providerIds: providersIds,
      }
    );
  },
  getServices() {
    return nodePortalAxiosInstance.get(providerEndpoints.services);
  },
  getModalities(services) {
    return nodePortalAxiosInstance.get(providerEndpoints.modalities, {
      params: { services },
    });
  },
  getMarkets() {
    return nodePortalAxiosInstance.get(providerEndpoints.markets);
  },
  getProvidersByLocationId({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${providerEndpoints.ProvidersByLocationId}?id=${id}`);
  },
};

export default provider;
