import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Button from "antd/es/button";
import "antd/es/button/style";
import Switch from "antd/es/switch";
import "antd/es/switch/style";
import React from "react";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { locationsCancelEvent } from "../../../utilities/webAnalytics";
import Restricted from "../../../PermissionsProvider/Restricted";
import styles from "./style.module.scss";

function FooterWizardLocation(props) {
  const {
    current,
    steps,
    prevStep,
    handleSaveAsDraft,
    isEditMode,
    handleUpdate,
    locationStatus,
    checked,
    setChecked,
    loading,
    setLoading,
    state,
    businessId,
  } = props;

  const navigate = useNavigate();

  const inFirstSteps =
    steps.findIndex((step) => step === current) < steps.length - 1;

  const onUpdateClick = () => {
    setLoading(true);
    let isUpdateStatus =
      locationStatus === "disabled" && checked === false
        ? false
        : (locationStatus === "live" ||
            locationStatus === "pending" ||
            locationStatus === "rejected" ||
            locationStatus === "draft") &&
          checked === true
        ? false
        : true;
    handleUpdate(isUpdateStatus, checked);
  };

  const onSwitchChange = (checked) => {
    setChecked(checked);
  };

  return (
    <div className={styles["footer-wrapper"]}>
      <div className={"divider"} />
      <div className="step-action-inner">
        <Row>
          <Col xs={24}>
            <div className="steps-action">
              {isEditMode ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Restricted to="disable" resource="location" fallback={<></>}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "2rem",
                      }}
                    >
                      <div style={{ marginRight: 25 }}>
                        {checked
                          ? "Disable this location"
                          : "Enable this location"}
                      </div>
                      <div className="switch-wrapper">
                        <Switch checked={checked} onChange={onSwitchChange} />
                      </div>
                    </div>
                  </Restricted>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={onUpdateClick}
                      type="primary"
                      className="button"
                      loading={loading}
                    >
                      Save updates
                    </Button>
                    <Button
                      type="secondary"
                      className="cancel-button button"
                      onClick={() => {
                        locationsCancelEvent();
                        navigate(
                          state?.mode === "business"
                            ? `/businesses/${businessId}`
                            : "/properties"
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="create-mode-wrapper">
                  {steps.findIndex((step) => step === current) > 0 ? (
                    <Button
                      className="btn-prev button"
                      type="light"
                      onClick={() => prevStep()}
                    >
                      <FeatherIcon icon="arrow-left" size={16} />
                      Previous
                    </Button>
                  ) : (
                    <span></span>
                  )}

                  <div className="save-action">
                    <Form.Item>
                      <Button
                        className="button"
                        type="secondary"
                        onClick={handleSaveAsDraft}
                        disabled={loading}
                      >
                        Save as Draft
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="submit-button button"
                        loading={loading}
                      >
                        {inFirstSteps ? "Save & Next" : "Submit"}
                        <FeatherIcon icon="arrow-right" size={16} />
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FooterWizardLocation;
