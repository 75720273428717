import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Button from "antd/es/button";
import "antd/es/button/style";
import Switch from "antd/es/switch";
import "antd/es/switch/style";
import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
// import { locationsCancelEvent } from "../../../utilities/webAnalytics";
import styles from "./styles.module.scss";

function Footer(props) {
  const {
    handleUpdate,
    locationStatus,
    checked,
    setChecked,
    loading,
    setLoading,
    permissions,
  } = props;

  const navigate = useNavigate();

  const onUpdateClick = () => {
    handleUpdate();
  };

  const onSwitchChange = (checked) => {
    setChecked(checked);
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={"divider"} />
      <div className="step-action-inner">
        <Row>
          <Col xs={24}>
            <div className="steps-action">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Button
                    // onClick={onUpdateClick}
                    type="primary"
                    htmlType="submit"
                    className="submit-button button"
                    loading={loading}
                  >
                    Save updates
                  </Button>
                  <Button
                    type="secondary"
                    className="cancel-button button"
                    onClick={() => {
                      //   locationsCancelEvent();
                      navigate("/businesses");
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
