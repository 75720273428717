import get from "lodash/get";

import { useQuery } from "react-query";
import locationApi from "../api/location";
import useTryAutoLogin from "./useTryAutoLogin";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const useGetLocation = (id, businessId) => {
  const { permissions } = usePermissions();
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["location", id, businessId],
    () => locationApi.getLocationById(id, businessId),
    {
      enabled: !!isLogin && !!id && !!permissions?.location?.includes("read"),
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const location = get(data, "data.data.location");
  const workingHours = get(data, "data.data.workingHours");

  return { location, workingHours, isError, error, isLoading, refetch };
};

export default useGetLocation;
