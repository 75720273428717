import React from "react";
import "antd/es/select/style";
import { Modal } from "../../atoms/modals/antd-modals";
import Button from "antd/es/button";
import "antd/es/button/style";
import styles from "./SuccessModal.module.scss";

export default function SuccessModal({
  isOpen,
  title = "Are you sure you want to delete this business?",
  text = `This will delete all information including settings and appointments
  and may incur cancellation fees on upcoming appointments. This
  action cannot be undone.`,
  buttons = [
    {
      className: "my-10",
      size: "small",
      type: "danger",
      outlined: "true",
      text: "No, exit now",
      onClick: () => {},
    },
    {
      size: "small",
      type: "primary",
      text: "Yes, delete this buiness",
      onClick: () => {},
    },
  ],
}) {
  return (
    <Modal
      className={styles["modal-wrapper"]}
      title={title}
      closable={false}
      centered
      visible={isOpen}
      footer={buttons.map((button, index) => (
        <Button
          className={button.className}
          key={index}
          size={button.size}
          type={button.type}
          outlined={button.outlined}
          onClick={button.onClick}
          loading={button.loading}
          style={button.style}
        >
          {button.text}
        </Button>
      ))}
    >
      {text}
    </Modal>
  );
}
