import { nodePortalAxiosInstance } from "./axiosInstance";
const vdEndpoints = {
    volumeDiscounts: "/invoice_discounts",
    toggleOn: "/invoice_discounts/toggle_on",
    toggleOff: "/invoice_discounts/toggle_off"

}
const VolumeDiscountsApis = {
    postVDToggleOn({ discountable_type, discountable_id, ids }) {
        return nodePortalAxiosInstance.post(`${vdEndpoints.toggleOn}`,
            {
                discountable_type,
                discountable_id,
                ids
            }
        );
    },
    postVDToggleOff({ discountable_type, discountable_id, ids }) {
        return nodePortalAxiosInstance.post(`${vdEndpoints.toggleOff}`,
            {
                discountable_type,
                discountable_id,
                ids
            }
        );
    },
}
export default VolumeDiscountsApis;