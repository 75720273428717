import React from "react";
import Select from "antd/es/select";
import "antd/es/select/style";

import { AutoCompleteInput } from "../../../molecules/autoComplete/autoComplete";
import styles from "./Filters.module.scss";

const { Option } = Select;

function Filters({
  sortingOptions,
  onSorting,
  dataSource,
  onSearch,
  placeholder,
  onSearchBusinessByProperty,
  showSearchByProperty = false,
}) {
  return (
    <div className={styles["filters-container"]}>
      <div className={styles["sort-container"]}>
        <div className={styles["sort-by"]}>Sort By</div>
        <Select
          className={styles["sort-select"]}
          placeholder="Select..."
          onChange={onSorting}
        >
          {sortingOptions.map((option) => (
            <Option key={option.id} value={option.value}>
              {option.title}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles["search-container"]}>
        <AutoCompleteInput
          onSearch={onSearch}
          onSelect={onSearch}
          dataSource={dataSource || []}
          placeholder={placeholder}
          width="100%"
          patterns
        />
      </div>
      {showSearchByProperty && (
        <div className={styles["search-container"]}>
          <AutoCompleteInput
            onSearch={onSearchBusinessByProperty}
            onSelect={onSearchBusinessByProperty}
            dataSource={[]}
            placeholder="Search Business By Property"
            width="100%"
            patterns
          />
        </div>
      )}
    </div>
  );
}

export default Filters;
