import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import PopoverAntd from "antd/es/popover";
import "antd/es/popover/style";
import styles from "./popup.module.scss"

const Popover = (props) => {
  const {
    content,
    placement,
    title,
    action,
    children,
    visible,
    handleVisibleChange,
    ...rest
  } = props;
  const content1 = <div className={styles["content"]}>{content}</div>;

  return (
    <PopoverAntd
      placement={placement}
      title={title && <p className={styles["title"]}>{title}</p>}
      content={content1}
      trigger={action}
      open={visible}
      onOpenChange={handleVisibleChange}
      {...rest}
    >
      {children}
    </PopoverAntd>
  );
};

const content = (
  <>
    <Link to="#">
      <FeatherIcon size={16} icon="check" />
      <span>Btn Dropdown one</span>
    </Link>
    <Link to="#">
      <FeatherIcon size={16} icon="check" />
      <span>Btn Dropdown two</span>
    </Link>
    <Link to="#">
      <FeatherIcon size={16} icon="check" />
      <span>Btn Dropdown three</span>
    </Link>
  </>
);

Popover.defaultProps = {
  action: "hover",
  placement: "bottomCenter",
  content,
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export { Popover };