import React, { useState } from "react";
import { addDays } from "date-fns";
import moment from "moment-timezone";

import { Cards } from "../../../../atoms/cards/Cards";
import styles from "./Overview.module.scss";
import ActivitiesCard from "../../../overview/ActivitiesCard";
import AppointmentsAndShiftsCard from "../../../overview/AppointmentsAndShiftsCard";
import useGetBusinessOverview from "../../../../../utilities/hooks/useGetBusinessOverview";
import useGetBusinessActivities from "../../../../../utilities/hooks/useGetBusinessActivities";
import LinkTo from "../../../../atoms/link";

function Overview({ business }) {
  const [page, setPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [fromSessionDate, setFromSessionDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toSessionDate, setToSessionDate] = useState(
    moment(addDays(new Date(), 30)).format("YYYY-MM-DD")
  );
  const [state, setState] = useState({
    datePickerInternational: null,
    dateRangePicker: {
      selection: {
        startDate: new Date(),
        endDate: addDays(new Date(), 30),
        key: "selection",
      },
    },
  });

  const handleRangeChange = (which) => {
    setState({
      ...state,
      dateRangePicker: {
        ...state.dateRangePicker,
        ...which,
      },
    });
  };

  const onClick = () => {
    let from = moment(state.dateRangePicker.selection.startDate).format(
      "YYYY-MM-DD"
    );
    let to = moment(state.dateRangePicker.selection.endDate).format(
      "YYYY-MM-DD"
    );
    if (fromSessionDate !== from || toSessionDate !== to) {
      setFromSessionDate(from);
      setToSessionDate(to);
    }
    setVisible(false);
  };

  const onCancel = () => {
    let from = moment(new Date()).format("YYYY-MM-DD");
    let to = moment(addDays(new Date(), 30)).format("YYYY-MM-DD");
    setFromSessionDate(from);
    setToSessionDate(to);
    setVisible(false);
    setState({
      ...state,
      datePickerInternational: null,
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: addDays(new Date(), 30),
          key: "selection",
        },
      },
    });
  };

  const {
    activities,
    totalItems,
    isLoading: isActivitiesLoading,
  } = useGetBusinessActivities({
    businessId: business.id,
    page,
  });

  const {
    location,
    appointments,
    isLoading: isOverviewLoading,
  } = useGetBusinessOverview({
    id: business.id,
    from_session: fromSessionDate,
    to_session: toSessionDate,
    page: 1,
    per_page: 10,
  });

  const onPageChange = (newPage) => setPage(newPage - 1);

  return (
    <div className={styles["cards-container"]}>
      <AppointmentsAndShiftsCard
        title="Upcoming Shifts & Appointments"
        business={{ [business.id]: business.name }}
        location={location}
        appointments={appointments}
        isLoading={isOverviewLoading}
        isbutton={
          <LinkTo
            to="/appointments"
            text="View more"
            className={styles["view-more"]}
          />
        }
      />

      <Cards title="Activity">
        <div className={styles["activity-container"]}>
          <ActivitiesCard
            activities={activities}
            page={page}
            totalItems={totalItems}
            isLoading={isActivitiesLoading}
            onPageChange={onPageChange}
          />
        </div>
      </Cards>
    </div>
  );
}

export default Overview;
