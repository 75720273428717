import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select from "antd/es/select";
import "antd/es/select/style";
import FeatherIcon from "feather-icons-react";
import debounce from "lodash.debounce";
import Card from "antd/es/card";
import "antd/es/card/style";
import styles from "./staffing.module.scss";

import FilterIcon from "../../../../static/img/icon/filters-icon.svg";
import { AutoCompleteInput } from "../../../molecules/autoComplete/autoComplete";
import { ProjectSorting } from "./style";
import { Col, Menu, Popover, Row } from "antd";
import { Button } from "../../../atoms/buttons/buttons";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Filters({
  selectedCategory,
  onChangeCategory,
  setSortingField,
  sortingField,
  users,
  setCreatedBy,
  setModifiedBy,
  setShiftType,
  templates,
  setSearch,
}) {
  const [visible, setVisible] = useState(false);

  const onSorting = (selectedItems) => {
    setSortingField({ field: selectedItems, type: " descending" });
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const searchChangeHandler = (searchUp) => {
    setSearch(searchUp);
  };

  const debouncedSearchChangeHandler = useMemo(
    () => debounce(searchChangeHandler, 500),
    []
  );

  const items = [
    getItem(
      "Created By",
      "sub1",
      null,
      users.created.map((user) =>
        getItem(`${user.fname} ${user.lname}`, `createdBy-${user.id}`)
      )
    ),
    getItem(
      "Modified By",
      "sub2",
      null,
      users.modified.map((user) =>
        getItem(`${user.fname} ${user.lname}`, `modifiedBy-${user.id}`)
      )
    ),
    getItem("Shift Type", "sub3", null, [
      getItem("Spa", `shiftType-Spa`),
      getItem("Massage", `shiftType-Massage`),
    ]),
  ];

  const onMenuClick = (e) => {
    let key = e.key.split("-");
    if (key[0] === "createdBy") {
      setCreatedBy(key[1]);
      setModifiedBy();
      setShiftType();
    }
    if (key[0] === "modifiedBy") {
      setModifiedBy(key[1]);
      setCreatedBy();
      setShiftType();
    }
    if (key[0] === "shiftType") {
      setShiftType(key[1]);
      setCreatedBy();
      setModifiedBy();
    }
    setVisible(false);
  };

  const content = (
    <Card
      bordered={false}
      title={
        <div className={styles["flex"]}>
          <img src={FilterIcon} alt="" />
          <div>Filters</div>
        </div>
      }
    >
      <Menu onClick={onMenuClick} mode="vertical" items={items} />
    </Card>
  );

  return (
    <ProjectSorting>
      <Row className="project-sort-bar">
        <Col lg={12} md={14} sm={24} className="project-sort-group">
          <div className="sort-group mr-10">
            <div className="sort-by">Sort By</div>
            <Select
              onChange={onSorting}
              value={sortingField?.field}
              placeholder="Select..."
            >
              <Select.Option value="name">Name</Select.Option>
              <Select.Option value="modified_date">Modified Date</Select.Option>
              <Select.Option value="created_date">Created Date</Select.Option>
              <Select.Option value="creator_name">Creator Name</Select.Option>
              <Select.Option value="shift_type">Type of Shift</Select.Option>
            </Select>
          </div>
          <Popover
            open={visible}
            className={styles["filter-by"]}
            overlayClassName={styles["overlay-content"]}
            placement="bottomLeft"
            showArrow={false}
            content={content}
            trigger="click"
            onOpenChange={handleVisibleChange}
          >
            <Button size="small" onClick={() => setVisible(!visible)}>
              <img src={FilterIcon} alt="" />
              Filters
            </Button>
          </Popover>
        </Col>
        <Col lg={12} md={10} sm={24} className="project-sort-search">
          <AutoCompleteInput
            onSearch={debouncedSearchChangeHandler}
            onSelect={debouncedSearchChangeHandler}
            dataSource={templates || []}
            placeholder="Search Templates"
            width="100%"
            patterns
          />
          <div className="layout-style">
            <li
              className={selectedCategory === "grid" ? "active" : "deactivate"}
            >
              <Link to="#" onClick={() => onChangeCategory("grid")}>
                <FeatherIcon icon="grid" size={16} />
              </Link>
            </li>
            <li
              className={selectedCategory === "list" ? "active" : "deactivate"}
            >
              <Link to="#" onClick={() => onChangeCategory("list")}>
                <FeatherIcon icon="list" size={16} />
              </Link>
            </li>
          </div>
        </Col>
      </Row>
    </ProjectSorting>
  );
}

export default Filters;
