import React from "react";
import { Navigate, Outlet } from "react-router";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";
// import { useQueryClient } from "react-query";
// import { get } from "lodash";

const ProtectedRoutes = ({
  to,
  resource,
  redirectPath = "/no-permission",
  both = false,
  // onlySootheMembers = false,
}) => {
  const { permissions, businessPermissions } = usePermissions();
  // const queryClient = useQueryClient()
  // const isSootheMember = get(queryClient.getQueryData("userProfileData"), "data.data.role.isSootheMember", false);

  if (!permissions && !businessPermissions) return <Outlet />;

  // if (onlySootheMembers && isSootheMember) return <Outlet />;

  let allowedOnBusiness = businessPermissions?.[resource]?.includes(to);
  let allowedOnUser = permissions?.[resource]?.includes(to);

  if (both) {
    if (allowedOnBusiness && allowedOnUser) return <Outlet />;
  } else {
    if (allowedOnUser) return <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoutes;
