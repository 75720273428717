import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import Heading from "../heading/heading";
import { Dropdown } from "../dropdown/dropdown";
import Card from "antd/es/card";
import "antd/es/card/style";
import styles from "./cards.module.scss";

const Cards = (props) => {
  const {
    title,
    children,
    more,
    moreText,
    size,
    headless,
    caption,
    isbutton,
    bodyStyle,
    headStyle,
    border,
    className,
  } = props;
  return (
    <>
      {!headless ? (
        <Card
          className={styles["card-wrapper"]}
          size={size}
          title={title}
          headStyle={headStyle && headStyle}
          bordered={border}
          extra={
            <>
              {more && (
                <Dropdown content={more} placement="bottom">
                  <NavLink to="#">
                    {!moreText ? (
                      <FeatherIcon icon="more-horizontal" />
                    ) : (
                      "More"
                    )}
                  </NavLink>
                </Dropdown>
              )}

              {isbutton && <span className={styles["btn-wrapper"]}>{isbutton}</span>}
            </>
          }
          style={{ width: "100%" }}
        >
          {children}
        </Card>
      ) : (
        <Card
          className={styles["card-wrapper"]}
          size={size}
          style={{ width: "100%" }}
          bordered={border}
        >
          {title && <Heading as="h4">{title}</Heading>}
          {caption && <p>{caption}</p>}
          {children}
        </Card>
      )}
    </>
  );
};

Cards.defaultProps = {
  border: false,
};

Cards.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  size: PropTypes.string,
  more: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  bodyStyle: PropTypes.object,
  headStyle: PropTypes.object,
  isbutton: PropTypes.node,
  headless: PropTypes.bool,
  border: PropTypes.bool,
  caption: PropTypes.string,
  bodypadding: PropTypes.string,
  moreText: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export { Cards };
