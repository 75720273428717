import React, { useEffect, useReducer, useState } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Form from "antd/es/form";
import "antd/es/form/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow } from "../../atoms";
import Col from "antd/es/grid/col";
import DatePicker from "antd/es/date-picker";
import TimePicker from "antd/es/time-picker";
import { Row } from "antd";
import styles from "../../organisms/CreateShift/TimeAndDate/TimeAndDate.module.scss";
import {
  onSelectStartTime,
  startDisabledTime,
  fromDisabledTime,
  onSelectHourFrom,
  lastDisabledTime,
  onSelectHourTill
} from "../../organisms/CreateShift/TimeAndDate/helpers";
import { getMinutes } from "../../../utilities/helpers";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetLocation from "../../../utilities/hooks/useGetLocation";
import openNotification from "../../../utilities/openNotification";
import shiftApi from "../../../utilities/api/shift";
import { useQueryClient } from "react-query";
import FeeModal from "./FeeModal";
import style from "./style.module.scss";
import { get, isEmpty } from "lodash";
import WarningModal from "../../organisms/CreateShift/WarningModal";

function RescheduleAppointmentModal({
  isOpen,
  onExit,
  loading,
  setLoading,
  disabled,
  locationId,
  appointmentId,
  businessId,
  rescheduleSpaAppointment,
  setRescheduleSpaAppointment,
}) {
  const queryClient = useQueryClient();

  const {
    data: selectedAppointment,
    isLoading,
    refetch,
    should_show_warning,
    able_to_adjust
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const { workingHours: workingShifts, isLoading: isLocationLoading } =
    useGetLocation(locationId, businessId);

  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "hh:mm:ss";

  const [formData, setFormData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      date: "",
      shiftHours: {
        start: 0,
        end: 0,
        diff: 0,
      },
    }
  );

  const { date, shiftHours } = formData;

  let fixedDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 23, 24];
  const [firstDisabledHours, setFirstDisabledHours] = useState([]);
  const [lastDisabledHours, setLastDisabledHours] = useState([]);
  const [availableShiftTime, setAvailableShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [shiftTime, setShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [selectDay, setSelectedDay] = useState({
    day: disabled ? "" : moment(date).format("dddd"),
    date: disabled ? "" : moment.utc(date),
  });
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  useEffect(() => {
    if (selectedAppointment) {
      moment.tz.setDefault(get(selectedAppointment, "time.timezone", ""));
      resetFormData();
    }
  }, [selectedAppointment]);

  const resetFormData = () => {
    setFormData({
      date: "",
      shiftHours: {
        start: "",
        end: "",
        diff: moment
          .duration(
            moment(selectedAppointment?.cart_end_time.display, "h:mm a").diff(
              moment(selectedAppointment?.time.display, "h:mm a"),
              "minutes"
            ),
            "minutes"
          )
          .asHours(),
      },
    });
  };

  useEffect(() => {
    if (date) {
      setSelectedDay({ ...selectDay, date: moment.utc(date) });
      const day = moment(date).format("dddd");
      const { start, end } = workingDaysAndHours[day];
      setAvailableShiftTime({ start, end });
      form.setFieldsValue({
        date: moment(date),
        "start-time": shiftHours.start ? moment(shiftHours.start, "hh:mm") : "",
        "end-time": shiftHours.end ? moment(shiftHours.end, "hh:mm") : "",
      });
    }
  }, [date, form, shiftHours]);

  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;
  
  let workingDaysAndHours = {};
  workingShifts
    ?.filter((day) => day.enabled)
    .forEach((day) => {
      let endDay;
      for (let i = 0; i < shiftHours.diff; i++)
        endDay = moment(day.till, "hh:mm:ss").hours() - i;
      workingDaysAndHours[day.weekname] = {
        start: moment(day.from, "hh:mm"),
        end: endDay,
      };
    });

  const handleSetShiftHours = (date, start, end, skipTimeSet = false) => {
    const firstAppointmentAvailableHour = isSootheMember
      ? moment()
      : moment().add(2, "hours");
    const firstAppointmentEnabledHour = moment(start, timeFormat);
    const lastAppointmentEnabledHour = moment(end, timeFormat);

    if (moment().format(dateFormat) === date.format(dateFormat)) {
      if (
        firstAppointmentAvailableHour.hour() <
        firstAppointmentEnabledHour.hour()
      ) {
        let extraValues = skipTimeSet ? {} : {
          start: moment().set({
            hours: firstAppointmentEnabledHour.hour(),
            minutes: firstAppointmentEnabledHour.minutes(),
          }),
          end: firstAppointmentEnabledHour.add(
            moment.duration(shiftHours.diff, "hours")
          )
        }
        setFormData({
          shiftHours: {
            ...shiftHours,
            ...extraValues
          },
        });
        return;
      }
      if (
        firstAppointmentAvailableHour.hour() > lastAppointmentEnabledHour.hour()
      ) {
        let extraValues = skipTimeSet ? {} : {
          start: moment().set({
            hours: lastAppointmentEnabledHour.hour() - 1,
            minutes: 0,
          }),
          end: lastAppointmentEnabledHour.add(
            moment.duration(shiftHours.diff, "hours")
          )
        }
        setFormData({
          shiftHours: {
            ...shiftHours,
            ...extraValues
          },
        });
        return;
      }
      let hours = isSootheMember
        ? firstAppointmentAvailableHour.minutes() > 15
          ? firstAppointmentAvailableHour.hour() + 1
          : firstAppointmentAvailableHour.hour()
        : firstAppointmentAvailableHour.minutes() > 45
          ? firstAppointmentAvailableHour.hour() + 1
          : firstAppointmentAvailableHour.hour();
      let minutes = isSootheMember
        ? firstAppointmentAvailableHour.minutes() >= 30
          ? getMinutes(firstAppointmentAvailableHour.minutes() - 30)
          : getMinutes(firstAppointmentAvailableHour.minutes() + 30)
        : getMinutes(firstAppointmentAvailableHour.minutes());
      let extraValues = skipTimeSet ? {} : {
        start: moment().set({
          hours: hours,
          minutes: minutes,
        }),
        end: moment()
          .set({
            hours: hours,
            minutes: minutes,
          })
          .clone()
          .set({
            minutes: minutes,
          })
          .add(moment.duration(shiftHours.diff, "hours"))
      }
      setFormData({
        shiftHours: {
          ...shiftHours,
          ...extraValues
        },
      });
      return;
    } else {
      let extraValues = skipTimeSet ? {} : {
        start: moment(start, "hh:mm"),
        end: firstAppointmentEnabledHour.add(
          moment.duration(shiftHours.diff, "hours")
        )
      }
      setFormData({
        shiftHours: {
          ...shiftHours,
          ...extraValues
        },
      });
    }
  };

  const handleRescheduleSpaAppointment = (form) => {
    setWarningModalIsOpen(false)
    form.validateFields().then((values) => {
      let data = {
        id: selectedAppointment.appointment_number,
        businessId: businessId,
        locationId: selectedAppointment.location_id,
        session_date: values.date.format("YYYY-MM-DD"),
        session_time: values["start-time"].format("HH:mm"),
        session_end_time: values["end-time"].format("HH:mm"),
        quoted_fee: selectedAppointment.reschedule_fee,
        rebook: "",
      };
      if (selectedAppointment.reschedule_fee) {
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isModalOpen: false,
          isFeeModalOpen: true,
          data,
          form,
        });
      } else {
        setRescheduleSpaAppointment({
          isModalLoading: true,
        });
        onRescheduleSpaAppointment(data, form);
      }
    });
  };

  const onRescheduleSpaAppointment = (data, form) => {
    shiftApi
      .rescheduleShift(data)
      .then((res) => {
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isModalOpen: false,
          isFeeModalLoading: false,
          isFeeModalOpen: false,
        });
        form.resetFields();
        refetch();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("businessAppointments");
        queryClient.refetchQueries("allBusinessesAppointments");
        openNotification("Reschedule Appointment", res.data.message, "success");
      })
      .catch(({ response }) => {
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isFeeModalLoading: false,
        });
        openNotification("Error", response.data.message, "error");
      });
  };

  const { appointment_number, time, cart_end_time, providers_json } =
    selectedAppointment || {};


  return (
    <>
      <Modal
        wrapClassName={`${style["modal"]} ${style["navy-modal"]}`}
        title={
          !isLoading &&
          !isLocationLoading &&
          `When would you like to reschedule #${appointment_number} to?`
        }
        visible={isOpen}
        footer={null}
        onCancel={onExit}
        centered
      >
        {isLoading || isLocationLoading ? (
          <Skeleton active paragraph={{ rows: 11 }} />
        ) : (
          <div className="view-modal-body">
            <div className="view-modal-body-card" style={{ marginRight: 0 }}>
              <Card
                style={{
                  marginBottom: "32px",
                  border: "none",
                }}
                headless="true"
              >
                <p
                  className="view-modal_section-title"
                  style={{
                    color: "#586B94",
                  }}
                >
                  #{appointment_number}
                </p>

                <FlexRow>
                  <FeatherIcon
                    icon="calendar"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {moment(time.utc, "YYYY/MM/DD").format("dddd, MMM D")} at{" "}
                    {time.display}
                    {" - "}
                    {cart_end_time.display}
                  </p>
                </FlexRow>
                <FlexRow>
                  <FeatherIcon
                    icon="map-pin"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {selectedAppointment.address.address_line_1},{" "}
                    {selectedAppointment.address.city}
                  </p>
                </FlexRow>
                <FlexRow>
                  <FeatherIcon
                    icon="shopping-cart"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {providers_json.length}{" "}
                    {providers_json.length > 1 ? "Providers" : "Provider"}{" "}
                    Confirmed, offers will be resent
                  </p>
                </FlexRow>
              </Card>
            </div>
            <div className="mb-20">
              <Form
                form={form}
                name="reschedule"
                onFinish={() => {
                  if (moment(selectedAppointment?.cart_end_time?.utc).isBefore(moment())) {
                    setWarningModalIsOpen(true)
                  } else {
                    handleRescheduleSpaAppointment(form)
                  }
                }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col md={8} sm={24}>
                    <Form.Item
                      className={styles["date-container"]}
                      colon={false}
                      name="date"
                      label="Date"
                      rules={[{ required: true, message: "Date is required" }]}
                    >
                      <DatePicker
                        initialValues={date && moment(date)}
                        value={moment(date)}
                        allowClear={false}
                        className={`${styles["date-picker"]} ${styles["date-picker-input"]}`}
                        onSelect={(dateNw) => {
                          const day = moment(dateNw).format("dddd");
                          const { start, end } = workingDaysAndHours[day];
                          setSelectedDay({ day, date: dateNw });
                          setFormData({ date: dateNw });
                          setAvailableShiftTime({ start, end });
                          setShiftTime({ start, end });
                          handleSetShiftHours(dateNw, start, end, isEmpty(date));
                        }}
                        disabledDate={(current) => {
                          const availbleHours =
                            workingDaysAndHours[current.format("dddd")];
                          return (
                            (moment(current.format("YYYY-MM-DD")).isSame(
                              moment().format("YYYY-MM-DD")
                            ) &&
                              moment().hour() + 2 >=
                              moment(
                                availbleHours?.end,
                                "hh:mm:ss"
                              ).hour()) ||
                            moment().add(-1, "days") >= current ||
                            !Object.keys(workingDaysAndHours).includes(
                              String(current.format("dddd"))
                            )
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={24}>
                    <Form.Item
                      colon={false}
                      name="start-time"
                      label="Start Time"
                      rules={[
                        { required: true, message: "Start Time is required" },
                      ]}
                    >
                      <TimePicker
                        className={styles["date-picker"]}
                        popupClassName={styles["time-picker-popup"]}
                        use12Hours
                        inputReadOnly
                        format="hh:mm A"
                        minuteStep={5}
                        disabled={!selectDay.date}
                        showNow={false}
                        allowClear={false}
                        // initialValues={shiftHours.start && shiftHours.start}
                        // value={moment(shiftHours.start, "hh:mm A")}
                        disabledTime={() =>
                          fromDisabledTime({
                            availableShiftTime,
                            endShiftTime: moment(shiftTime.end, "hh A"),
                            firstDisabledHours,
                            fixedDisabledHours,
                            selectedDay: date,
                            isSootheMember,
                          })
                        }
                        onSelect={(time) =>
                          onSelectHourFrom({
                            time,
                            setShiftHours: setFormData,
                            availableShiftTime,
                            form,
                            shiftHours,
                            selectedDay: date,
                            isSootheMember,
                            setLastDisabledHours,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={24}>
                    <Form.Item
                      style={{ flexGrow: 1 }}
                      colon={false}
                      name="end-time"
                      label="End Time"
                      rules={[
                        { required: true, message: "End Time is required" },
                      ]}
                    >
                      <TimePicker
                        className={styles["date-picker"]}
                        popupClassName={styles["time-picker-popup"]}
                        use12Hours
                        allowClear={false}
                        inputReadOnly
                        format="hh:mm A"
                        showNow={false}
                        disabled={!selectDay.date}

                        disabledTime={() =>
                          lastDisabledTime({
                            availableShiftTime,
                            startShiftTime: moment(shiftTime.start, "hh A"),
                            lastDisabledHours,
                            fixedDisabledHours,
                            selectedDay: date,
                            isSootheMember,
                          })
                        }
                        onSelect={(time) => {
                          onSelectHourTill({
                            time,
                            setShiftHours: setFormData,
                            availableShiftTime,
                            form,
                            shiftHours,
                            selectedDay: date,
                            isSootheMember,
                            setFirstDisabledHours,
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <footer>
              <Button key={1} size="small" onClick={onExit} type="light">
                Close without saving
              </Button>

              <Button
                style={{ margin: 0 }}
                size="small"
                type="sootheButton"
                onClick={() => form.submit()}
                key="submit"
                htmlType="submit"
                loading={loading}
              >
                Reschedule and resend offers now
              </Button>
            </footer>
          </div>
        )}
      </Modal>
      {rescheduleSpaAppointment.isFeeModalOpen && (
        <FeeModal
          isOpen={rescheduleSpaAppointment.isFeeModalOpen}
          loading={rescheduleSpaAppointment.isFeeModalLoading}
          title={"Are you sure you want to reschedule this appointment?"}
          body={
            <div>
              <p>
                Rescheduling this appointment will incur the fee below as stated
                in our cancellation policy
              </p>
              <p>
                Your total charge will be $
                {parseInt(selectedAppointment.reschedule_fee).toFixed(2)}.
              </p>
            </div>
          }
          action="reschedule"
          onExit={onExit}
          onSubmit={() => {
            setRescheduleSpaAppointment({
              isFeeModalLoading: true,
            });
            onRescheduleSpaAppointment(
              rescheduleSpaAppointment.data,
              rescheduleSpaAppointment.form
            );
          }}
        />
      )}
      <WarningModal isOpen={warningModalIsOpen} onExit={() => { setWarningModalIsOpen(false) }} onSubmit={() => {
        handleRescheduleSpaAppointment(form)
      }} />
    </>
  );
}

export default RescheduleAppointmentModal;
