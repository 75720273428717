import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { Button } from "../../../atoms/buttons/buttons";
import { useQueryClient } from "react-query";

function Week({ weekState, setWeekState, onWeekChange }) {
  const queryClient = useQueryClient();

  const { startOfCurrentWeek } = weekState;

  useEffect(() => {
    onWeekChange(startOfCurrentWeek);
  }, []);

  const onWeekIncrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    let newVal = startOfCurrentWeek.clone().add(1, "weeks");
    setWeekState({
      ...weekState,
      startOfCurrentWeek: newVal
    })
    onWeekChange(newVal)
  };

  const onWeekDecrement = () => {
    queryClient.refetchQueries("getAppointmentDetails");
    let newVal = startOfCurrentWeek.clone().subtract(1, "weeks");
    setWeekState({
      ...weekState,
      startOfCurrentWeek: newVal
    })
    onWeekChange(newVal)
  };


  return (
    <div className="calender-head__navigation">
      <Button
        id="calendar_left-arrow-button"
        onClick={onWeekDecrement}
        className="btn-navigate"
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-left" />
      </Button>
      <span id="calendar_date" className="date-label">
        {`${startOfCurrentWeek.format("MMM DD")} - 
        ${startOfCurrentWeek.clone().add(6, "days").format("MMM DD, YYYY")}`}
      </span>
      <Button
        id="calendar_right-arrow-button"
        onClick={onWeekIncrement}
        className="btn-navigate"
        type="white"
        outlined
      >
        <FeatherIcon icon="chevron-right" />
      </Button>
    </div>
  );
}

export default Week;