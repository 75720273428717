/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import Form from "antd/es/form";
import "antd/es/form/style";
import Footer from "./Footer";
import { PageHeader } from "../../components/molecules/page-header/PageHeader";
import Heading from "../../components/atoms/heading/heading";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import map from "lodash/map";
import Select from "antd/es/select";
import "antd/es/select/style";
import styles from "./roles.module.scss";
import useGetRoleTypes from "../../utilities/hooks/useGetRoleTypes";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import useGetPermissionsPerRole from "../../utilities/hooks/useGetPermissionsPerRole";
import rolesApi from "../../utilities/api/roles";
import Switch from "antd/es/switch";
import "antd/es/switch/style";
import openNotification from "../../utilities/openNotification";
import useHotjar from "../../utilities/hooks/useHotjar";
import { useQueryClient } from "react-query";

const Roles = ({ roles }) => {
  const queryClient = useQueryClient();

  const [selectedRole, setSelectedRole] = useState(roles?.[0]?.id);
  const [isSuperAdmin, setIsSuperAdmin] = useState(roles?.[0].isSuperAdmin);
  const [isSootheMember, setIsSootheMember] = useState(
    roles?.[0].isSootheMember
  );
  const [isBusinessSuperAdmin, setIsBusinessSuperAdmin] = useState(
    roles?.[0].business_superadmin
  );
  const [viewAllTeams, setViewAllTeams] = useState(roles?.[0].viewAllTeams);
  const [isLoading, setIsLoading] = useState(false);
  const { permissions, isPermissionsLoading } =
    useGetPermissionsPerRole(selectedRole);
  const [inviteRoles, setInviteRoles] = useState(
    permissions?.selected_roles.map((role) => role.id)
  );
  const [form] = Form.useForm();
  const permissionsCategories = permissions?.all_permissions;
  const [selectPermissionsOfCategories, setSelectPermissionsOfCategories] =
    useState(
      permissions?.selected_permissions.map((item) => ({
        resource: item.resource,
        values: item.permissions.map((perm) => perm.id),
      }))
    );
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (permissions) {
      const inviteRolesData = permissions?.selected_roles.map(
        (role) => role.id
      );
      setInviteRoles(inviteRolesData);
      setSelectPermissionsOfCategories(
        permissions?.selected_permissions.map((item) => ({
          resource: item.resource,
          values: item.permissions.map((perm) => perm.id),
        }))
      );
      setIsSuperAdmin(permissions?.isSuperAdmin);
      setIsBusinessSuperAdmin(permissions?.business_superadmin);
      setIsSootheMember(permissions?.isSootheMember);
      setViewAllTeams(permissions?.viewAllTeams);
    }
  }, [permissions]);

  if (!permissions || isPermissionsLoading) {
    return (
      <div className="spin" style={{ height: "70vh" }}>
        <Spin />
      </div>
    );
  }
  const onUpdatePermissions = () => {
    setIsLoading(true);
    const data = {
      roles: inviteRoles,
      isSuperAdmin,
      business_superadmin: isBusinessSuperAdmin,
      isSootheMember: isSootheMember,
      viewAllTeams,
      permissions: selectPermissionsOfCategories
        .map((item) => item.values)
        .flat(),
    };
    rolesApi
      .updatePermissions(selectedRole, data)
      .then((res) => {
        setIsLoading(false);
        openNotification(
          "Save Business",
          "Roles updated successfully",
          "success"
        );
        queryClient.invalidateQueries("permissions");
        queryClient.invalidateQueries("userProfileData");
      })
      .catch((err) => {
        setIsLoading(false);
        openNotification("Update Roles", err.response.data.message, "error");
      });
  };

  const onSwitchChange = (value) => {
    setIsSuperAdmin(value);
  };

  const onSwitchBusinessSuperAdminChange = (value) => {
    setIsBusinessSuperAdmin(value);
  };
  const onSwitchSootheMemberChange = (value) => {
    setIsSootheMember(value);
  };

  const onSwitchViewAllTeamsChange = (value) => {
    setViewAllTeams(value);
  };

  return (
    <>
      <PageHeader
        className="header-boxed"
        title={<Heading as="h1">Roles</Heading>}
      />
      <div className="main">
        <Row gutter={15}>
          <Col lg={19} sm={24}>
            <div className={styles["card-container"]}>
              <>
                <Form
                  form={form}
                  layout="vertical"
                  scrollToFirstError={{
                    behavior: "smooth",
                    block: "center",
                  }}
                >
                  <Row gutter={[32, 32]}>
                    <Col style={{ padding: 0 }} lg={8} md={8} sm={8} xs={24}>
                      <Form.Item name="roles" label="Select Role">
                        <Select
                          className={styles["roles-select"]}
                          placeholder="Select..."
                          showArrow
                          defaultValue={selectedRole}
                          value={selectedRole}
                          onChange={(value) => {
                            setSelectedRole(value);
                          }}
                        >
                          {map(roles, (role, it) => {
                            return (
                              <Select.Option
                                key={`role-${it}`}
                                value={role.id}
                                style={{ padding: "2px" }}
                              >
                                <span> {role.name}</span>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 15,
                      }}
                    >
                      <div className="switch-wrapper">
                        <Switch
                          size="small"
                          checked={isSuperAdmin}
                          onChange={onSwitchChange}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        Add this role to all properties
                      </div>
                    </div>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 15,
                      }}
                    >
                      <div className="switch-wrapper">
                        <Switch
                          size="small"
                          checked={isBusinessSuperAdmin}
                          onChange={onSwitchBusinessSuperAdminChange}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        Add this role to all businesses
                      </div>
                    </div>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 15,
                      }}
                    >
                      <div className="switch-wrapper">
                        <Switch
                          size="small"
                          checked={isSootheMember}
                          onChange={onSwitchSootheMemberChange}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>Is Soothe Member</div>
                    </div>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 40,
                      }}
                    >
                      <div className="switch-wrapper">
                        <Switch
                          size="small"
                          checked={viewAllTeams}
                          onChange={onSwitchViewAllTeamsChange}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>View all teams</div>
                    </div>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <Form.Item
                      colon={false}
                      name={"invite-roles"}
                      label={"This role may invite :"}
                      style={{ width: "100%" }}
                    >
                      {form.setFieldsValue({
                        "invite-roles": inviteRoles,
                      })}
                      <Select
                        className={styles["roles-select"]}
                        placeholder="Select..."
                        mode="multiple"
                        allowClear
                        showArrow
                        value={inviteRoles}
                        onChange={(value) => {
                          setInviteRoles(value);
                        }}
                      >
                        {map(roles, (role, it) => {
                          return (
                            <Select.Option
                              key={`role-${it}`}
                              value={role.id}
                              style={{ padding: "2px" }}
                            >
                              <span> {role.name}</span>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Row>

                  {map(permissionsCategories, (category, it) => {
                    const fieldValue = selectPermissionsOfCategories.find(
                      (per) => per.resource === category.resource
                    )?.values;
                    form.setFieldsValue({ [category.resource]: fieldValue });
                    return (
                      <Row gutter={[32, 32]} key={category.resource + it}>
                        <Form.Item
                          name={category.resource}
                          label={
                            category.resource.charAt(0).toUpperCase() +
                            category.resource.slice(1) +
                            ":"
                          }
                          style={{ width: "100%" }}
                        >
                          <Select
                            className={styles["roles-select"]}
                            placeholder="Select..."
                            mode="multiple"
                            allowClear
                            showArrow
                            value={fieldValue}
                            onChange={(value) => {
                              let newData = structuredClone(
                                selectPermissionsOfCategories
                              );
                              let newCategory = newData.find(
                                (item) => item.resource === category.resource
                              );
                              if (!newCategory) {
                                newData.push({
                                  resource: category.resource,
                                  values: value,
                                });
                              } else {
                                newCategory.values = value;
                              }
                              setSelectPermissionsOfCategories(newData);
                            }}
                          >
                            {map(category.permissions, (permission, it) => {
                              return (
                                <Select.Option
                                  key={permission.id}
                                  value={permission.id}
                                  className={styles["providers-select"]}
                                  style={{ padding: "2px" }}
                                >
                                  <span> {permission.description}</span>
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Row>
                    );
                  })}
                </Form>
                {/* <Restricted to="update" resource="role" fallback={<></>}> */}
                <Footer
                  handleUpdate={onUpdatePermissions}
                  // isEditMode={isEditMode}
                  // current={currentStep}
                  // steps={steps}
                  // prevStep={goPrevious}
                  // handleSaveAsDraft={handleSaveAsDraft}
                  // handleUpdate={handleUpdate}
                  // locationStatus={location?.status}
                  // setChecked={setChecked}
                  // checked={checked}
                  // setLoading={setLoading}
                  loading={isLoading}
                  // permissions={permissions}
                />
                {/* </Restricted> */}
              </>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default (props) => {
  useHotjar();
  const { roles, isLoading: isRolesLoading } = useGetRoleTypes();
  const { permissions, isPermissionsLoading } = useGetPermissionsPerRole(
    roles?.[0]?.id
  );

  if (!roles || !permissions || isPermissionsLoading || isRolesLoading) {
    return (
      <div className="spin" style={{ height: "70vh" }}>
        <Spin />
      </div>
    );
  }
  return <Roles permissions={permissions} roles={roles} />;
};
