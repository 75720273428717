/* eslint-disable import/no-anonymous-default-export */
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import "antd/es/card/style";
import styles from "./VolumeDiscounts.module.scss";
import React, { useCallback, useEffect, useState } from 'react';
import "antd/es/input/style";
import "antd/es/input-number/style";
import { Button, InputNumber, Table, Tooltip } from 'antd';
import useGetBusinessVolumeDiscounts from "../../../../utilities/hooks/useGetBusinessVolumeDiscounts";
import useGetPropertyVolumeDiscounts from "../../../../utilities/hooks/useGetPropertyVolumeDiscounts";
import { concat, filter, find, forEach, get, includes, isEmpty, map, sortBy } from "lodash";
import { SwitchToggle } from "../../../molecules/SwitchToggle";
import FeatherIcon from "feather-icons-react";

const VolumeDiscounts = (props) => {
    let counter = 0;
    const discountsInitialization = () => {
        let tempArray = isEmpty(props.discounts) ?
            filter(sortBy(props.default_discounts, (ell) => Number(ell.monthly_invoice_low)), (el) => !el.is_deleted) :
            sortBy(props.discounts, (ell) => Number(ell.monthly_invoice_low));

        // Ahmad: Check if props.discounts is empty and add is_new: true for appropriate objects
        if (isEmpty(props.discounts)) {
            tempArray = map(tempArray, (el) => ({
                ...el,
                is_new: !el.id, // Ahmad: Set is_new to true if id is missing
            }));
        }
        if (isEmpty(tempArray)) {
            counter += 1;
            tempArray.push({
                monthly_invoice_low: 0,
                monthly_invoice_high: 0,
                discount_percentage: 0,
                is_new: true,
                avg_hours: "",
                avg_shifts: ""
            })
        }
        return map(tempArray, (el) => ({
            ...isEmpty(props.discounts) ? {} : { id: el.id },
            monthly_invoice_low: el.monthly_invoice_low ? Number(el.monthly_invoice_low) : el.monthly_invoice_low,
            monthly_invoice_high: el.monthly_invoice_high ? Number(el.monthly_invoice_high) : el.monthly_invoice_high,
            discount_percentage: el.discount_percentage,
            avg_hours: el.avg_hours,
            avg_shifts: el.avg_shifts,
            discountable_type: props.discountable_type,
            discountable_id: props.discountable_id,
            is_new: el.is_new || false, // Ahmad Ensure is_new is included in the object
        }));
    };

    const isSootheMember = props.isSootheMember;
    const [dataSource, setDataSource] = useState([]);
    const [isCustomizable, setIsCustomizable] = useState(!get(props, "any_toggled_off", false));
    const [copyOfDiscounts, setCopyOfDiscounts] = useState(null);
    const [gaps, setGaps] = useState([]);
    const [switchedEnds, setSwitchedEnds] = useState([]);
    const [redundancies, setRedundancies] = useState([]);
    const checkIfHasErrors = useCallback(() => {
        const filteredDiscounts = filter(copyOfDiscounts, (ell) => (!ell.is_deleted));
        let emptyLowOrHigh = find(filteredDiscounts, (el, it) => {
            return (!Number(el.monthly_invoice_low) || (!Number(el.monthly_invoice_high) && it + 1 !== filteredDiscounts.length))
        })
        if (isCustomizable && isSootheMember && isEmpty(gaps) && isEmpty(switchedEnds) && isEmpty(redundancies) && isEmpty(emptyLowOrHigh)) {
            props.setHasErrors(false)
        } else {
            props.setHasErrors(isCustomizable && isSootheMember)
        }
    }, [copyOfDiscounts, gaps, isCustomizable, isSootheMember, props, redundancies, switchedEnds]);

    useEffect(() => {
        if (!copyOfDiscounts) {
            setCopyOfDiscounts(discountsInitialization())
        }
    }, [props]);
    useEffect(() => {
        checkForSwitchedExtremums();
        checkForOverlap();
        checkForGaps();
        prefillDataSource();
    }, [copyOfDiscounts, isCustomizable]);

    useEffect(() => {
        checkIfHasErrors();
    }, [checkIfHasErrors]);

    useEffect(() => {
        if (isCustomizable) {
            let filteredArr = filter(copyOfDiscounts, (el) => (!el.is_deleted)),
                deletedArr = filter(copyOfDiscounts, (el) => (el.is_deleted));
            props.setVolumeDiscountsData({
                "invoice_discounts": concat(filteredArr, deletedArr),
                any_toggled_off: !isCustomizable
            })
        } else {
            let availableList = props.discounts || props.default_discounts;
            if (availableList?.length === 0) {
                availableList = copyOfDiscounts;
            }
            props.setVolumeDiscountsData({
                "invoice_discounts": map(availableList, (ell) => {
                    return {
                        ...ell,
                        discountable_type: props.discountable_type,
                        discountable_id: props.discountable_id,
                        toggled: false
                    }
                }),
                any_toggled_off: !isCustomizable
            })
        }
        checkIfShouldSave();
    }, [
        copyOfDiscounts,
        gaps,
        isCustomizable,
        redundancies,
        switchedEnds,
    ]);

    const checkForGaps = () => {
        const sortedRanges = filter(copyOfDiscounts, (el) => (!el.is_deleted));
        let gaps = [];
        const ln = sortedRanges.length;

        forEach(sortedRanges, (range, index) => {
            const nextRange = sortedRanges[index + 1];
            if (index + 1 !== ln && !includes(switchedEnds, index + 1) && nextRange) {
                if (nextRange.monthly_invoice_low && parseFloat((range.monthly_invoice_high + 0.01).toFixed(2)) !== parseFloat((nextRange.monthly_invoice_low).toFixed(2))) {
                    gaps.push(index + 1);
                }
            }
        })
        setGaps(gaps);
    }
    const checkForOverlap = () => {
        const sortedRanges = filter(copyOfDiscounts, (el) => (!el.is_deleted));
        let overlaps = [];
        const ln = sortedRanges.length;
        forEach(sortedRanges, (range, index) => {
            const nextRange = sortedRanges[index + 1];
            if (index + 1 !== ln && !includes(switchedEnds, index + 1) && nextRange) {
                if (parseFloat((Number(range.monthly_invoice_high)).toFixed(2)) >= parseFloat((Number(nextRange?.monthly_invoice_low)).toFixed(2)) && !includes(switchedEnds, index + 1)) {
                    overlaps.push(index + 1);
                }
            }
        })
        setRedundancies(overlaps);
    }
    const checkForSwitchedExtremums = () => {
        const sortedRanges = filter(copyOfDiscounts, (el) => (!el.is_deleted));
        let switchedExtremums = [];

        forEach(sortedRanges, (range, index) => {
            if (Number(range.monthly_invoice_low) && ((index + 1 !== sortedRanges.length || (index + 1 === sortedRanges.length && Number(range.monthly_invoice_high))) && parseFloat((Number(range.monthly_invoice_low)).toFixed(2)) > parseFloat((Number(range.monthly_invoice_high)).toFixed(2)))) {
                switchedExtremums.push(index);
            }
        })
        setSwitchedEnds(switchedExtremums);
    }

    const checkIfShouldSave = () => {
        const filteredDiscounts = filter(copyOfDiscounts, (ell) => (!ell.is_deleted));
        let emptyLowOrHigh = find(filteredDiscounts, (el, it) => {
            return (!Number(el.monthly_invoice_low) || (!Number(el.monthly_invoice_high) && it + 1 !== filteredDiscounts.length))
        })
        if ((!isCustomizable && !isCustomizable !== props.any_toggled_off) || (isCustomizable && isEmpty(gaps) && isEmpty(switchedEnds) && isEmpty(redundancies) && isEmpty(emptyLowOrHigh))) {
            props.setShouldSaveVD(true)
        } else {
            props.setShouldSaveVD(false)
        }
    }
    const expandedRowRender = (record) => {
        const filteredDiscounts = filter(copyOfDiscounts, (ell) => (!ell.is_deleted));
        if (includes(switchedEnds, record.index)) {
            return <p style={{ color: 'red' }}>"Monthly Invoice: Low" should be less than "Monthly Invoice: High".</p>
        }
        if (includes(redundancies, record.index)) {
            return <p style={{ color: 'red' }}>Difference between "Monthly Invoice: High" of this row and "Monthly Invoice: Low" of the next row should be exactly 0.01 cents.</p>
        }
        if (includes(gaps, record.index)) {
            return <p style={{ color: 'red' }}>"Monthly Invoice: Low" should be {parseFloat((Number(get(filteredDiscounts, `${record.index - 1}.monthly_invoice_high`, 0)) + 0.01).toFixed(2))} to cover all ranges.</p>
        }
        return null;
    };
    const monthInvLowUI = (discount, index, editable = true) => {
        return isSootheMember && editable ?
            <span><span style={{ fontWeight: "500", marginRight: "16px" }}>{index + 1}.</span>
                $ <InputNumber min={0.01} pattern="[0-9\.]+" className="input-style-light w-121" value={discount.monthly_invoice_low} onChange={(e) => handleChange(index, e, "monthly_invoice_low")} />
            </span>
            : <span><span style={{ fontWeight: 500 }}>{index + 1}.</span> $ {discount.monthly_invoice_low}</span>
    }
    const monthInvHighUI = (discount, index, editable = true, len) => {
        return isSootheMember && editable ?
            <span>$ <InputNumber min={index !== len - 1 ? 0.01 : null} pattern="[0-9\.]+" className="input-style-light w-121" value={index !== len - 1 ? discount.monthly_invoice_high || 0: discount.monthly_invoice_high} onChange={(e) => handleChange(index, e, "monthly_invoice_high")} /></span>
            : <span>$ {discount.monthly_invoice_high}</span>
    }
    const percDiscUI = (discount, index, editable = true) => {
        return isSootheMember && editable ?
            <span>
                <InputNumber pattern="[0-9\.]+" className="input-style-light w-64" defaultValue={discount.discount_percentage} onChange={(e) => handleChange(index, e, "discount_percentage")} /> %
            </span>
            : <span>{discount.discount_percentage} %</span>
    }
    const handleDelete = (index) => {
        let tempArray = filter(copyOfDiscounts, (el) => (!el.is_deleted)),
            deletedArr = filter(copyOfDiscounts, (el) => (el.is_deleted));

        let objToBeDeleted = tempArray[index];
        objToBeDeleted.is_deleted = true;
        if (!objToBeDeleted.is_new) {
            deletedArr.push(objToBeDeleted);
        }
        tempArray.splice(index, 1);

        let newCD = concat(tempArray, deletedArr);
        setCopyOfDiscounts(newCD);
    };

    const handleAdd = (index) => {
        counter += 1;
        let filteredArr = filter(copyOfDiscounts, (el) => (!el.is_deleted)),
            deletedArr = filter(copyOfDiscounts, (el) => (el.is_deleted));
        let lowInvoiceAmount = parseFloat((Number(get(filteredArr, `${index}.monthly_invoice_high`, "0")) + 0.01).toFixed(2)),
            highInvoiceAmount = 0;
        if (index + 1 !== filteredArr.length) {
            highInvoiceAmount = parseFloat((Number(get(filteredArr, `${index + 1}.monthly_invoice_low`, "0")) - 0.01).toFixed(2));
            if (highInvoiceAmount < 0 || highInvoiceAmount < lowInvoiceAmount) {
                highInvoiceAmount = lowInvoiceAmount;
            }
        } else {
            highInvoiceAmount = null;
        }
        filteredArr.splice(index + 1, 0, {
            monthly_invoice_low: lowInvoiceAmount,
            monthly_invoice_high: highInvoiceAmount,
            discount_percentage: 0,
            avg_hours: "",
            avg_shifts: "",
            is_new: true,
            is_deleted: false,
            discountable_type: props.discountable_type,
            discountable_id: props.discountable_id
        })
        let newCD = concat(filteredArr, deletedArr);
        setCopyOfDiscounts(newCD);
    };
    const handleChange = (index, value, fieldName) => {
        let tempArray = filter(copyOfDiscounts, (el) => (!el.is_deleted)),
            deletedArr = filter(copyOfDiscounts, (el) => (el.is_deleted));
        if (fieldName === "monthly_invoice_low" && !value) {
            tempArray[index][fieldName] = 0.01;
        } else {
            tempArray[index][fieldName] = value;
        }
        let newCD = concat(tempArray, deletedArr);
        setCopyOfDiscounts(newCD);
    }
    const columns = [
        {
            title: 'Monthly Invoice: Low',
            dataIndex: 'monthly_invoice_low',
            width: props.discountable_type !== "business" && '24%',
        },
        {
            title: 'Monthly Invoice: High',
            dataIndex: 'monthly_invoice_high',
            width: props.discountable_type !== "business" && '23%',
        },
        {
            title: 'Discount %',
            dataIndex: 'discount_percentage',
            width: props.discountable_type !== "business" && '13%',
            align: props.discountable_type !== "business" && "center"
        },
        ...props.discountable_type === "business" ? [] : [{
            title: <span className="flex-spaced" style={{ justifyContent: "space-evenly" }}>Avg Hours <Tooltip placement="bottom" overlayInnerStyle={{
                borderRadius: "10px",
                color: "white"
            }}
                title="Average hours are based off hourly rates entered in “Provider Payout”." color="#252C3F"><FeatherIcon size="20" icon="info" /></Tooltip></span>,
            dataIndex: 'avg_hours',
            width: '15%',
            align: "center"
        },
        {
            title: <span className="flex-spaced" style={{ justifyContent: "space-evenly" }}>Avg Shifts <Tooltip placement="bottom" overlayInnerStyle={{
                borderRadius: "10px",
                color: "white"
            }}
                title="Average shifts are based off of 4 hour shifts." color="#252C3F"
                bac
            ><FeatherIcon size="20" icon="info" /></Tooltip></span>,
            dataIndex: 'avg_shifts',
            width: '15%',
            align: "center"
        }],
        {
            dataIndex: 'operation',
            width: '10%',
        }
    ];
    const prefillDataSource = () => {
        let dataList = [];
        if (!isEmpty(copyOfDiscounts)) {
            let filteredArr = filter(copyOfDiscounts, (el) => (!el.is_deleted));
            map(filteredArr, (discount, index) => {
                let key = discount.id || `new-item-${index}-${counter}-${filteredArr.length}`;
                dataList.push({
                    key,
                    index: index,
                    monthly_invoice_low: monthInvLowUI(discount, index),
                    monthly_invoice_high: monthInvHighUI(discount, index, true, filteredArr?.length || 0),
                    discount_percentage: percDiscUI(discount, index),
                    avg_hours: discount.avg_hours,
                    avg_shifts: discount.avg_shifts ? `${discount.avg_shifts.low || ""} - ${discount.avg_shifts.high || ""}` : "",
                    operation: !isSootheMember || !isCustomizable ? null :
                        <div className="flex-around">{filteredArr?.length > 1 ? (
                            <Button
                                id={`test-remove-${key}`}
                                shape="circle"
                                size="small"
                                style={{
                                    borderColor: "#586B94",
                                    color: "#586B94",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    borderWidth: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => handleDelete(index)}
                            >
                                -
                            </Button>
                        ) : null}

                            <Button
                                id={`test-add-${key}`}
                                shape="circle"
                                size="small"
                                style={{
                                    borderColor: "#586B94",
                                    color: "#586B94",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    borderWidth: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => handleAdd(index)}
                            >
                                +
                            </Button>
                        </div>
                })
            })
        }
        setDataSource(dataList)
    }

    const tableCard = () => {
        return (<Table
            className="table-responsive"
            rowClassName={(_, index) => {
                if (includes(switchedEnds, index) || includes(redundancies, index) || includes(gaps, index)) {
                    return `${styles['error-row']}`;
                }
                return index % 2 === 0 ? `${styles['gray-row']}` : `${styles['white-row']}`;
            }}
            dataSource={dataSource}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ y: 500 }}
            expandable={{
                expandedRowRender,
                rowExpandable: record => includes(gaps, record.index) || includes(switchedEnds, record.index) || includes(redundancies, record.index),
                expandIcon: ({ expanded, onExpand, record }) =>
                    !includes(gaps, record.index) && !includes(switchedEnds, record.index) && !includes(redundancies, record.index) ?
                        null :
                        expanded && (includes(gaps, record.index) || includes(switchedEnds, record.index) || includes(redundancies, record.index)) ? (
                            <FeatherIcon size="20" color="red" style={{ cursor: "pointer" }} icon="x-octagon" onClick={e => onExpand(record, e)} />
                        ) : (
                            <FeatherIcon size="20" color="red" style={{ cursor: "pointer" }} icon="alert-octagon" onClick={e => onExpand(record, e)} />
                        )
            }}
        />)
    }
    return (<div>
        <div className="flex-spaced align-items-center">
            <div className="ptb-16">
                <div className="title-16 flex-spaced" style={{ justifyContent: "start" }} >Volume-Based Discounts<Tooltip placement="bottom" className="ml-8" overlayInnerStyle={{
                    borderRadius: "10px",
                    color: "white"
                }}
                    title="Discounts applied to entire monthly invoice." color="#252C3F"><FeatherIcon size="20" icon="info" /></Tooltip></div>
                <div className="subtitle-14">Based off the hourly rate entered in the “Provider Payout” tab.</div>
            </div>
            {isSootheMember ? <SwitchToggle
                value={isCustomizable}
                copy="Toggle"
                onChange={(e) => {
                    let val = e;
                    setIsCustomizable(val);
                }}
                defaultChecked={isCustomizable}
            /> : null}
        </div>
        {isCustomizable ? <div style={{ border: "1px solid #e2e2e2", borderRadius: "16px", padding: "16px" }}>
            {tableCard()}
        </div> : null}
    </div>)
};
export default (props) => {
    const {
        businessId,
        volumeDiscountsData,
        locationId
    } = props;
    if (businessId) {
        const { discounts: businessVDiscounts, default_discounts: businessDefaultDiscounts, isLoading: isBusinessVDLoading, any_toggled_off: businessToggle } =
            useGetBusinessVolumeDiscounts({
                id: businessId,
                type: volumeDiscountsData?.billingType,
            });
        if (isBusinessVDLoading) {
            return <Skeleton active />;
        }
        // if businessId available means the type is business
        return <VolumeDiscounts {...props} discounts={businessVDiscounts}
            discountable_type={"business"} discountable_id={businessId}
            any_toggled_off={businessToggle} default_discounts={businessDefaultDiscounts} isSootheMember={props.isSootheMember}
        />;
    }
    const { discounts: propertyVDiscounts, default_discounts: propertyDefaultDiscounts, isLoading: isPropertyVDLoading, any_toggled_off: propertyToggle } =
        useGetPropertyVolumeDiscounts(props.locationId);

    if (isPropertyVDLoading) {
        return <Skeleton active />;
    }
    return <VolumeDiscounts {...props} discounts={propertyVDiscounts}
        discountable_type={"location"} discountable_id={locationId}
        any_toggled_off={propertyToggle} default_discounts={propertyDefaultDiscounts} isSootheMember={props.isSootheMember}
    />;
};
