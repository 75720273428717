import React from "react";
import FeatherIcon from "feather-icons-react";

import { Popover } from "../../popup/popup";
import { DateRangePickerOne } from "../../datePicker/datePicker";
import { Button } from "../buttons";
import LinkTo from "../../link";
import styles from "../styles.module.scss"

const CalendarButtonPageHeader = ({
  state,
  handleRangeChange,
  onClick,
  onCancel,
  visible,
  setVisible,
  datePicker,
  isDateDisplayed = true,
  handleActiveTab,
  className,
  calendarClassName,
  placement,
}) => {
  const { dateRangePicker } = state;
  const start = dateRangePicker.selection.startDate.toString().split(" ");
  const end = dateRangePicker.selection.endDate.toString().split(" ");

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const content = (
    <DateRangePickerOne
      state={state}
      handleRangeChange={handleRangeChange}
      onClick={onClick}
      onCancel={onCancel}
      datePicker={datePicker}
      className={calendarClassName}
    />
  );

  return (
    <Popover
      placement={placement ? placement : "bottomRight"}
      title="Search by Calendar"
      content={content}
      action="click"
      visible={visible}
      handleVisibleChange={handleVisibleChange}
    >
      {isDateDisplayed ? (
        <Button
          size="small"
          onClick={() => setVisible(!visible)}
          className={className}
        >
          <div className={styles["calendar-button"]}>
            <FeatherIcon size={16} icon="calendar" />
            {datePicker ? (
              <p>{`${start[1]} ${start[2]} ${start[3]}`}</p>
            ) : (
              <p>{`${start[1]} ${start[2]} ${start[3]} - ${end[1]} ${end[2]} ${end[3]}`}</p>
            )}
            <FeatherIcon
              className={styles["down-icon"]}
              size={16}
              icon="chevron-down"
            />
          </div>
        </Button>
      ) : (
        <LinkTo
          onClick={() => handleActiveTab("custom")}
          to={"#"}
          text={"Custom"}
        />
      )}
    </Popover>
  );
};
export { CalendarButtonPageHeader };
