import get from "lodash/get";
import { useQuery } from "react-query";
import business from "../api/businesses";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetBusinessBilling = ({ id, status, type }) => {
  const { isLogin } = useTryAutoLogin();
  const { permissions } = usePermissions();

  const { data, isError, isLoading } = useQuery(
    ["businessBilling", id],
    business.getBilling,
    {
      enabled:
        !!isLogin &&
        !!id &&
        !!status &&
        !!(type === "byBusiness") &&
        !!permissions?.business?.includes("billing"),
      cacheTime: 0,
      staleTime: 0,
    }
  );
  const billing = get(data, "data.data.result");
  return {
    billing,
    isError,
    isLoading,
  };
};

export default useGetBusinessBilling;
