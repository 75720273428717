import React from "react";
import PropTypes from "prop-types";
import styles from "./pageHeaders.module.scss";
import PageHeader from "antd/es/page-header";

const CustomPageHeader = (props) => {
  const { title, subTitle, routes, buttons, ghost, bgColor, className } = props;
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor || "#F4F5F7",
        }}
      >
        <PageHeader
          style={{
            backgroundColor: "rgb(244, 245, 247)",
          }}
          // onBack={() => window.history.back()}
          className={`${styles["wrapper"]} ${className}`}
          title={title}
          subTitle={subTitle}
          breadcrumb={routes && { routes }}
          extra={buttons}
          ghost={ghost}
        />
      </div>
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  ghost: PropTypes.bool,
};

export { CustomPageHeader as PageHeader };
