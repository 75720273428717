import { usePermissions } from "./PermissionsProvider";

const Restricted = ({ both = false, to, resource, children, fallback }) => {
  const { permissions, businessPermissions } = usePermissions() || {};

  const allowedOnBusiness =
    businessPermissions?.[resource]?.includes(to) || false;
  const allowedOnUser = permissions?.[resource]?.includes(to) || false;

  if (both && allowedOnBusiness && allowedOnUser) {
    return children;
  }

  if (!both && allowedOnUser) {
    return children;
  }

  return fallback;
};

export default Restricted;
