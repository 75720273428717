import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { LoadingOutlined } from "@ant-design/icons";
import Upload from "antd/es/upload";
import "antd/es/upload/style";
import message from "antd/es/message";
import "antd/es/message/style";

import UploadInputStyle from "../../atoms/inputs/UploadInputStyle";
import Heading from "../../atoms/heading/heading";

import { generateUrl, uploadToS3 } from "../../../services/aws";

const { Dragger } = Upload;

const acceptedFileTypes = [
  "application/pdf", // PDF
  "application/msword", // DOC
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "application/vnd.ms-excel", // XLS
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
  "image/png", // PNG
  "image/jpeg", // JPEG
  "text/csv", // CSV
  "text/xml", // XML
];

const FileUpload = ({
  onUploadSuccess,
  value,
  onRemoveSuccess,
  disabled = false,
  bkgroundColor,
  uploadPDFFileOnly = true,
  id,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: "1",
          name: value,
          status: "done",
          url: value,
        },
      ]);
    }
  }, [value]);

  const customRequest = async ({ file, onSuccess }) => {
    setIsLoading(true);
    const fileExtension = file?.name?.split(".").pop();
    const response = await generateUrl().then((res) => res.data);
    if (response.data && response.data.url) {
      const url = response.data.url;
      await uploadToS3(url, file);
      const fileUrl = url.split("?")[0];
      onSuccess(fileUrl, file);
      onUploadSuccess(fileUrl, fileExtension);
      setIsLoading(false);
      setFileList([
        {
          uid: "1",
          name: fileUrl,
          status: "done",
          url: fileUrl,
        },
      ]);
    }
  };

  const onRemove = () => {
    setFileList([]);
    onRemoveSuccess();
  };

  const fileUploadProps = {
    name: "file",
    beforeUpload: async (file) => {
      const fileType = file.type.toLowerCase();

      if (uploadPDFFileOnly && fileType === "application/pdf") {
        return true;
      } else if (!uploadPDFFileOnly && acceptedFileTypes.includes(fileType)) {
        return true;
      } else {
        let msg = "";
        msg = uploadPDFFileOnly
          ? "You can only upload PDF file"
          : "Invalid file type. Please upload a PDF, DOC, DOCX, XLS, XLSX, PNG, JPEG, CSV, XML file.";
        message.error(msg);
        return false;
      }
    },
    maxCount: 1,
    customRequest,
    onRemove,
  };

  return (
    <UploadInputStyle bkgroundColor={bkgroundColor}>
      <Dragger
        id={id}
        {...fileUploadProps}
        disabled={disabled}
        fileList={fileList}
        accept={
          uploadPDFFileOnly ? "application/pdf" : acceptedFileTypes.join(", ")
        }
      >
        <p className="ant-upload-drag-icon">
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <FeatherIcon icon="upload" size={50} />
          )}
        </p>
        <Heading as="h4" className="ant-upload-text">
          Drag and drop file
        </Heading>
        <p className="ant-upload-hint">
          or <span>Browse</span> to choose a file
        </p>
      </Dragger>
    </UploadInputStyle>
  );
};

export default FileUpload;
