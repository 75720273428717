import { debounce } from "lodash";
import React, { useMemo, useState } from "react";

import useGetBusinessProperties from "../../../../../utilities/hooks/useGetBusinessProperties";
import Filters from "../../Filters";
import PropertiesTable from "../PropertiesTable";
import styles from "./Properties.module.scss";

function Properties({ business }) {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [sorting, setSorting] = useState();

  const { properties, isLoading, totalItems, refetch } =
    useGetBusinessProperties({
      businessId: business.id,
      page,
      per_page: perPage,
      search,
      sorting,
    });

  const onSorting = (selectedItems) =>
    setSorting({ field: selectedItems, type: "ascending" });

  const onPageChange = (newPage) => setPage(newPage - 1);

  const onPageSizeChange = (current, newSize) => {
    setPage(0);
    setPerPage(newSize);
  };

  const searchChangeHandler = (searchUp) => {
    setSearch(searchUp);
    setPage(0);
  };

  const debouncedSearchChangeHandler = useMemo(
    () => debounce(searchChangeHandler, 500),
    []
  );

  const sortingOptions = [
    {
      id: 1,
      value: "name",
      title: "Name",
    },
    {
      id: 2,
      value: "providers",
      title: "Providers",
    },
    {
      id: 3,
      value: "status",
      title: "Status",
    },
  ];

  return (
    <div className={styles["properties-container"]}>
      <Filters
        onSorting={onSorting}
        onSearch={debouncedSearchChangeHandler}
        dataSource={properties}
        placeholder={`Search ${business.name} Properties`}
        sortingOptions={sortingOptions}
      />
      <PropertiesTable
        properties={properties}
        isLoading={isLoading}
        totalItems={totalItems}
        page={page}
        perPage={perPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        business={business}
        refetch={refetch}
      />
    </div>
  );
}

export default Properties;
