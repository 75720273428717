import React from "react";
import Select from "antd/es/select";
import "antd/es/select/style";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import styles from "./styles.module.scss";

export const LocationsDropdown = ({
  name = "locations",
  value,
  onChange,
  style,
  type,
  disabled,
  placeholder,
  isAppointmentsLocations = false,
  locations,
  defaultValue,
  showAllLocationsOption = true,
}) => {
  const getSelectedValuesAndOptions = (values, options) => {
    if (values[0] === "all" && values.length !== 1) {
      return {
        selectedValues: values.slice(1),
        selectedOptions: options.slice(1),
      };
    }
    if (values.includes("all")) {
      return {
        selectedValues: ["all"],
        selectedOptions: [undefined],
      };
    }
    return { selectedValues: values, selectedOptions: options };
  };
  if (type === "main-dropdown") {
    return (
      <div
        id="locations-dropdown"
        className={styles["main-dropdown-container"]}
      >
        <Select
          defaultValue="all"
          className={styles["main-dropdown"]}
          style={style}
          type={type}
          name={name}
          value={value}
          onChange={(val, option) => {
            if (!isEmpty(option)) {
              if (isAppointmentsLocations) {
                onChange(option);
              } else {
                onChange(val, option);
              }
            }
          }}
          showSearch
          showArrow
          placeholder="All Properties"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children.toLowerCase() ?? "").includes(input.toLowerCase())
          }
        >
          {showAllLocationsOption && (
            <Select.Option id="all" title="all" value="all">
              All Properties
            </Select.Option>
          )}
          {map(locations, (location, it) => {
            return (
              <Select.Option
                id={`location-${location.id}`}
                key={`list-${it}`}
                title={location.name}
                value={location.id}
                status={location.status}
              >
                {location.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  } else {
    return (
      <Select
        disabled={disabled}
        className={styles["modal-dropdown"]}
        style={style}
        type={type}
        name={name}
        allowClear={true}
        mode="multiple"
        value={value}
        onChange={(values, options) => {
          const { selectedValues, selectedOptions } =
            getSelectedValuesAndOptions(values, options);
          if (!isEmpty(selectedOptions)) {
            if (isAppointmentsLocations) {
              return onChange(map(selectedOptions, (opt) => opt.title));
            }
          }
          return onChange(
            selectedValues,
            map(selectedOptions, (opt) => opt?.title)
          );
        }}
        showSearch
        showArrow
        getPopupContainer={(trigger) => trigger.parentNode}
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option key="all" value="all">
          All Properties
        </Select.Option>
        {map(locations, (location, it) => {
          return (
            <Select.Option
              key={`list-${it}`}
              value={location.id}
              title={location.name}
            >
              {location.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
};
