import React from "react";
import { Link, useLocation } from "react-router-dom";
import "antd/es/tag/style";
import FeatherIcon from "feather-icons-react";
import Menu from "antd/es/menu";
import "antd/es/tooltip/style";
import moment from "moment-timezone";
import { ProjectCard } from "./style";
import { Cards } from "../../../atoms/cards/Cards";
import { textRefactor } from "../../../../utilities/utilities";
import { DropdownBase } from "../../../atoms";
import { Col, Row } from "antd";
import { includes } from "lodash";

function GridCard({
  value,
  location,
  setIsDeleteTemplateModalOpen,
  setTemplateId,
  providersInLocation,
  setShiftTemplateData,
}) {
  const {
    id,
    name,
    updatedAt,
    createdAt,
    shiftType,
    modified_by,
    created_by,
    data: { starttime, endtime, providers, number_providers },
  } = value;
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");

  let existingProviders = providersInLocation.filter((pro) =>
    providers?.includes(pro.provider_id)
  ).length;

  return (
    <ProjectCard>
      <Cards headless>
        <div className="project-top">
          <div className="project-title">
            <h1>
              <span
                className={`status ${shiftType === "Event"
                  ? "error"
                  : shiftType === "Massage"
                    ? "warning"
                    : shiftType === "Spa" || shiftType === "Corporate" || shiftType === "Corporate Wellness"
                      ? "in-progress"
                      : "disabled"
                  }`}
              >
                {shiftType}
              </span>
              <p className="project-desc">{textRefactor(name, 13)}</p>
            </h1>
            <DropdownBase
              className="actions-dropdown"
              classNameC="actions-dropdown-content"
              action={["click"]}
              content={
                <Menu
                  items={[
                    {
                      label: (
                        <Link
                          to={{
                            pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${location.id}`,
                          }}
                          state={{
                            templateId: id,
                          }}
                        >
                          <FeatherIcon icon="arrow-up-right" size={18} /> Use
                          Template
                        </Link>
                      ),
                      key: "1",
                    },
                    {
                      label: (
                        <Link
                          to={"#"}
                          onClick={() => {
                            setTemplateId(id);
                            setShiftTemplateData({
                              isModalOpen: true,
                              isRequired: true,
                            });
                          }}
                        >
                          <FeatherIcon icon="edit-3" size={18} /> Rename
                        </Link>
                      ),
                      key: "2",
                    },
                    {
                      label: (
                        <Link
                          to={{
                            pathname: `/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${location.id}`,
                          }}
                          state={{
                            templateId: id,
                            mode: "edit",
                          }}
                        >
                          <FeatherIcon icon="edit-2" size={18} /> Edit
                        </Link>
                      ),
                      key: "3",
                    },
                    {
                      label: (
                        <Link
                          to="#"
                          onClick={() => {
                            setTemplateId(id);
                            setIsDeleteTemplateModalOpen(true);
                          }}
                        >
                          <FeatherIcon icon="trash-2" size={18} /> Delete
                        </Link>
                      ),
                      key: "4",
                    },
                  ]}
                />
              }
            >
              <Link to="#">
                <FeatherIcon icon="more-horizontal" size={18} />
              </Link>
            </DropdownBase>
          </div>
          <Row className="project-timing">
            <Col md={13} xs={24}>
              <span>Start Time</span>
              <strong> {moment(starttime, "h:mm").format("h:mm A")}</strong>
            </Col>
            <Col md={11} xs={24}>
              <span>End Time</span>
              <strong>{moment(endtime, "h:mm").format("h:mm A")}</strong>
            </Col>
          </Row>
          <Row className="project-progress">
            <Col md={24}>Providers needed : {`${number_providers}`}</Col>
          </Row>
          <Row className="project-progress">
            <Col md={24}>Offers sent : {`${existingProviders}`}</Col>
          </Row>
        </div>
        <div className="project-bottom">
          <Row className="project-footer">
            <Col md={12}>
              <p>
                Last Modified {moment(updatedAt, "h:mm").format("h:mm A")} on{" "}
                {moment(updatedAt).format("YY/MM/DD")}
              </p>
              <p>
                by <span>{`${modified_by.fname} ${modified_by.lname}`}</span>
              </p>
            </Col>
            <Col md={11}>
              <p>
                Created {moment(createdAt, "h:mm").format("h:mm A")} on{" "}
                {moment(createdAt).format("YY/MM/DD")}
              </p>
              <p>
                by <span>{`${created_by.fname} ${created_by.lname}`}</span>
              </p>
            </Col>
          </Row>
        </div>
      </Cards>
    </ProjectCard>
  );
}

export default GridCard;
