import React from "react";
import PropTypes from "prop-types";
import styles from "./heading.module.scss";

const Heading = (props) => {
  const { as, children, className, id } = props;
  const headerType = as ? parseInt(as.slice(1)) : 1;
  return React.createElement(
    `h${headerType}`,
    { className: `${styles[`H${headerType}`]} ${className}`, id },
    children
  );
};

Heading.defaultProps = {
  as: "h1",
};

Heading.propTypes = {
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  id: PropTypes.string,
};

export default Heading;
