import React from "react";
import StepsAntd from "antd/es/steps";
import "antd/es/steps/style";
import styles from "./style.module.scss";

const { Step } = StepsAntd;

function Steps(props) {
  const { current, labelPlacement, steps } = props;

  return (
    <div className={styles["steps"]}>
      <StepsAntd responsive={true} current={current} labelPlacement={labelPlacement}>
        {steps.map((item, index) => (
          <Step key={index} title={item} />
        ))}
      </StepsAntd>
    </div>
  );
}

export default Steps;
