import React from "react";
import openNotification from "./openNotification";
import { Empty } from "antd";

function DataStateHandler({
  isLoading,
  loadingComponent,
  error,
  isError,
  data,
  noData,
  style,
  children,
}) {
  let err = error?.response?.data?.message;
  let status = error?.response?.status;
  return (
    <>
      {isLoading ? (
        <>{loadingComponent}</>
      ) : !isLoading && (isError || err) ? (
        (err &&
          status !== 403 &&
          status !== 401 &&
          openNotification("Error", err, "error"),
        (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...style,
            }}
          >
            Error occurred
          </div>
        ))
      ) : !isLoading && !isError && !data ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default DataStateHandler;
