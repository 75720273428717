import React, { useReducer, useState } from "react";
import Menu from "antd/es/menu";
import "antd/es/menu/style";
import FeatherIcon from "feather-icons-react";

import styles from "./Files.module.scss";
import FilesTable from "../FilesTable";
import { DropdownBase } from "../../../../atoms";
import { Button } from "../../../../atoms/buttons/buttons";
import Restricted from "../../../../../PermissionsProvider/Restricted";
import { Link } from "react-router-dom";
import UploadFileModal from "../../../UploadFileModal";
import useGetBusinessFiles from "../../../../../utilities/hooks/useGetBusinessFiles";
import AddLinkModal from "../../../AddLinkModal";
import useGetLocations from "../../../../../utilities/hooks/useGetLocations";

function Files({ business }) {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [uploadFileModalState, setUploadFileModalState] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isOpen: false,
      mode: "add",
      fileId: null,
    }
  );
  const [addLinkModalState, setAddLinkModalState] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isOpen: false,
      mode: "add",
      fileId: null,
    }
  );

  const { files, isLoading, totalItems, refetch } = useGetBusinessFiles({
    businessId: business.id,
    page,
    per_page: perPage,
    // sorting,
  });

  const { locations } = useGetLocations(business?.id);

  const onPageChange = (newPage) => setPage(newPage - 1);

  const onPageSizeChange = (current, newSize) => {
    setPage(0);
    setPerPage(newSize);
  };

  const items = [
    {
      key: "1",
      label: (
        <Link
          id="business_upload-file"
          to="#"
          onClick={() => setUploadFileModalState({ isOpen: true })}
        >
          <FeatherIcon icon="upload" size={18} />
          Upload file
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          id="business_add-link"
          to="#"
          onClick={() => setAddLinkModalState({ isOpen: true })}
        >
          <FeatherIcon icon="link-2" size={18} />
          Add link
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className={styles["files-container"]}>
        <Restricted
          key="1"
          to="create_files"
          resource="business"
          fallback={<></>}
        >
          <div className={styles["btn-container"]}>
            <DropdownBase
              key="button"
              className="actions-dropdown"
              classNameC="actions-dropdown-content"
              action={["click"]}
              content={<Menu items={items}></Menu>}
            >
              <Button
                className={styles["file-btn"]}
                id="business_new-file"
                size="default"
                type="lightBlue"
                key="link"
              >
                <FeatherIcon icon="plus" size={20} /> New File
              </Button>
            </DropdownBase>
          </div>
        </Restricted>
        <FilesTable
          files={files}
          isLoading={isLoading}
          totalItems={totalItems}
          page={page}
          perPage={perPage}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          business={business}
          refetch={refetch}
          setAddLinkModalState={setAddLinkModalState}
          setUploadFileModalState={setUploadFileModalState}
          locations={locations}
        />
      </div>
      {uploadFileModalState.isOpen && (
        <UploadFileModal
          isOpen={uploadFileModalState.isOpen}
          mode={uploadFileModalState.mode}
          fileId={uploadFileModalState.fileId}
          onExit={() =>
            setUploadFileModalState({
              isOpen: false,
              mode: "add",
              fileId: null,
            })
          }
          title="Upload file"
          businessId={business?.id}
        />
      )}
      {addLinkModalState.isOpen && (
        <AddLinkModal
          isOpen={addLinkModalState.isOpen}
          mode={addLinkModalState.mode}
          fileId={addLinkModalState.fileId}
          onExit={() =>
            setAddLinkModalState({ mode: "add", fileId: null, isOpen: false })
          }
          title="Add link"
          businessId={business?.id}
        />
      )}
    </>
  );
}

export default Files;
