import get from "lodash/get";

import { useQuery } from "react-query";
import location from "../api/location";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetLocations = (businessId, status = "live", include_staffing = false, include_corporate = false) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["locations", businessId, status, include_staffing, include_corporate],
    () => location.getAllLocations(businessId, status, include_staffing, include_corporate),
    {
      enabled: !!isLogin && !!businessId,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );

  const locations = get(data, "data.data.locations");

  return { locations, isError, error, isLoading, refetch };
};

export default useGetLocations;
