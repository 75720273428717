import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";

export const DropdownBase = (props) => {
  const {
    content,
    placement,
    title,
    action,
    children,
    style,
    className,
    classNameC,
    onClick,
    disabled,
  } = props;

  return (
    <Dropdown
      className={className}
      overlayClassName={classNameC}
      style={style}
      placement={placement}
      title={title}
      overlay={<div>{content}</div>}
      trigger={action}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Dropdown>
  );
};

const content = (
  <>
    <Link to="#">
      <span>Export to CSV</span>
    </Link>
    <Link to="#">
      <span>Export to XML</span>
    </Link>
    <Link to="#">
      <span>Export to Drive</span>
    </Link>
  </>
);

DropdownBase.defaultProps = {
  action: ["click"],
  placement: "bottomRight",
  content,
  style: {},
};

DropdownBase.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.array,
  content: PropTypes.node,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};
