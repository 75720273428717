import get from "lodash/get";
import { useQuery } from "react-query";
import location from "../api/location";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetPropertyDiscounts = (id) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading } = useQuery(
    ["propertyDiscounts", id],
    location.getDisconts,
    {
      enabled: !!isLogin && !!id,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const discounts = get(data, "data.data.result");
  return { discounts, isError, isLoading };
};

export default useGetPropertyDiscounts;
