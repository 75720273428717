import get from "lodash/get";
import { useQuery } from "react-query";
import notification from "../api/notifications";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetNotificationsTableStruct = (notificationsInfo) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, error } = useQuery(
    ["TableStructure", notificationsInfo],
    notification.getNotificationsTableStructure,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const tableStructure = get(data, "data.data");

  return { tableStructure, isError, isLoading, error };
};

export default useGetNotificationsTableStruct;
