import React from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Card from "antd/es/card";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow } from "../../atoms";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import styles from "./style.module.scss";

function CancelAppointmentModal({ isOpen, onCancel, onExit, appointmentId }) {
  const { data: selectedAppointment, isLoading, should_show_warning, able_to_adjust } = useGetAppointmentsDetails({
    appointmentId,
  });

  return (
    <Modal
      wrapClassName={styles["modal-container"]}
      title={
        !isLoading &&
        `Are you sure you want to cancel Appointment #${selectedAppointment?.appointment_number}?`
      }
      visible={isOpen}
      footer={null}
      onCancel={onExit}
      centered
    >
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 9 }} />
      ) : (
        <div className="view-modal-body">
          <div className="view-modal-body-card" style={{ marginRight: 0 }}>
            <Card
              headless="true"
              style={{
                backgroundColor:
                  selectedAppointment.status === "pending" &&
                  selectedAppointment.needs_attention
                    ? "#FFEFED"
                    : "#FAFBFF",
                color:
                  selectedAppointment.status === "pending" &&
                  selectedAppointment.needs_attention
                    ? "#C11500"
                    : "#272B41",
                marginBottom: "32px",
                border: "none",
              }}
            >
              <p
                className="view-modal_section-title"
                style={{
                  color:
                    selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}
              >
                {selectedAppointment?.cart_products[0].client_name}’s{" "}
                {selectedAppointment?.cart_products[0].service_type}
              </p>
              <FlexRow>
                <FeatherIcon
                  icon="calendar"
                  size={16}
                  color={
                    selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94"
                  }
                  className="mr-10"
                />
                <p>
                  {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format(
                    "dddd, MMM, D"
                  )}{" "}
                  at {selectedAppointment.time.display}
                </p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="map-pin"
                  size={16}
                  color={
                    selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94"
                  }
                  className="mr-10"
                />
                <p>
                  {`${selectedAppointment.address.address_line_1}, ${selectedAppointment.address.city}`}
                  {selectedAppointment.address.hotel_room_number &&
                    ` • Room ${selectedAppointment.address.hotel_room_number}`}
                </p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="shopping-cart"
                  size={16}
                  color={
                    selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94"
                  }
                  className="mr-10"
                />
                <p>
                  {selectedAppointment.cart_products[0].service_type}
                  {selectedAppointment.cart_products[0].provider_name &&
                    ` with ${selectedAppointment.cart_products[0].provider_name}`}
                </p>
              </FlexRow>
              {selectedAppointment.booked_by && (
                <FlexRow>
                  <FeatherIcon
                    icon="user"
                    size={16}
                    color={
                      selectedAppointment.status === "pending" &&
                      selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94"
                    }
                    className="mr-10"
                  />
                  <p>
                    Booked by {selectedAppointment.booked_by} on{" "}
                    {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format(
                      "MM/DD/YY"
                    )}
                  </p>
                </FlexRow>
              )}
            </Card>
            {/* <div style={{ color: "#586B94" }}>
            Clients cancelling an appointment less than 24 hours from the
            scheduled appointment time will be charged a cancel fee of $25.
          </div> */}
            <footer>
              <Button key={1} size="small" onClick={onExit} type="light">
                No, exit now
              </Button>

              <Button
                style={{ margin: 0 }}
                key={2}
                size="small"
                onClick={onCancel}
                type="danger"
              >
                Yes, cancel this appointment
              </Button>
            </footer>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CancelAppointmentModal;
