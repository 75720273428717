import React, { useState, lazy, Suspense, useReducer, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { capitalize, includes } from "lodash";
import moment from "moment-timezone";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Spin from "antd/es/spin";

import { Cards } from "../../atoms/cards/Cards";
import { Button } from "../../atoms/buttons/buttons";
import Year from "../../molecules/calendar/calendarHeader/Year";
import Month from "../../molecules/calendar/calendarHeader/Month";
import Week from "../../molecules/calendar/calendarHeader/Week";
import Day from "../../molecules/calendar/calendarHeader/Day";
import List from "../../molecules/calendar/calendarHeader/List";
import useGetShifts from "../../../utilities/hooks/useGetShifts";
import "react-calendar/dist/Calendar.css";
import useGetProvidersByLocation from "../../../utilities/hooks/useGetProvidersByLocation";
import { useQueryClient } from "react-query";
import styles from "./style.module.scss"

const YearCalendar = lazy(() =>
  import("../../molecules/calendar/overview/Year")
);
const MonthCalendar = lazy(() =>
  import("../../molecules/calendar/overview/Month")
);
const WeekCalendar = lazy(() =>
  import("../../molecules/calendar/overview/Week")
);
const DayCalendar = lazy(() => import("../../molecules/calendar/overview/Day"));

const ListCalendar = lazy(() =>
  import("../../molecules/calendar/overview/List")
);

function Calendar({ selectedLocation, businessId, permissions, userData }) {
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");
  const queryClient = useQueryClient();

  const dateFormat = "YYYY-MM-DD";
  const calendarItems = ["day", "week", "month", "year", "list"];

  const [selectedCalendar, setSelectedCalendar] = useState("week");
  const initialDayState = {
    date: new Date(),
    container: null,
    currentLabel: moment().format("MMMM YYYY"),
    defaultValue: moment().format("YYYY-MM-DD"),
    height: 0,
  };
  const [dayState, setDayState] = useState(initialDayState);

  const initialWeekState = {
    currentWeek: moment().isoWeek(),
    maxWeek: moment().year(moment().year()).isoWeeksInYear(),
    minWeek: moment().year(moment().year()).startOf('year').isoWeek(),
    year: moment().year(),
    defaultValue: moment().format("YYYY-MM-DD"),
    startOfCurrentWeek: moment().startOf('isoWeek').isoWeekday(0)
  };
  const [weekState, setWeekState] = useState(initialWeekState);

  const initialMonthState = {
    date: new Date(),
    container: null,
    currentLabel: moment().format("MMMM YYYY"),
    defaultValue: moment().format("YYYY-MM-DD"),
  };
  const [monthState, setMonthState] = useState(initialMonthState);

  const initialYearState = {
    currentYear: moment().year(),
    maxYear: 2025,
    minYear: 2018,
    defaultValue: moment().format("YYYY-MM-DD"),
  };
  const [yearState, setYearState] = useState(initialYearState);

  const initialListState = {
    currentMonth: 0,
    defaultValue: moment().format("YYYY-MM-DD"),
  };
  const [listState, setListState] = useState(initialListState);

  let currentDate = `${weekState.year}-${moment()
    .year(weekState.year)
    .isoWeek(weekState.currentWeek)
    .day("Sunday").format("MM-DD")}`;

  const [filters, setFilters] = useReducer((prev, next) => ({ ...next }), {
    fromDate: weekState.startOfCurrentWeek.format(dateFormat),
    toDate: weekState.startOfCurrentWeek.clone().add(6, "days").format(dateFormat),
  });

  const { data: events, isLoading } = useGetShifts({
    businessId,
    locationId: selectedLocation?.id,
    b2b_type: isCorporateWellness ? "corporate" : "staffing",
    ...filters,
  });
  const {
    providers,
    services,
    workinghours,
    isLoading: isProvidersLoading,
    timezone
  } = useGetProvidersByLocation(selectedLocation?.id);

  let data = { providers, services, workinghours, timezone };

  useEffect(() => {
    if (data?.timezone) {
      moment.tz.setDefault(data?.timezone || "");
    }
  }, [data?.timezone])

  const onDayChange = (date) => {
    setFilters({
      day: moment(date).format(dateFormat),
    });
  };

  const onWeekChange = (newVal) => {
    setFilters({
      fromDate: newVal.format(dateFormat),
      toDate: newVal.clone().add(6, "days").format(dateFormat),
    });
  };

  const onMonthChange = (date) => {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    let day = String(date.getDate()).padStart(2, '0');

    let formattedDateObj = `${year}-${month}-${day}`;
    const startOfMonth = moment(formattedDateObj, dateFormat).startOf("month").format(dateFormat);
    const endOfMonth = moment(formattedDateObj, dateFormat).endOf("month").format(dateFormat);

    setFilters({
      fromDate: startOfMonth,
      toDate: endOfMonth,
    });
  };

  const onYearChange = (year) => {
    setFilters({ year: String(year) });
  };

  const onListChange = (startDate, endDate) => {
    setFilters({
      fromDate: startDate,
      toDate: endDate,
    });
  };

  const resetDayState = () => {
    setDayState(initialDayState);
    onDayChange(initialDayState.date);
  };

  const setModeRelevantFilters = (mode) => {
    switch (mode) {
      case "day":
        resetDayState();
        break;
      case "week":
        setWeekState(initialWeekState);
        onWeekChange(initialWeekState.startOfCurrentWeek);
        break;
      case "month":
        setMonthState(initialMonthState);
        onMonthChange(initialMonthState.date);
        break;
      case "year":
        setYearState(initialYearState);
        onYearChange(initialYearState.currentYear);
        break;
      case "list":
        setListState(initialListState);
        onListChange(moment().format("YYYY-MM-DD"), moment().add(1, "month").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };

  const getHeader = () => {
    if (isProvidersLoading || isLoading) return null;
    switch (selectedCalendar) {
      case "year":
        return (
          <Year
            setYearState={setYearState}
            yearState={yearState}
            onYearChange={onYearChange}
          />
        );
      case "month":
        return (
          <Month
            setMonthState={setMonthState}
            monthState={monthState}
            dateFormat={dateFormat}
            onMonthChange={onMonthChange}
          />
        );
      case "week":
        return (
          <Week
            weekState={weekState}
            setWeekState={setWeekState}
            onWeekChange={onWeekChange}
          />
        );
      case "day":
        return (
          <Day
            dayState={dayState}
            setDayState={setDayState}
            dateFormat={dateFormat}
            onDayChange={onDayChange}
          />
        );
      case "list":
        return (
          <List
            listState={listState}
            setListState={setListState}
            onListChange={onListChange}
            dateFormat={dateFormat}
          />
        );
      default:
        return <></>;
    }
  };

  const getContent = () => {
    if (isProvidersLoading || isLoading) return <Skeleton paragraph={{ rows: 15 }} active />;
    switch (selectedCalendar) {
      case "year":
        return (
          <YearCalendar
            setYearState={setYearState}
            yearState={yearState}
            events={events}
            isEventsLoading={isLoading}
            selectedLocation={selectedLocation}
            businessId={businessId}
            data={data}
          />
        );
      case "month":
        return (
          <MonthCalendar
            setMonthState={setMonthState}
            monthState={monthState}
            events={events}
            isEventsLoading={isLoading}
            selectedLocation={selectedLocation}
            data={data}
            businessId={businessId}
          />
        );
      case "week":
        return (
          <WeekCalendar
            weekState={weekState}
            events={events}
            isEventsLoading={isLoading}
            selectedLocation={selectedLocation}
            data={data}
            businessId={businessId}
            userData={userData}
          />
        );
      case "day":
        return (
          <DayCalendar
            dayState={dayState}
            setDayState={setDayState}
            events={events}
            isEventsLoading={isLoading}
            selectedLocation={selectedLocation}
            data={data}
            businessId={businessId}
            userData={userData}
          />
        );
      case "list":
        return (
          <ListCalendar
            listState={listState}
            setListState={setListState}
            events={events}
            isEventsLoading={isLoading}
            selectedLocation={selectedLocation}
            businessId={businessId}
            data={data}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles["calendar-wrapper"]}>
      <Cards headless id="calendar">
        <div className="calendar-header">
          <div className="calendar-header__left">
            <Button
              className="btn-today"
              type="white"
              size="small"
              outlined
              onClick={() => {
                setSelectedCalendar("day");
                resetDayState();
              }}
            >
              Today
            </Button>
            {getHeader()}
          </div>
          <div className="calendar-header__right">
            <ul>
              {calendarItems.map((item, index) => (
                <li
                  key={index}
                  className={
                    selectedCalendar === item ? "active" : "deactivate"
                  }
                >
                  <Link
                    id={`calendar_${item}-button`}
                    onClick={() => {
                      setSelectedCalendar(item);
                      setModeRelevantFilters(item);
                      queryClient.refetchQueries("getAppointmentDetails");
                    }}
                    to="#"
                  >
                    {capitalize(item)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton paragraph={{ rows: 15 }} active />
            </Cards>
          }
        >
          <Spin spinning={isLoading || isProvidersLoading}>{getContent()}</Spin>
        </Suspense>
      </Cards>
    </div>
  );
}

export default Calendar;